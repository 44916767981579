<h2 mat-dialog-title>
  <mat-icon class="fa fa-floppy-disk"></mat-icon>
  Add new Location
</h2>
<div mat-dialog-content>
  <tc-select-search placeholder="Location description"
      [ctrl]="location"
      [items]="locations$ | async" bindLabel="name" bindValue="location_id"
      ></tc-select-search>
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="cancel()">Cancel</button>
  <button mat-raised-button type="button" color="primary" (click)="save()">Save</button>
</div>
