import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { Store, select } from '@ngrx/store'
import { AccountObject, GeneralAddress, SUPPLIER } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadConsignees } from 'src/app/store/consignees'
import { selectAllConsignees } from 'src/app/store/consignees/consignees.selectors'
import { AddressFormService } from 'src/components/address-form/address-form.service'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { isAtLeastAdmin } from 'src/services/data/users.service'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CompanyFormGroup } from '../company-form'

@Component({
  selector: 'tc-company-addresses-tab',
  templateUrl: './company-addresses-tab.component.html',
  styleUrl: './company-addresses-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SelectSearchModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    PipesModule,
  ]
})
export class CompanyAddressesTabComponent implements OnInit {
  constructor(
    private readonly ConfirmModal: ConfirmModalService,
    private readonly AddressForm: AddressFormService,
    private readonly AuthApi: AuthApiService,
    private readonly store: Store,
    private readonly cd: ChangeDetectorRef,
  ) {}

  @Input({ required: true }) company$: Observable<DeepReadonly<AccountObject>>
  @Input({ required: true }) companyForm: CompanyFormGroup

  protected readonly isAdminManagerSuperuser = isAtLeastAdmin(this.AuthApi.currentUser)
  protected isTradecafeStaffAccount$: Observable<boolean>
  protected isSuppler$: Observable<boolean>
  protected readonly consignees$ = this.store.pipe(select(selectAllConsignees), waitNotEmpty())

  ngOnInit() {
    this.store.dispatch(loadConsignees())
    this.isTradecafeStaffAccount$ = this.company$.pipe(map(company => this.AuthApi.currentUser.account === company.account))
    this.isSuppler$ = replayForm(this.companyForm.controls.type).pipe(map(type => type === SUPPLIER))
  }

  protected addAddress() {
    this.openAddressForm().subscribe((newAddress) => {
      const ctrl = this.companyForm.controls.addresses
      let addresses = ctrl.value.map(addr => newAddress.primary ? { ...addr, primary: undefined } : addr);
      addresses.push(newAddress);

      ctrl.setValue(addresses)
      ctrl.markAsDirty()
      ctrl.markAsTouched()
      this.cd.markForCheck()
    })
  }

  protected editAddress(address: DeepReadonly<GeneralAddress>) {
    this.openAddressForm(address).subscribe((updates) => {
      const ctrl = this.companyForm.controls.addresses
      ctrl.setValue(ctrl.value.map(addr => {
        if (address === addr) return { ...address, ...updates }
        if (updates.primary) return { ...addr, primary: undefined }
        return addr
      }))
      ctrl.markAsDirty()
      ctrl.markAsTouched()
      this.cd.markForCheck()
    })
  }

  protected async archiveAddress(address: any) {
    await this.ConfirmModal.show({
      title: 'Archive this address?',
      description: 'Are you sure you want to archive this address?',
    })
    const ctrl = this.companyForm.controls.addresses
    ctrl.setValue(ctrl.value.map((addr) => {
      if (address === addr) return { ...address, archived: true }
      return addr
    }))
    ctrl.markAsDirty()
    ctrl.markAsTouched()
    this.cd.markForCheck()
  }

  protected async unarchiveAddress(address: any) {
    await this.ConfirmModal.show({
      title: 'Unarchive this address?',
      description: 'Are you sure you want to unarchive this address?',
    })
    const ctrl = this.companyForm.controls.addresses
    ctrl.setValue(ctrl.value.map((addr) => {
      if (address === addr) return { ...address, archived: false }
      return addr
    }))
    ctrl.markAsDirty()
    ctrl.markAsTouched()
    this.cd.markForCheck()
  }

  private openAddressForm(address?: DeepReadonly<GeneralAddress>) {
    return this.company$.pipe(take(1), switchMap(company =>
      this.AddressForm.showAddress(address, company)))
  }
}
