import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { selectAllLocations } from 'src/app/store/locations'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'


@Component({
  selector: 'tc-company-location-form',
  templateUrl: './company-location-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyLocationFormComponent {
  constructor(
    private readonly store: Store,
    private readonly dialogRef: MatDialogRef<CompanyLocationFormComponent, string>,
  ) {}

  protected readonly locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  protected readonly location = new FormControl<string>(undefined, Validators.required);

  cancel() {
    this.dialogRef.close()
  }

  save() {
    this.dialogRef.close(this.location.value)
  }
}
