<cdk-virtual-scroll-viewport tvsItemSize="56" headerHeight="56" [itemSize]="56" style="--item-size: 56px" bufferMultiplier="3">
  <table mat-table [trackBy]="getRowId"
    fixedLayout [dataSource]="dataSource"
    matSort matSortStart="desc" matSortDisableClear>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <!-- NOTE: column filters are disabled. There're issues which will be addressed in 1.19+
    <tr mat-header-row *matHeaderRowDef="filterColumns" class="tc-filters-row"></tr>
    -->
    <tr mat-row *matRowDef="let row; columns: displayColumns" [class.tc-loading-row]="!row"></tr>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <!-- <mat-checkbox *ngIf="selectAll"
          matTooltip="Select All"
          (change)="selection.toggleRows($event.checked, dataSource.data)"
        ></mat-checkbox> -->
      </th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        @if (!row) {
          <div class="skeleton-loader square"></div>
        } @else {
          @if (selection.canSelect(row)) {
            <mat-checkbox
              [disabled]="row.disabled"
              [checked]="(selection.selectedIds$ | async).includes(row.account)"
              (click)="$event.stopPropagation()"
              (change)="selection.toggleRow(row)"
            ></mat-checkbox>
          }
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header title="Company">Company</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        @if (!row) {
          <div class="skeleton-loader thin-line"></div>
          <div class="skeleton-loader tiny-line"></div>
        } @else {
          <div class="text-ellipsis" [title]="row.name">{{row.name}}</div>
          <div class="text-ellipsis" class="text-gray" [style.fontWeight]="row.dirty ? 'bold' : ''" [title]="row.hint">{{row.hint}}</div>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="primary">
      <th mat-header-cell *matHeaderCellDef mat-sort-header title="Primary LC">Primary LC</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        @if (!row) {
          <div class="skeleton-loader square"></div>
        } @else if (row.primary) {
          <i class="fa fa-2x fa-badge-check"></i>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="associate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header title="Associate LC">Associate LC</th>
      <td mat-cell *matCellDef="let row; dataSource:dataSource">
        @if (!row) {
          <div class="skeleton-loader square"></div>
        } @else if (row.associate) {
          <i class="fa fa-2x fa-badge-check"></i>
        }
      </td>
    </ng-container>
  </table>
</cdk-virtual-scroll-viewport>
<mat-paginator #paginator style="display: none"></mat-paginator>
