import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { TypeSafeMatCellDefModule } from 'src/components/type-safe-mat-cell-def/type-safe-mat-cell-def.module';
import { ReassignmentsListComponent } from './reassignments-list.component';

@NgModule({
  declarations: [ReassignmentsListComponent],
  exports: [ReassignmentsListComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    ScrollingModule,
    TypeSafeMatCellDefModule,
    MatPaginatorModule,
    TableVirtualScrollModule,
  ],
})
export class ReassignmentsListModule { }
