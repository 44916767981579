
<mat-checkbox [formControl]="pricingForm.controls.tax_type" style="grid-column: span 3">GST/HST/PST</mat-checkbox>

<mat-form-field>
  <mat-label>GST #</mat-label>
  <input matInput [formControl]="pricingForm.controls.tax_gst">
</mat-form-field>
<tc-select-search placeholder="Default Unit" style="grid-column: span 2"
    [ctrl]="pricingForm.controls.measure_id"
    [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
    ></tc-select-search>

<mat-form-field>
  <mat-label>HST #</mat-label>
  <input matInput [formControl]="pricingForm.controls.tax_hst">
</mat-form-field>
<tc-select-search placeholder="Incoterm" style="grid-column: span 2"
    [ctrl]="pricingForm.controls.incoterm"
    [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
    ></tc-select-search>

<mat-form-field>
  <mat-label>Provincial Tax #</mat-label>
  <input matInput [formControl]="pricingForm.controls.tax_pst">
</mat-form-field>
<tc-select-search placeholder="Location" style="grid-column: span 2"
    [ctrl]="pricingForm.controls.tax_location"
    [items]="companyLocations$ | async" bindValue="location_id" bindLabel="name"
    ></tc-select-search>

<mat-form-field>
  <mat-label>Other Tax #</mat-label>
  <input matInput [formControl]="pricingForm.controls.other_tax">
</mat-form-field>
<tc-select-search placeholder="Currency"
    [ctrl]="pricingForm.controls.currency"
    [items]="currencies$ | async " bindValue="code" bindLabel="code"
    ></tc-select-search>
<mat-form-field>
  <mat-label>Default Margin (%)</mat-label>
  <input matInput type="number" step="0.05"
      [formControl]="pricingForm.controls.default_margin">
</mat-form-field>
