<form name="form" (submit)="save()">
  <h2 mat-dialog-title>
    <i class="fa fa-file-lines" aria-hidden="true"></i> {{title}}
  </h2>
  <div mat-dialog-content>
    <section style="display: grid; gap: 0 15px; grid-template-columns: minmax(0, 5fr) minmax(0, 3fr) minmax(0, 4fr);">
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input matInput type="number" min="0" step="0.01" [formControl]="receiptForm.controls.amount">
        </mat-form-field>
        <tc-select-search placeholder="Currency"
            [ctrl]="receiptForm.controls.currency"
            [items]="currencies$ | async" bindValue="code" bindLabel="code"
            ></tc-select-search>
        <tc-epoch-field placeholder="Received" required="true" [useUtc]="true"
            [ctrl]="receiptForm.controls.received"
            ></tc-epoch-field>
    </section>
    <section style="display: grid; gap: 0 15px; grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);">
      <tc-select-search placeholder="Customer"
          [ctrl]="receiptForm.controls.account"
          [items]="accounts$ | async" bindValue="account" bindLabel="name" bindHint="type"
          ></tc-select-search>
      <tc-select-search placeholder="Bank"
          [ctrl]="receiptForm.controls.bank_id"
          [items]="banks$ | async" bindValue="bank_id" bindLabel="name"
          ></tc-select-search>
      <mat-form-field>
        <mat-label>Bank SC</mat-label>
        <input matInput type="number" step="0.01" min="0" [formControl]="receiptForm.controls.bank_sc">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Bank Ref. #</mat-label>
        <input matInput type="text" [formControl]="receiptForm.controls.ref_no">
      </mat-form-field>
    </section>
    <section style="display: grid">
      <mat-form-field>
        <mat-label>Bank Narrative</mat-label>
        <textarea matInput [formControl]="receiptForm.controls.bank_narrative"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput [formControl]="receiptForm.controls.description"></textarea>
      </mat-form-field>
    </section>
  </div>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="inProgress$ | async">
      <i class="fa"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
