import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Store, select } from '@ngrx/store'
import { BUYER, CreditBalance, CreditPool, FileObject, PaymentTermsDates, SERVICE_PROVIDER, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, keyBy } from 'lodash-es'
import { NgxCurrencyConfig, NgxCurrencyDirective, NgxCurrencyInputMode } from 'ngx-currency'
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, from, of } from 'rxjs'
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { CreditPoolApiService } from 'src/api/credit-pool'
import { FileUploaderService } from 'src/api/file'
import { loadCurrencies, selectAllCurrencies, selectCurrencyEntities } from 'src/app/store/currencies'
import { loadPaymentMethods, selectAllPaymentMethods } from 'src/app/store/payment-methonds'
import { loadPaymentReferences, selectAllPaymentReferences } from 'src/app/store/payment-references'
import { loadUsers, selectTradersOptions, selectUserEntities } from 'src/app/store/users'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { FilesService } from 'src/pages/admin/trading/deals/deal-documents/files.service'
import { CreditPoolService } from 'src/services/data/credit-pool.service'
import { dayjs } from 'src/services/dayjs'
import { ModalModule, ModalProxyService } from 'src/shared/modal'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CompanyFormGroup, CreditFormGroup, enableDisableCreditForm } from '../company-form'


@Component({
  selector: 'tc-company-credit-tab',
  templateUrl: './company-credit-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatTooltipModule,
    SelectSearchModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    EpochFieldModule,
    PipesModule,
    FlexLayoutModule,
    ModalModule,
    NgxCurrencyDirective,
  ]
})
export class CompanyCreditTabComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly ConfirmModal: ConfirmModalService,
    private readonly CreditPoolApi: CreditPoolApiService,
    private readonly CreditPoolSvc: CreditPoolService,
    private readonly Files: FilesService,
    private readonly FileUploader: FileUploaderService,
    private readonly modalHelper: ModalProxyService,
    private readonly store: Store,
    private readonly toaster: ToasterService,
  ) { super() }

  // const
  protected readonly PaymentTermsDates = PaymentTermsDates
  protected readonly CreditTypes = [
    { id: 'dcl', name: 'DCL Limit' },
    { id: 'insurance', name: 'Insurance Limit' },
  ]
  protected readonly SecureTypes = [
    { id: "cash", name: 'Cash Against Documents' },
    { id: "letter", name: 'Letter of Credit' },
    { id: "prepayment", name: 'Prepayment' },
  ]
  protected readonly OpenPaymentMethods = [
    { id: "wire_transfer", name: 'Wire Transfer Funds' },
  ]

  // input
  @Input({ required: true }) companyAccount: number
  @Input({ required: true }) companyForm: CompanyFormGroup
  @Input({ required: true }) creditForm: CreditFormGroup
  @Input({ required: true }) editable: boolean
  @Input({ required: true }) creditPool$: BehaviorSubject<DeepReadonly<CreditPool>>

  @ViewChild('insuranceStatementFile', { static: false }) insuranceStatementFile: ElementRef<HTMLInputElement>
  protected readonly insuranceStatementFile$ = new ReplaySubject<FileObject>(1)

  // state
  protected readonly balance$ = new ReplaySubject<CreditBalance>(1)
  protected creditPoolExists$: Observable<boolean>
  private readonly files$ = new BehaviorSubject<Record<string, FileObject>>({})
  protected creditApplications$: Observable<{ file: FileObject; file_id?: string; type?: string }[]>

  protected isNotBuyer$: Observable<boolean>
  protected isBuyer$: Observable<boolean>
  protected isServiceProvider$: Observable<boolean>
  protected fulfilled$: Observable<DeepReadonly<Omit<CreditPool['onboarding']['fulfilledByCreditDepartment'], 'user'> & { user: User }>>
  protected currencyOptions$: Observable<Partial<NgxCurrencyConfig>>

  // form
  protected showPrepaymentPercent$: Observable<boolean>

  // ref data
  protected readonly currencies$ = this.store.pipe(select(selectAllCurrencies), waitNotEmpty())
  protected readonly paymentReferences$ = this.store.pipe(select(selectAllPaymentReferences), waitNotEmpty())
  protected readonly paymentMethods$ = this.store.pipe(select(selectAllPaymentMethods), waitNotEmpty())
  protected readonly traders$ = this.store.pipe(select(selectTradersOptions()), waitNotEmpty())


  ngOnInit() {
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadPaymentMethods())
    this.store.dispatch(loadPaymentReferences({}))
    this.store.dispatch(loadUsers({}))

    this.isNotBuyer$ = replayForm(this.companyForm.controls.type).pipe(map(type => type !== BUYER))
    this.isBuyer$ = replayForm(this.companyForm.controls.type).pipe(map(type => type === BUYER))
    this.isServiceProvider$ = replayForm(this.companyForm.controls.type).pipe(map(type => type === SERVICE_PROVIDER))

    this.creditPoolExists$ = this.creditPool$.pipe(map(cp => !!cp.pool_id))
    this.fulfilled$ = combineLatest([
      this.creditPool$.pipe(map(creditPool => creditPool.onboarding?.fulfilledByCreditDepartment)),
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
    ]).pipe(map(([fulfilled, users]) => ({ ...fulfilled, user: users[fulfilled?.user]})))

    this.creditApplications$ = replayForm(this.creditForm).pipe(
      withLatestFrom(this.files$),
      switchMap(([creditForm, files]) => {
        const missingFileIds = compact(creditForm.credit_applications?.map(ca => !files[ca.file_id] && ca.file_id))
        if (!missingFileIds.length) return of([creditForm, files] as const)
        return from(this.Files.getFilesByIds(missingFileIds)).pipe(
          tap(newFiles => {
            files = { ...files, ...keyBy(newFiles, 'file_id') }
            this.files$.next(files)
          }),
          map(() => [creditForm, files] as const)
        )
      }),
      map(([creditForm, files]) =>
        creditForm.credit_applications?.map(ca => ({ ...ca, file: files[ca.file_id] }))))

    this.showPrepaymentPercent$ = replayForm(this.creditForm).pipe(map(creditForm =>
      creditForm.payment_term_type === 'secure' &&['prepayment', 'cash'].includes(creditForm.payment_term_secure_type)))
    this.currencyOptions$ = combineLatest([
      replayForm(this.creditForm.controls.currency),
      this.store.pipe(select(selectCurrencyEntities), waitNotEmpty()),
    ]).pipe(map(([code, currencies]) => {
      const currency = currencies[code]
      const [, { value: thousands}, , { value: decimal }] = Intl.NumberFormat().formatToParts(1000.1)
      return {
        prefix: currency ? `${currency.symbol || currency.code || ''} ` : '',
        thousands,
        decimal,
        inputMode: NgxCurrencyInputMode.Natural,
        align: 'left',
      }
    }))

    combineLatest([
      replayForm(this.creditForm),
      replayForm(this.companyForm),
    ]).pipe(untilComponentDestroyed(this)).subscribe(() => {
      enableDisableCreditForm(this.creditForm, this.companyForm, !this.editable)
    })
    this.loadBalance()
  }

  private async loadBalance() {
    const insuranceStatementFileId = this.creditPool$.value.attributes?.insurance_statement_file_id

    const [balance, insuranceStatementFile] = await Promise.all([
      this.creditPool$.value.pool_id ? this.CreditPoolSvc.getBalance(this.companyAccount).toPromise() : undefined,
      insuranceStatementFileId && this.Files.getFileById(insuranceStatementFileId),
    ])

    this.balance$.next(balance)
    if (insuranceStatementFileId) {
      this.insuranceStatementFile$.next(insuranceStatementFile)
    }
  }

  protected removeInsuranceStatementFile() {
    if (this.insuranceStatementFile?.nativeElement) this.insuranceStatementFile.nativeElement.files = undefined
    this.insuranceStatementFile$.next(undefined)
    const ctrl = this.creditForm.controls.insurance_statement_file_id
    ctrl.setValue(undefined)
    ctrl.markAsTouched()
    ctrl.markAsDirty()
  }

  protected uploadClicked() {
    if (!this.insuranceStatementFile.nativeElement.files.length) {
      this.insuranceStatementFile.nativeElement.click()
      return
    }
    this.creditPool$.pipe(take(1)).subscribe(cp => {
      if (!cp.pool_id) {
        this.toaster.warning('Credit pool is not ready. Please select currency and click "save" button.')
        return
      }

      this.FileUploader.uploadFile(this.insuranceStatementFile.nativeElement.files[0], { visibility: 0 }).subscribe(file => {
        this.insuranceStatementFile$.next(file)
        const ctrl = this.creditForm.controls.insurance_statement_file_id
        ctrl.setValue(file.file_id)
        ctrl.markAsTouched()
        ctrl.markAsDirty()
      })
    })
  }

  protected async addCreditApplication() {
    this.creditForm.markAllAsTouched()
    if (!this.creditForm.valid) {
      this.toaster.warning('Fill in all required fields first')
      return
    }

    const {application, file} = await this.modalHelper.showModal({
      component: 'tcCompanyCreditApplicationForm',
      windowClass: 'modalShipping',
      // size: 'lg',
      backdrop: 'static',
    }) as { application: { file_id: string, type: string }, file: FileObject }

    this.files$.next({ ...this.files$.value, [file.file_id]: file })
    const ctrl = this.creditForm.controls.credit_applications
    ctrl.setValue([...ctrl.value, application])
    ctrl.markAsTouched()
    ctrl.markAsDirty()
    // save() // TODO: WA-11608 - save this.creditApplications$
  }

  protected async deleteCreditApplication(index: number) {
    this.creditForm.markAllAsTouched()
    if (!this.creditForm.valid) {
      this.toaster.warning('Fill in all required fields first')
      return
    }

    await this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to delete this credit application?',
    })

    const ctrl = this.creditForm.controls.credit_applications
    ctrl.setValue(ctrl.value.filter((_a, i) => i !== index))
    ctrl.markAsTouched()
    ctrl.markAsDirty()
    // save() // TODO: WA-11608 - save this.creditApplications$
  }

  protected downloadCreditApplication(application) {
    this.Files.download(application.file)
  }

  protected async reviewCredit() {
    const creditPool = await this.creditPool$.pipe(take(1)).toPromise()
    const { user_id } = this.AuthApi.currentUser

    try {
      const { data: cp } = await this.CreditPoolApi.update(this.companyAccount, {
        user_id,
        onboarding: {
          ...creditPool.onboarding,
          fulfilledByCreditDepartment: {
            isFulfilled: true,
            user: user_id,
            updated: dayjs.utc().unix(),
          },
        },
      })
      this.creditPool$.next(cp)
      this.toaster.success('Credit application review successfully.')
    } catch (err) {
      console.error('Unable to review credit application.', err)
      this.toaster.error('Unable to review credit application.', err)
    }
  }

  // function addPaymentTerms() {
  //   $ctrl.creditPool.attributes.custom_payment_terms = $ctrl.creditPool.attributes.custom_payment_terms || []
  //   $ctrl.creditPool.attributes.custom_payment_terms.push({})
  // }
}
