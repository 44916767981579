import { Injectable } from "@angular/core";
import { ModalService } from "src/shared/modal";
import { CompanyLocationFormComponent } from "./company-location-form.component";

@Injectable()
export class CompanyLocationFormService {
  constructor(private readonly modal: ModalService) {}

  showCompanyLocation() {
    return this.modal.openDialog<CompanyLocationFormComponent, void, string>(CompanyLocationFormComponent).toPromise()
  }
}
