<mat-form-field>
  <mat-label>Bank name</mat-label>
  <input matInput [formControl]="bankingForm.controls.name">
</mat-form-field>
<mat-form-field style="grid-column: span 2">
  <mat-label>Address</mat-label>
  <input matInput [formControl]="bankingForm.controls.street1">
</mat-form-field>

<mat-form-field>
  <mat-label>City</mat-label>
  <input matInput [formControl]="bankingForm.controls.city">
</mat-form-field>
<mat-form-field style="grid-column: span 2">
  <mat-label>Province/State</mat-label>
  <input matInput [formControl]="bankingForm.controls.state">
</mat-form-field>

<tc-select-search placeholder="Country"
    [ctrl]="bankingForm.controls.country"
    [items]="countries$ | async" bindLabel="name" bindValue="name"
    ></tc-select-search>
<mat-form-field>
  <mat-label>Zip code</mat-label>
  <input matInput [formControl]="bankingForm.controls.postal">
</mat-form-field>
<tc-select-search placeholder="Payment Type"
    [ctrl]="bankingForm.controls.payment_type"
    [items]="accountBankingPayments" bindLabel="label" bindValue="id"
    ></tc-select-search>

<mat-form-field>
  <mat-label>Transit #</mat-label>
  <input matInput [formControl]="bankingForm.controls.transit">
</mat-form-field>
<mat-form-field style="grid-column: span 2">
  <mat-label>USD account #</mat-label>
  <input matInput [formControl]="bankingForm.controls.account_usd">
</mat-form-field>

<mat-form-field>
  <mat-label>Bank code</mat-label>
  <input matInput [formControl]="bankingForm.controls.bank_code">
</mat-form-field>
<mat-form-field style="grid-column: span 2">
  <mat-label>CAD account #</mat-label>
  <input matInput [formControl]="bankingForm.controls.account_cad">
</mat-form-field>

<mat-form-field>
  <mat-label>Branch designation #</mat-label>
  <input matInput [formControl]="bankingForm.controls.branch_designation">
</mat-form-field>
<mat-form-field>
  <mat-label>ABA #</mat-label>
  <input matInput [formControl]="bankingForm.controls.aba">
</mat-form-field>
<mat-form-field>
  <mat-label>SWIFT code</mat-label>
  <input matInput [formControl]="bankingForm.controls.swift">
</mat-form-field>

<mat-form-field>
  <mat-label>Beneficiary name</mat-label>
  <input matInput [formControl]="bankingForm.controls.beneficiary_name">
</mat-form-field>
