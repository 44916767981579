import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountObject, AccountType, BUYER, Carrier, CreditPool, Currency, SegmentType } from "@tradecafe/types/core";
import { DeepReadonly } from "@tradecafe/types/utils";
import { compact, identity, pick, pickBy, uniq } from "lodash-es";
import { disableIf } from "src/shared/utils/disable-if";
import { ValidateApiService } from "src/api/validate";
import { EMAIL_REGEX } from "src/shared/regex/email";

export type CompanyFormGroup = ReturnType<typeof newCompanyForm>
export type CompanyBankingFormGroup = ReturnType<typeof newCompanyBankingForm>
export type CompanyPricingFormGroup = ReturnType<typeof newCompanyPricingForm>
export type CreditFormGroup = ReturnType<typeof newCreditForm>
export type CarrierFormGroup = ReturnType<typeof newCarrierForm>
export type CompanyBulkFormGroup = ReturnType<typeof newCompanyBulkForm>

export function newCompanyForm(ValidateApi: ValidateApiService, company: DeepReadonly<AccountObject>) {
  return new FormGroup({
    // nested forms and lists
    consignees: new FormControl(company.consignees || []),
    addresses: new FormControl(company.addresses || []),
    banking: newCompanyBankingForm(company),
    pricing: newCompanyPricingForm(company),
    distribution_lists: new FormControl(company.distribution_lists || []),
    locations: new FormControl(company.attributes?.locations || []),
    default_location: new FormControl(company.attributes?.default_location),
    default_port: new FormControl(company.default_port, company.default_location_is_port ? Validators.required : undefined),
    default_location_is_port: new FormControl<boolean>(company.default_location_is_port ?? false),

    permissionsOfferCreate: new FormControl(company.permissions?.offer?.create || false),

    avg_days_to_due_date: new FormControl(company.attributes?.credit_info?.avg_days_to_due_date),
    taxID: new FormControl(company.creditinfo?.questions?.taxID),
    duns: new FormControl(company.creditinfo?.questions?.duns),
    credit_reporting_listings: new FormControl(company.creditinfo?.questions?.credit_reporting_listings),
    file_reference: new FormControl(company.creditinfo?.questions?.file_reference),

    // details
    type: new FormControl<AccountType>(company.type),
    name: new FormControl(company.name, Validators.required),
    business_type_id: new FormControl(company.attributes?.business_type_id),
    status: new FormControl(company.status, Validators.required),
    since: new FormControl(company.since),
    parent: new FormControl(company.parent),
    website: new FormControl(company.website),
    tracking_website: new FormControl(company.attributes?.tracking_website),
    primaryphone: new FormControl(company.primaryphone, Validators.required),
    primaryemail: new FormControl(company.primaryemail, [Validators.required, Validators.pattern(EMAIL_REGEX)]),
    manager: new FormControl(company.manager),
    managers: new FormControl(company.managers),
    coordinator: new FormControl(company.coordinator),
    coordinators: new FormControl(company.coordinators),
    scac_code: new FormControl(company.attributes?.scac_code),
    carrier_id: new FormControl(company.attributes?.carrier_id),
    preferred_carriers: new FormControl(company.preferred_carriers),
    language: new FormControl(company.language),
    products_spec: new FormControl(company.products_spec, Validators.minLength(1)),
  })
}

export function readCompanyForm(companyForm: CompanyFormGroup): DeepReadonly<Partial<AccountObject>> {
  const cf = companyForm.getRawValue()
  return {
    // nested forms and lists
    addresses: cf.addresses,
    products: uniq(compact(cf.products_spec?.map(p => p.product_id))) || [],
    attributes: {
      pricing: {
        ...cf.pricing,
        tax_type: cf.pricing.tax_type ? 1 : 0,
      },
      locations: cf.locations,
      default_location: cf.default_location,
      credit_info: {
        avg_days_to_due_date: cf.avg_days_to_due_date
      },
      business_type_id: cf.business_type_id,
      tracking_website: cf.tracking_website,
      scac_code: cf.scac_code,
      carrier_id: cf.carrier_id || '',
    },
    banking: cf.banking,
    consignees: cf.consignees,
    coordinator: cf.coordinator,
    coordinators: cf.coordinators,
    creditinfo: {
      questions: pickBy({
        taxID: cf.taxID,
        duns: cf.duns,
        credit_reporting_listings: cf.credit_reporting_listings,
        file_reference: cf.file_reference,
      }, identity)
    },
    default_port: cf.default_port,
    default_location_is_port: cf.default_location_is_port,
    distribution_lists: cf.distribution_lists,
    language: cf.language,
    manager: cf.manager,
    managers: cf.managers,
    name: cf.name,
    parent: cf.parent || 0,
    permissions: {
      offer: { create: cf.permissionsOfferCreate },
    },
    preferred_carriers: cf.preferred_carriers,
    primaryemail: cf.primaryemail,
    primaryphone: cf.primaryphone,
    products_spec: cf.products_spec,
    since: cf.since,
    status: cf.status,
    type: cf.type,
    website: cf.website,
  }
}

export function enableDisableCompanyForm(companyForm: CompanyFormGroup, readonly: boolean) {
  disableIf(companyForm.controls.name, readonly)
  disableIf(companyForm.controls.business_type_id, readonly)
  disableIf(companyForm.controls.status, readonly)
  disableIf(companyForm.controls.since, readonly)
  disableIf(companyForm.controls.parent, readonly)
  disableIf(companyForm.controls.website, readonly)
  disableIf(companyForm.controls.tracking_website, readonly)
  disableIf(companyForm.controls.type, readonly)
  disableIf(companyForm.controls.primaryphone, readonly)
  disableIf(companyForm.controls.primaryemail, readonly)
  disableIf(companyForm.controls.manager, readonly)
  disableIf(companyForm.controls.managers, readonly)
  disableIf(companyForm.controls.coordinator, readonly)
  disableIf(companyForm.controls.coordinators, readonly)
  disableIf(companyForm.controls.scac_code, readonly)
  disableIf(companyForm.controls.preferred_carriers, readonly)
  disableIf(companyForm.controls.language, readonly)
}

export function newCompanyBankingForm(company: DeepReadonly<AccountObject>) {
  return new FormGroup({
    name: new FormControl(company.banking?.name),
    street1: new FormControl(company.banking?.street1),
    city: new FormControl(company.banking?.city),
    state: new FormControl(company.banking?.state),
    country: new FormControl(company.banking?.country),
    postal: new FormControl(company.banking?.postal),
    payment_type: new FormControl(company.banking?.payment_type),
    transit: new FormControl(company.banking?.transit),
    account_usd: new FormControl(company.banking?.account_usd),
    bank_code: new FormControl(company.banking?.bank_code),
    account_cad: new FormControl(company.banking?.account_cad),
    branch_designation: new FormControl(company.banking?.branch_designation),
    aba: new FormControl(company.banking?.aba),
    swift: new FormControl(company.banking?.swift),
    beneficiary_name: new FormControl(company.banking?.beneficiary_name),
  })
}

export function newCompanyPricingForm(company: DeepReadonly<AccountObject>) {
  return new FormGroup({
    tax_type: new FormControl(!!company.attributes?.pricing?.tax_type),
    tax_gst: new FormControl(company.attributes?.pricing?.tax_gst),
    measure_id: new FormControl(company.attributes?.pricing?.measure_id),
    tax_hst: new FormControl(company.attributes?.pricing?.tax_hst),
    incoterm: new FormControl(company.attributes?.pricing?.incoterm),
    tax_pst: new FormControl(company.attributes?.pricing?.tax_pst),
    tax_location: new FormControl(company.attributes?.pricing?.tax_location),
    other_tax: new FormControl(company.attributes?.pricing?.other_tax),
    currency: new FormControl(company.attributes?.pricing?.currency),
    default_margin: new FormControl(company.attributes?.pricing?.default_margin),
  })
}

export function newCreditForm(creditPool: DeepReadonly<CreditPool>) {
  return new FormGroup({
    bwi_credit_ref_sent: new FormControl(!!creditPool.attributes?.bwi_credit_ref_sent),
    sent_date: new FormControl(creditPool.attributes?.sent_date),
    credit_extended: new FormControl(creditPool.attributes?.credit_extended),
    currency: new FormControl(creditPool.currency?.code, Validators.required),
    extended_date: new FormControl(creditPool.attributes?.extended_date),
    payment_term_type: new FormControl(creditPool.attributes?.payment_term?.type),
    payment_ref_na: new FormControl(!!creditPool.attributes?.payment_ref?.na),
    payment_term_prepayment_percent: new FormControl(creditPool.attributes?.payment_term?.prepayment_percent, [Validators.min(0), Validators.max(100)]),
    payment_ref_days: new FormControl(creditPool.attributes?.payment_ref?.days, Validators.min(0)),
    payment_ref_from_date: new FormControl(creditPool.attributes?.payment_ref?.from_date),
    payment_ref_reference_id: new FormControl(creditPool.attributes?.payment_ref?.reference_id),
    payment_ref_payment_method_open: new FormControl(creditPool.attributes?.payment_ref?.payment_method_open),
    payment_ref_payment_method_secure: new FormControl(creditPool.attributes?.payment_ref?.payment_method_secure),
    payment_term_credit_type: new FormControl(creditPool.attributes?.payment_term?.credit_type),
    payment_term_secure_type: new FormControl(creditPool.attributes?.payment_term?.secure_type),
    custom_payment_terms: new FormArray(creditPool.attributes?.custom_payment_terms?.map(pt => new FormGroup({
      traders: new FormControl(pt.traders, Validators.required),
      type: new FormControl(pt.type),
      na: new FormControl(!!pt.na),
      prepayment_percent: new FormControl(pt.prepayment_percent, [Validators.min(0), Validators.max(100)]),
      days: new FormControl(pt.days, Validators.min(0)),
      from_date: new FormControl(pt.from_date),
      reference_id: new FormControl(pt.reference_id),
      credit_type: new FormControl(pt.credit_type),
      secure_type: new FormControl(pt.secure_type),
      payment_method_open: new FormControl(pt.payment_method_open),
      payment_method_secure: new FormControl(pt.payment_method_secure),
    })) || []),
    credit_applications: new FormControl(creditPool.attributes?.credit_applications || []),
    credit_application_date: new FormControl(creditPool.attributes?.credit_info?.credit_application_date),
    credit_insurance_submitted_date: new FormControl(creditPool.attributes?.credit_info?.credit_insurance_submitted_date),
    credit_insurance_approval_date: new FormControl(creditPool.attributes?.credit_info?.credit_insurance_approval_date),
    credit_insurance_expirary_date: new FormControl(creditPool.attributes?.credit_info?.credit_insurance_expirary_date),
    insurance_statement_file_id: new FormControl(creditPool.attributes?.insurance_statement_file_id),
    insurance_exp_date: new FormControl(creditPool.attributes?.insurance_exp_date),
    insured_limit: new FormControl(creditPool.attributes?.credit_info?.insured_limit),
    insurance_provider: new FormControl(creditPool.attributes?.credit_info?.insurance_provider),
    max: new FormControl(creditPool.max),
    in_transit_max: new FormControl(creditPool.in_transit_max),
    deal_creation_max: new FormControl(creditPool.deal_creation_max),
  })
}

export function readCreditForm(
  creditForm: CreditFormGroup,
  currencies: DeepReadonly<Dictionary<Currency>>,
): DeepReadonly<Partial<CreditPool>> {
  const cf = creditForm.getRawValue()
  const currencyCode = cf.currency
  const creditPool = {
    currency: pick(currencies[currencyCode], ['code', 'symbol']),
    attributes: {
      bwi_credit_ref_sent: cf.bwi_credit_ref_sent ? 1 : 0,
      credit_applications: cf.credit_applications,
      credit_extended: cf.credit_extended,
      credit_info: {
        credit_application_date: cf.credit_application_date,
        credit_insurance_approval_date: cf.credit_insurance_approval_date,
        credit_insurance_expirary_date: cf.credit_insurance_expirary_date,
        credit_insurance_submitted_date: cf.credit_insurance_submitted_date,
        insurance_provider: cf.insurance_provider,
        insured_limit: cf.insured_limit,
      },
      extended_date: cf.extended_date,
      insurance_exp_date: cf.insurance_exp_date,
      insurance_statement_file_id: cf.insurance_statement_file_id,
      payment_ref: {
        days: cf.payment_ref_days,
        from_date: cf.payment_ref_from_date,
        na: cf.payment_ref_na, // ? 1 : 0,
        payment_method_open: cf.payment_ref_payment_method_open,
        payment_method_secure: cf.payment_ref_payment_method_secure,
        reference_id: cf.payment_ref_reference_id,
      },
      payment_term: {
        credit_type: cf.payment_term_credit_type,
        prepayment_percent: cf.payment_term_prepayment_percent,
        secure_type: cf.payment_term_secure_type,
        type: cf.payment_term_type,
      },
      sent_date: cf.sent_date,
      custom_payment_terms: cf.custom_payment_terms?.map(pt => ({
        ...pt // , na: pt.na ? 1 : 0,
      })),
    } as CreditPool['attributes'],
    max: cf.max || 0,
    in_transit_max: cf.in_transit_max || 0,
    deal_creation_max: cf.deal_creation_max || 0,
  }

  // TODO: remove this once we make sure BE is OK
  // @see https://bitbucket.org/tradecafe/webapp-staff/commits/b5ceff8fab0c54d77da10ddf5b9531add54dda54
  creditPool.attributes.credit_info.ar_limit_extended = creditPool.max
  creditPool.attributes.credit_info.ar_limit_extended_currency = creditPool.currency.code
  creditPool.attributes.in_transit_max = creditPool.in_transit_max
  creditPool.attributes.in_transit_max_currency = creditPool.currency.code
  creditPool.attributes.deal_creation_max_currency = creditPool.currency.code
  creditPool.attributes.credit_info.insured_limit_currency = creditPool.currency.code

  return creditPool
}

export function enableDisableCreditForm(creditForm: CreditFormGroup, companyForm: CompanyFormGroup, readonly: boolean) {
  const companyType = companyForm.controls.type.value
  const cf = creditForm.getRawValue()
  disableIf(creditForm.controls.bwi_credit_ref_sent, readonly)
  disableIf(creditForm.controls.sent_date, readonly)
  disableIf(creditForm.controls.credit_extended, readonly)
  disableIf(creditForm.controls.currency, readonly)
  disableIf(creditForm.controls.extended_date, readonly)
  disableIf(creditForm.controls.payment_term_type, readonly)
  disableIf(companyForm.controls.avg_days_to_due_date, readonly)
  disableIf(creditForm.controls.payment_ref_na, readonly || !cf.payment_term_type || cf.payment_term_type == 'secure')
  disableIf(creditForm.controls.payment_term_prepayment_percent, readonly)
  disableIf(creditForm.controls.payment_ref_days, readonly)
  disableIf(creditForm.controls.payment_ref_from_date, readonly)
  disableIf(creditForm.controls.payment_ref_reference_id, companyType === BUYER
    ? readonly || !cf.payment_term_type || cf.payment_term_type == 'open' // WEIRD
    : readonly)
  disableIf(creditForm.controls.payment_term_credit_type, readonly || !cf.payment_term_type || cf.payment_term_type == 'secure')
  disableIf(creditForm.controls.payment_term_secure_type, readonly || !cf.payment_term_type || cf.payment_term_type == 'open')
  disableIf(creditForm.controls.payment_ref_payment_method_open, readonly || !cf.payment_term_type || cf.payment_term_type == 'secure')
  disableIf(creditForm.controls.payment_ref_payment_method_secure, readonly || !cf.payment_term_type || cf.payment_term_type == 'open')
  // disableIf(creditForm.controls.custom_payment_terms, readonly)
  creditForm.controls.custom_payment_terms.controls.forEach(ptForm => {
    const pt = ptForm.getRawValue()
    disableIf(ptForm.controls.traders, readonly)
    disableIf(ptForm.controls.type, readonly)
    disableIf(ptForm.controls.na, readonly || !pt.type || pt.type == 'secure')
    disableIf(ptForm.controls.prepayment_percent, readonly)
    disableIf(ptForm.controls.days, readonly)
    disableIf(ptForm.controls.from_date, readonly)
    disableIf(ptForm.controls.reference_id, companyType === BUYER
      ? readonly || !pt.type || pt.type == 'open' // WEIRD
      : readonly)
    disableIf(ptForm.controls.credit_type, readonly || !pt.type || pt.type == 'secure')
    disableIf(ptForm.controls.secure_type, readonly || !pt.type || pt.type == 'open')
    disableIf(ptForm.controls.payment_method_open, readonly || !pt.type || pt.type == 'secure')
    disableIf(ptForm.controls.payment_method_secure, readonly || !pt.type || pt.type == 'open')
  })
  disableIf(creditForm.controls.credit_application_date, readonly)
  disableIf(creditForm.controls.credit_insurance_submitted_date, readonly)
  disableIf(creditForm.controls.credit_insurance_approval_date, readonly)
  disableIf(creditForm.controls.credit_insurance_expirary_date, readonly)
  disableIf(companyForm.controls.taxID, readonly)
  disableIf(companyForm.controls.duns, readonly)
  disableIf(companyForm.controls.credit_reporting_listings, readonly)
  disableIf(companyForm.controls.file_reference, readonly)
  disableIf(creditForm.controls.insurance_exp_date, readonly)
  disableIf(creditForm.controls.insured_limit, readonly)
  disableIf(creditForm.controls.insurance_provider, readonly)
  disableIf(creditForm.controls.max, readonly)
  disableIf(creditForm.controls.in_transit_max, readonly)
  disableIf(creditForm.controls.deal_creation_max, readonly)
}

export function newCarrierForm(carrier?: DeepReadonly<Carrier>) {
  return new FormGroup({
    type: new FormControl<DeepReadonly<SegmentType[]>>(carrier?.type || []),
    carrier_id: new FormControl<string>(carrier?.carrier_id),
  })
}

export function readCarrierForm(carrierForm: CarrierFormGroup): DeepReadonly<Partial<Carrier>> {
  const cf = carrierForm.getRawValue()
  return cf
}

export function newCompanyBulkForm() {
  return new FormGroup({
    canEditManagers: new FormControl(false),
    accountManagersToAdd: new FormControl<string[]>([]),
    accountManagersToDelete: new FormControl<string[]>([]),
    accountManagersToReplace: new FormControl<string[]>([]),
    canEditManager: new FormControl(false),
    managerInCommon: new FormControl<string>(undefined),
    canEditCoordinators: new FormControl(false),
    coordinatorsToAdd: new FormControl<string[]>([]),
    coordinatorsToDelete: new FormControl<string[]>([]),
    coordinatorsToReplace: new FormControl<string[]>([]),
    canEditCoordinator: new FormControl(false),
    coordinatorInCommon: new FormControl<string>(undefined),
    canEditLanguage: new FormControl(false),
    languageInCommon: new FormControl<string>(undefined),
  })
}
