import { Injectable } from '@angular/core'
import { groupBy, pick } from 'lodash-es'
import { Subject } from 'rxjs'
import { AuthApiService } from 'src/api/auth'
import { PackageTrackingApiService } from 'src/api/package-tracking'

const ALLOWED_FIELDS = ['tracking_number', 'deal_id', 'carrier', 'attributes', 'type', 'track']

/**
 * Package tracking service
 *
 * @see https://docs.google.com/document/d/1OXAcKAX4XjP4Y1GqOj2kQDwyLIXZlkie35XNJzLJ0Ag/edit
 *
 * @export
 * @returns
 */
@Injectable()
export class PackageTrackingService {
  constructor(
    private AuthApi: AuthApiService,
    private PackageTrackingApi: PackageTrackingApiService,
  ) {}

  packageTrackingChanged$ = new Subject<void>()

  async getPackagesByDeal(dealId: string) {
    const data = await this.getPackagesByDealIds([dealId])
    return data[dealId] || []
  }

  async getPackagesByDealIds(dealIds: string[]) {
    const data = await this.PackageTrackingApi.byDealIds(dealIds).then(r => r.data)
    return groupBy(data, 'deal_id')
  }

  /**
   * Create new packageTracking
   *
   * @param {any} packageTracking
   * @returns
   */
  async create(packageTracking) {
    const payload = pick(packageTracking, ALLOWED_FIELDS)
    const { account, user_id } = this.AuthApi.currentUser
    payload.user_id = user_id
    const { data } = await this.PackageTrackingApi.create(account, payload)
    this.packageTrackingChanged$.next()
    return data
  }

  /**
   * Update packageTracking
   *
   * @param {any} packageTracking
   * @returns
   */
  async update(packageTracking) {
    const { package_id } = packageTracking
    const payload = pick(packageTracking, ALLOWED_FIELDS)
    const { account } = this.AuthApi.currentUser
    const { data } = await this.PackageTrackingApi.update(account, package_id, payload)
    this.packageTrackingChanged$.next()
    return data
  }

  /**
   * Update packageTracking
   *
   * @param {any} packageTracking
   * @returns
   */
  async remove({ package_id }) {
    const { account } = this.AuthApi.currentUser
    const { data } = await this.PackageTrackingApi.delete(account, package_id)
    this.packageTrackingChanged$.next()
    return data
  }
}
