import { DecimalPipe } from '@angular/common'
import { Injectable } from '@angular/core'
import { CREDIT_NOTE_APPLIED, CREDIT_NOTE_APPROVED, CreditNote, Deal, ForexCurrency, IEpochRange, VENDOR_CREDIT_APPLIED, VENDOR_CREDIT_APPROVED, VendorCredit } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { compact, get } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { CostApiService } from 'src/api/cost/cost'
import { ProductTypeApiService } from 'src/api/product/type'
import { DateRangePickerService } from 'src/components/date-range-select/date-range-picker.service'
import { DealCalculatorService } from 'src/services/data/deal-calculator.service'
import { MeasuresService } from 'src/services/data/measures.service'
import { ProductsService } from 'src/services/data/products.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { dayjs } from '../dayjs'
import { ExcelService } from '../excel.service'
import { AccountsService } from './accounts.service'
import { CreditNotesService } from './credit-notes.service'
import { DealsService } from './deals.service'
import { UsersService } from './users.service'
import { VendorCreditsService } from './vendor-credits.service'

/**
 * Credit export service: WA-3426
 */
@Injectable()
export class CreditsExportService {
  constructor(
    private readonly Accounts: AccountsService,
    private readonly AuthApi: AuthApiService,
    private readonly CostApi: CostApiService,
    private readonly CreditNotes: CreditNotesService,
    private readonly DateRangePicker: DateRangePickerService,
    private readonly DealCalculator: DealCalculatorService,
    private readonly Deals: DealsService,
    private readonly decimalPipe: DecimalPipe,
    private readonly Excel: ExcelService,
    private readonly Measures: MeasuresService,
    private readonly Products: ProductsService,
    private readonly ProductTypeApi: ProductTypeApiService,
    private readonly toaster: ToasterService,
    private readonly Users: UsersService,
    private readonly VendorCredits: VendorCreditsService,
  ) {}

  showExportVendorCredits() {
    this.DateRangePicker.showDateRangePicker({
      title: 'Export Vendor Credits',
      description: 'Select Approved Date Range',
      okText: 'Export',
      okIcon: 'fa-cloud-arrow-down',
      operation: async dateRange => {
        try {
          await this.exportVendorCredits(dateRange)
          this.toaster.success('Vendor Credits exported successfully')
        } catch(err) {
          console.error('Unable to export Vendor Credits', err)
          this.toaster.error('Unable to export Vendor Credits', err)
          throw err
        }
      }
    })
  }

  showExportCreditNotes() {
    this.DateRangePicker.showDateRangePicker({
      title: 'Export Credit Notes',
      description: 'Select Approved Date Range',
      okText: 'Export',
      okIcon: 'fa-cloud-arrow-down',
      operation: async dateRange => {
        try {
          await this.exportCreditNotes(dateRange)
          this.toaster.success('Credit Notes exported successfully')
        } catch(err) {
          console.error('Unable to export Credit Notes', err)
          this.toaster.error('Unable to export Credit Notes', err)
          throw err
        }
      }
    })
  }

  private async exportVendorCredits({ from, to }: IEpochRange) {
    // TODO: WA-13837: move status & date filters to the backend
    const statuses = [VENDOR_CREDIT_APPROVED, VENDOR_CREDIT_APPLIED]
    const vendorCredits = await this.VendorCredits.getAllVendorCreditsRaw().then(credits => credits.filter(vc =>
      vc.approval?.date
      && from <= vc.approval.date
      && vc.approval.date <= to
      && statuses.includes(vc.status)))

    // TODO: WA-13837: preload data using bulk API
    let rows = await Promise.all(vendorCredits.map(vc =>
      this.vendorCreditMapping(vc).catch((err) => {
        console.error('Unable to map VC', err)
      })))

    const headers = [
      'Document Type', 'Vendor Invoice No.', 'Vendor Name', 'Posting Date', 'Document Date', 'Vendor Number',
      'Customer Dimension', 'Currency', 'Currency Exchange Rate', 'Payment Term', 'Payment Term Days', 'Deal Dimension',
      'Trader Dimension', 'Product Dimension', 'Country Dimension', 'Total Invoice Amount', 'Description', 'Quantity',
      'Amount', 'Customer Name Line', 'Deal Line', 'Trader Code Line', 'Product Code Line', 'Country Line', 'Estimated Amount',
      'Partial Margin', 'Document No.',
    ]
    rows = compact(rows)
    rows.unshift(headers)

    // export as csv
    this.Excel.download(rows, 'Vendor Credits')
  }

  private async exportCreditNotes({ from, to }: IEpochRange) {
    // TODO: WA-13837: move status & date filters to the backend
    const statuses = [CREDIT_NOTE_APPROVED, CREDIT_NOTE_APPLIED]
    const creditNotes = await this.CreditNotes.getAllCreditNotes().then(credits => credits.filter(cn =>
      cn.approval?.date
      && from <= cn.approval.date
      && cn.approval.date <= to
      && statuses.includes(cn.status)))

    // TODO: WA-13837: preload data using bulk API
    let rows = await Promise.all(creditNotes.map(cn =>
      this.creditNoteMapping(cn).catch(err => {
        console.error('Unable to map CN', err)
      })))

    const headers = [
      'Document Type', 'Document No.', 'Posting Date', 'Document Date', 'Customer No.',
      'Customer Dimension', 'Currency', 'Currency Exchange Rate', 'Due Date', 'Payment Term Days', 'Deal Dimension',
      'Trader Dimension', 'Product Dimension', 'Country Dimension', 'Total Invoice Amount', 'Description', 'Quantity',
      'Amount', 'Customer Name Line', 'Deal Line', 'Trader Code Line', 'Product Code Line', 'Country Line',
    ]
    rows = compact(rows)
    rows.unshift(headers)

    // export as csv
    this.Excel.download(rows, 'Credit Notes')
  }

  private formatUTCEpoch(epoch: number) {
    if (!epoch) {
      return ''
    }
    return dayjs.utc(epoch * 1000).format('DD-MM-YYYY')
  }

  private formatNumber(num: number) {
    if (!num) return ''
    return this.decimalPipe.transform(num, '1.2-2')
  }

  private getExchangeRateForDeal(deal: DeepReadonly<Deal>, currency: ForexCurrency | 'CAD') {
    if (currency === 'CAD') return 1

    const fxRates = deal.attributes.fx_rates
    const fxRatesRange = deal.attributes.fx_rates_ask_range

    const res = fxRates.rates[currency][fxRatesRange].ask
    if (!res) return undefined

    return res
  }

  private async vendorCreditMapping(item: DeepReadonly<VendorCredit>) {
    const { account } = this.AuthApi.currentUser

    const vendorCreditCost = await this.CostApi.get(item.cost_id).then(({ data }) => data)

    const [vendor, dv] = await Promise.all([
      this.Accounts.getAccountById(item.account),
      this.Deals.getDealView(item.deal_id, ['deal', 'offers', 'bids']).toPromise(),
    ])
    const firstDealProduct = await this.Products.getByIds([dv.offers[0].product]).then(res => res[dv.offers[0].product])

    const [productType, trader, buyer] = await Promise.all([
      this.ProductTypeApi.get(firstDealProduct.type_id).then(({ data }) => data),
      this.Users.getUsersByIds(account, [dv.deal.trader_user_id]).then(data => data[dv.deal.trader_user_id]),
      this.Accounts.getAccountById(dv.deal.buyer_id),
      this.DealCalculator.updateFxRates(dv.deal),
    ])
    const offer = dv.offers[0]
    const currency = vendorCreditCost?.amount.currency as ForexCurrency | 'CAD'
    const quantity = vendorCreditCost.type !== 'primary'
      ? '1'
      : this.formatNumber(this.Measures.convert(
          vendorCreditCost?.attributes?.actual_buy_weight || offer.weight.amount,
          offer.weight.unit,
          'LB'))
    return [
      'Vendor Credit', // 'Document Type'
      item.credit_note_id || '', // Vendor Invoice No.
      vendor?.name, // 'Vendor Name'
      this.formatUTCEpoch(get(item, 'approval.date')), // 'Posting Date'
      this.formatUTCEpoch(item.created), // 'Document Date'
      item.account, // 'Vendor Number'
      buyer?.name || '', // 'Customer Dimension'
      currency, // 'Currency'
      this.getExchangeRateForDeal(dv.deal, currency) || '', // 'Currency Exchange Rate'
      '', // 'Payment Term'
      '', // 'Payment Term Days'
      item.deal_id, // 'Deal Dimension'
      trader?.fullname || '', // 'Trader Dimension'
      productType?.name || '', // 'Product Dimension'
      dv.deal.attributes.docs_country || '', // 'Country Dimension'
      this.formatNumber(item.amount), // 'Total Invoice Amount'
      get(vendorCreditCost, 'service'), // 'Description'
      quantity, // 'Quantity'
      this.formatNumber(item.amount), // 'Amount'
      buyer?.name || '', // 'Customer Name Line'
      item.deal_id, // 'Deal Line'
      trader?.fullname || '', // 'Trader Code Line'
      productType?.name || '', // 'Product Code Line'
      dv.deal.attributes.docs_country || '', // 'Country Line'
      '0', // 'Estimated Amount'
      this.formatNumber(dv.deal.attributes.actual?.partial_margin || 0), // 'Partial Margin',
      item.credit_note_id,  // 'Document No.'
    ]
  }

  private async creditNoteMapping(item: DeepReadonly<CreditNote>) {
    const { account } = this.AuthApi.currentUser

    const [dv, creditNoteCost, buyer] = await Promise.all([
      this.Deals.getDealView(item.deal_id, ['deal', 'offers', 'bids']).toPromise(),
      this.CostApi.get(item.cost_id).then(({ data }) => data),
      this.Accounts.getAccountById(item.account),
    ])

    const [creditNoteProduct, trader] = await Promise.all([
      this.Products.getByIds([creditNoteCost.product_id]).then(res => res[creditNoteCost.product_id]),
      this.Users.getUsersByIds(account, [dv.deal.trader_user_id]).then(data => data[dv.deal.trader_user_id]),
      this.DealCalculator.updateFxRates(dv.deal),
      this.Measures.getMeasures(),
    ])
    const productType = await this.ProductTypeApi.get(creditNoteProduct.type_id).then(({ data }) => data)

    const currency = dv.bids[0].currency as ForexCurrency | 'CAD'
    const bid = dv.bids.find(bid => bid.product === creditNoteProduct.product_id)
    const quantity = creditNoteCost.type !== 'primary'
      ? '1'
      : this.formatNumber(this.Measures.convert(
          creditNoteCost.attributes?.actual_sell_weight || bid.weight.amount || 0,
          bid.weight.unit,
          'LB'))
    return [
      'Credit Note', // 'Document Type'
      item.cn_id, // 'Document No.'
      this.formatUTCEpoch(get(item, 'approval.date')), // 'Posting Date'
      this.formatUTCEpoch(item.created), // 'Document Date'
      buyer?.account || '', // 'Customer No.'
      buyer?.name || '', // 'Customer Dimension'
      currency, // 'Currency'
      this.getExchangeRateForDeal(dv.deal, currency) || '', // 'Currency Exchange Rate'
      '', // 'Due Date' 'Payment Term'
      '', // 'Payment Term Days'
      item.deal_id, // 'Deal Dimension'
      trader.fullname, // 'Trader Dimension'
      creditNoteProduct?.name || '', // 'Product Dimension'
      dv.deal.attributes.docs_country || '', // 'Country Dimension'
      this.formatNumber(item.amount), // 'Total Invoice Amount'
      '', // 'Description'
      quantity, // 'Quantity'
      this.formatNumber(item.amount), // 'Amount'
      buyer?.name || '', // 'Customer Name Line'
      item.deal_id, // 'Deal Line'
      trader.fullname, // 'Trader Code Line'
      productType?.name || '', // 'Product Code Line'
      dv.deal.attributes.docs_country || '', // 'Country Line'
    ]
  }
}
