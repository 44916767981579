import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Template } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('TemplateApi', downgradeInjectable(TemplateApiService))


@Injectable()
@AngularCopy()
export class TemplateApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(account: number, @AngularParams() params) {
    return this.http.get<{ data: Template[] }>(`${apiUrl}/${account}/templates`, { params }).toPromise()
  }

  create(account: number, data: Partial<Template>) {
    return this.http.post<{ data: Template }>(`${apiUrl}/${account}/templates`, data).toPromise()
  }

  get(account: number, id: string) {
    return this.http.get<{ data: Template }>(`${apiUrl}/${account}/templates/${id}`).toPromise()
  }

  update(account: number, id: string, data: Partial<Template>) {
    return this.http.put<{ data: Template }>(`${apiUrl}/${account}/templates/${id}`, data).toPromise()
  }

  delete(account: number, id: string) {
    return this.http.delete<{ data: Template }>(`${apiUrl}/${account}/templates/${id}`).toPromise()
  }

  parse(account: number, id: string, data) {
    return this.http.post<{ data: Template }>(`${apiUrl}/${account}/templates/${id}/parse`, data).toPromise()
  }

  getLanguages() {
    return this.http.get<{ data: any }>(`${apiUrl}/templates/languages`).toPromise()
  }

  translate(message, target) {
    return this.http.post<{ data: string }>(`${apiUrl}/templates/translate`, { message, target }).toPromise()
  }
}
