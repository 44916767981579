import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatchedOffer } from '@tradecafe/types/core';
import { identity } from 'lodash-es';
import { filter, map } from 'rxjs/operators';
import { loadAccounts } from 'src/app/store/accounts';
import { loadCurrencies } from 'src/app/store/currencies';
import { loadLocations } from 'src/app/store/locations';
import { loadProductCategories } from 'src/app/store/product-categories';
import { loadProductTypes } from 'src/app/store/product-types';
import { loadProducts } from 'src/app/store/products';
import { loadUsers } from 'src/app/store/users';
import { itTertiaryFreightCost, itTertiaryNotFreightCost } from 'src/services/data/costs.service';
import { ModalService } from 'src/shared/modal';
import { CostsListDialogComponent, CostsListDialogOptions, CostsListDialogResult } from './costs-list-dialog.component';


@Injectable()
export class CostsListDialogService {

  constructor(
    private readonly modal: ModalService,
    private readonly store: Store,
  ) { }


  showAddFreightRates(matchedOffer: MatchedOffer) {
    return this.show({
      title: 'Add Freight Rates',
      matchedOffer,
      costs: [],
      canAddFreight: true,
      canRemoveCosts: true,
    }).pipe(
      map(r => r ? { costs: r.costs, remove: r.shouldRemoveCosts } : undefined)
    ).toPromise()
  }

  showAddTertiaryCosts(matchedOffer: MatchedOffer) {
    return this.show({
      title: 'Add Tertiary Costs',
      matchedOffer,
      costs: [],
      canAddTertiaryCost: true,
    }).pipe(
      map(r => r ? r.costs : undefined)
    ).toPromise()
  }

  showFreightCosts(matchedOffer: MatchedOffer) {
    return this.show({
      title: 'Select Costs',
      matchedOffer,
      costs: matchedOffer.costs,
      filter: itTertiaryFreightCost,
      canAddFreight: true,
    }).pipe(filter(identity), map(({ costs }) => {
      matchedOffer.costs = costs
      return matchedOffer
    }))
  }

  showTertiaryCosts(matchedOffer: MatchedOffer) {
    return this.show({
      title: 'Select Costs',
      matchedOffer,
      costs: matchedOffer.costs,
      filter: itTertiaryNotFreightCost,
      canAddTertiaryCost: true,
    }).pipe(filter(identity), map(({ costs }) => {
      matchedOffer.costs = costs
      return matchedOffer
    }))
  }

  private show(opts: CostsListDialogOptions) {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadProductCategories())
    this.store.dispatch(loadProductTypes())
    this.store.dispatch(loadProducts())
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadUsers({}))

    return this.modal.openDialog<
      CostsListDialogComponent,
      CostsListDialogOptions,
      CostsListDialogResult
    >(CostsListDialogComponent, opts, {
      panelClass: ['tc-dialog', 'modal-lg'],
      width: null,
      maxWidth: null,
      autoFocus: false,
    })
  }
}
