import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AccountObject, User } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { difference } from 'lodash-es';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { loadAccounts, reassignAccounts, reassignAccountsFailure, reassignAccountsSuccess, selectAccount, selectAccountEntities, selectAllAccounts } from 'src/app/store/accounts';
import { loadUsers, selectCoordinatorsOptions, selectUserEntities } from 'src/app/store/users';
import { environment } from 'src/environments/environment';
import { ScrollableDataSource } from 'src/services/table-utils/data-sources/scrollable-data-source';
import { replayForm } from 'src/shared/utils/replay-form';
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty';
import { ReassignAccountRow, ReassignmentsListComponent } from './reassignments-list/reassignments-list.component';

const { tradecafeAccount } = environment

export interface ReassignAccountsOptions {
  sourceUser: DeepReadonly<User>
}

@Component({
  selector: 'tc-reassign-accounts-form',
  templateUrl: './reassign-accounts-form.component.html',
  styleUrls: ['./reassign-accounts-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReassignAccountsFormComponent extends OnDestroyMixin implements AfterViewInit {

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<ReassignAccountsOptions, void>,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) private dialogData: ReassignAccountsOptions,
  ) { super() }

  protected readonly sourceUser = this.dialogData.sourceUser

  private tradecafe$ = this.store.pipe(select(selectAccount(tradecafeAccount)), waitNotEmpty())

  // reassignments from storage. only lines related to sourceUser
  private snapshot$ = this.tradecafe$.pipe(map(tc =>
    tc.coordinator_reassignments?.filter(r => r.prev === this.sourceUser.user_id) || []))
  // form value
  private reassignments$ = new ReplaySubject<AccountObject['coordinator_reassignments']>(1)

  protected readonly targetUser = new FormControl<string>('')
  protected readonly targetUser$ = combineLatest([
    replayForm(this.targetUser),
    this.store.pipe(select(selectUserEntities), waitNotEmpty())
  ]).pipe(map(([targetUserId, users]) => users[targetUserId]))
  protected readonly targetUsers$ = this.store.pipe(select(selectCoordinatorsOptions()), waitNotEmpty(), map(users =>
    users.filter(u => u.id !== this.sourceUser.user_id)))
  protected readonly type = new FormControl<'permanent'|'temporary'>('permanent')
  protected readonly types = ['permanent', 'temporary']

  protected readonly canUnassign$ = combineLatest([this.reassignments$, this.targetUser$]).pipe(map(([reassignments, targetUser]) => reassignments.some(r => r.next === targetUser?.user_id)))
  protected readonly inProgress$ = new BehaviorSubject<'loading' | 'save' | undefined>('loading')

  protected readonly dataSource = new ScrollableDataSource(
    combineLatest([
      replayForm(this.targetUser),
      this.snapshot$,
      this.reassignments$,
      this.tradecafe$,
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
      this.store.pipe(select(selectAllAccounts), waitNotEmpty()),
    ]).pipe(map(([targetUserId, snapshot, reassignments, tradecafe, users, accounts]) => accounts
      .filter(acc => everAssignedToLc(tradecafe, acc, this.sourceUser.user_id))
      .map((acc): ReassignAccountRow => {
        const otherAssignee = users[reassignments.find(r => r.account === acc.account && r.next !== targetUserId)?.next]
        const selectedAssignee = users[reassignments.find(r => r.account === acc.account && r.next === targetUserId)?.next]
        const otherAssigneeOld = users[snapshot.find(r => r.account === acc.account && r.next !== targetUserId)?.next]
        const selectedAssigneeOld = users[snapshot.find(r => r.account === acc.account && r.next === targetUserId)?.next]
        const dirty = !otherAssignee !== !otherAssigneeOld || !selectedAssignee !== !selectedAssigneeOld
        const assignee = selectedAssignee || otherAssignee
        const unassignee = !selectedAssignee && !otherAssignee && (selectedAssigneeOld || otherAssigneeOld)
        return {
          account: acc.account,
          name: acc.name,
          primary: acc.coordinator === this.sourceUser.user_id ||
            [...reassignments, ...snapshot].some(r => r.account === acc.account && r.primary),
          associate: acc.coordinators?.includes(this.sourceUser.user_id) ||
            [...reassignments, ...snapshot].some(r => r.account === acc.account && !r.primary),
          disabled: !targetUserId || !!otherAssignee,
          dirty,
          hint: assignee
            ? (dirty ? `Reassign to ${assignee.fullname}` : `Reassigned to ${assignee.fullname}`)
            : unassignee && `Unassign ${unassignee.fullname}`
        }
      }))));

  @ViewChild(ReassignmentsListComponent) list: ReassignmentsListComponent

  ngAfterViewInit(): void {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadUsers({}))
    this.snapshot$.pipe(take(1), untilComponentDestroyed(this)).subscribe(snapshot => {
      this.reassignments$.next(snapshot)
      this.inProgress$.next(undefined) // loaded
    })
    this.reassignments$.pipe(untilComponentDestroyed(this)).subscribe(reassignments => {
      this.list.selection.selectedIds.clear()
      reassignments.map(r => r.account).forEach(account => this.list.selection.selectedIds.set(account, true))
      this.list.selection.selectedIds$.next(reassignments.map(r => r.account))
    })
    this.actions$.pipe(ofType(reassignAccountsSuccess, reassignAccountsFailure), untilComponentDestroyed(this)).subscribe(() => {
      this.inProgress$.next(undefined)
    })
  }

  selectionChanged(selectedIds: number[]) {
    combineLatest([
      replayForm(this.targetUser),
      this.reassignments$, // component cached value. not yet in store
      this.tradecafe$, // from store
      this.store.pipe(select(selectAccountEntities), waitNotEmpty()), // from store
    ]).pipe(take(1), untilComponentDestroyed(this)).subscribe(([targetUserId, reassignments, tradecafe, accounts]) => {
      selectedIds = selectedIds.filter(account => this.dataSource.data.find(r => r.account === account && !r.disabled))
      const original = reassignments.filter(r => r.next === targetUserId).map(r => r.account)
      const unassigned = difference(original, selectedIds)
      const assigned = difference(selectedIds, original)
      if (!unassigned.length && !assigned.length) return console.warn('this should')
      this.reassignments$.next([
        // keep records not related to currently selected target user and records which were not unassigned this time
        ...reassignments.filter(r => r.next !== targetUserId || !unassigned.includes(r.account)),
        // add records assigned this time
        ...assigned.map(account => ({
          account,
          prev: this.sourceUser.user_id,
          next: targetUserId,
          primary: isPrimaryLc(tradecafe, accounts[account], this.sourceUser.user_id)
        }))
      ])
    })
  }

  unassign() {
    combineLatest([replayForm(this.targetUser), this.reassignments$])
    .pipe(take(1), untilComponentDestroyed(this)).subscribe(([targetUserId, reassignments]) => {
      // keep records not related to currently selected target user and records which were not unassigned this time
      this.reassignments$.next(reassignments.filter(r => r.next !== targetUserId))
    })
  }

  save() {
    if (this.inProgress$.value) return

    combineLatest([this.tradecafe$, this.reassignments$])
    .pipe(take(1), untilComponentDestroyed(this)).subscribe(([tradecafe, reassignments]) => {
      this.inProgress$.next('save')
      this.store.dispatch(reassignAccounts({
        coordinator_reassignments: [
          ...tradecafe.coordinator_reassignments?.filter(r => r.prev !== this.sourceUser.user_id) || [],
          ...reassignments
        ],
      }))
    })
  }

  close() {
    this.dialogRef.close()
  }
}

function everAssignedToLc(tradecafe: DeepReadonly<AccountObject>, acc: DeepReadonly<AccountObject>, coordinatorId: string) {
  return isPrimaryLc(tradecafe, acc, coordinatorId) || isAssociateLc(tradecafe, acc, coordinatorId)
}

function isPrimaryLc(tradecafe: DeepReadonly<AccountObject>, acc: DeepReadonly<AccountObject>, coordinatorId: string) {
  return acc.coordinator === coordinatorId || tradecafe.coordinator_reassignments?.some(r =>
    r.primary && r.account === acc.account && r.prev === coordinatorId)
}

function isAssociateLc(tradecafe: DeepReadonly<Pick<AccountObject, 'coordinator_reassignments'>>, acc: DeepReadonly<AccountObject>, coordinatorId: string) {
  return acc.coordinators?.includes(coordinatorId) || tradecafe.coordinator_reassignments?.some(r =>
    !r.primary && r.account === acc.account && r.prev === coordinatorId)
}
