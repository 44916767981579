import { Injectable } from "@angular/core";
import { AccountObject, AccountProductSpec, ItemType, Product, ProductCategory, ProductType } from "@tradecafe/types/core";
import { DeepReadonly } from "@tradecafe/types/utils";
import { cloneDeep, differenceBy } from 'lodash-es';
import { ConfirmModalService } from "src/components/confirm/confirm-modal.service";
import { AccountsService } from "src/services/data/accounts.service";
import { ModalService } from "src/shared/modal";
import { ToasterService } from "src/shared/toaster/toaster.service";
import { CompanyProfileFormComponent, CompanyProfileFormOptions } from "./company-profile-form.component";


export interface CompanyProfileDialogOptions {
  title: string
  isBulkEdit: boolean
  company: DeepReadonly<AccountObject>
  selectedCompanies: DeepReadonly<AccountObject[]>
  rows: DeepReadonly<ProfileRow[]>
}

export interface ProfileRow {
  profileLine: AccountProductSpec
  product: Product
  category: ProductCategory
  type: ProductType
  itemType: ItemType
  businessType: string
}


@Injectable()
export class CompanyProfileFormService {
  constructor(
    private readonly Accounts: AccountsService,
    private readonly ConfirmModal: ConfirmModalService,
    private readonly modal: ModalService,
    private readonly toaster: ToasterService,
  ) {}

  // async showAddProfile({ isBulkEdit: true, ...opts: Omit<CompanyProfileDialogOptions, 'title'|'isBulkEdit'> }): Promise<AccountObject[]>
  // async showAddProfile({ isBulkEdit: false, ...opts: Omit<CompanyProfileDialogOptions, 'title'|'isBulkEdit'> }): Promise<AccountObject>
  showAddProfile(opts: Omit<CompanyProfileDialogOptions, 'title'>) {
    return this.showProfileForm({ ...opts, title: 'Add new Profile' })
  }

  // async showEditProfile({ isBulkEdit: true, ...opts: Omit<CompanyProfileDialogOptions, 'title'|'isBulkEdit'> }): Promise<AccountObject[]>
  // async showEditProfile({ isBulkEdit: false, ...opts: Omit<CompanyProfileDialogOptions, 'title'|'isBulkEdit'> }): Promise<AccountObject>
  showEditProfile(opts: Omit<CompanyProfileDialogOptions, 'title'>) {
    return this.showProfileForm({ ...opts, title: 'Edit Profile' })
  }

  // async showDeleteProfile({ isBulkEdit: true, ...opts: Omit<CompanyProfileDialogOptions, 'title'|'isBulkEdit'> }): Promise<AccountObject[]>
  // async showDeleteProfile({ isBulkEdit: false, ...opts: Omit<CompanyProfileDialogOptions, 'title'|'isBulkEdit'> }): Promise<AccountObject>
  async showDeleteProfile(opts: Omit<CompanyProfileDialogOptions, 'title'>) {
    await this.ConfirmModal.show({
      title: 'Warning',
      description: 'Are you sure you want to delete these products?',
    })

    // make api call(s) to update account-products
    if (opts.isBulkEdit) {
      try {
        const companies = await this.removeProductSpecs(opts.selectedCompanies, opts.rows)
        return companies
      } catch (err) {
        console.error('Unable to update products in these accounts', err)
        this.toaster.error('Unable to update products in these accounts', err)
        throw err
      }
    } else {
      try {
        const [company] = await this.removeProductSpecs([opts.company], opts.rows)
        return company
      } catch (err) {
        console.error('Unable to update products in this account', err)
        this.toaster.error('Unable to update products in this account', err)
        throw err
      }
    }
  }

  private removeProductSpecs(companies: DeepReadonly<AccountObject[]>, rows: DeepReadonly<ProfileRow[]>) {
    return Promise.all(companies.map(company =>
      this.Accounts.updateAccountProducts(company, differenceBy(
        company.products_spec,
        rows.map(r => r.profileLine),
        x => x.product_id + x.item_type_id
      ))))
  }

  private showProfileForm(opts: CompanyProfileDialogOptions) {
    const oneProduct = opts.rows[0]
    return this.modal.openDialog<CompanyProfileFormComponent, CompanyProfileFormOptions, DeepReadonly<AccountObject | AccountObject[]>>(
      CompanyProfileFormComponent,
      {
        title: opts.title,
        company: opts.company,
        defaults: {
          // NOTE: this could be related to company.attributes.business_type_id
          businessTypeId: oneProduct?.category.type,
          categoryId: oneProduct?.product?.category_id,
          typeId: oneProduct?.product?.type_id,
          itemTypeId: oneProduct?.itemType?.item_type_id,
          products_spec: opts.rows?.map(p => p.profileLine),
        },
        isBulkEdit: opts.isBulkEdit,
        selectedCompanies: cloneDeep(opts.selectedCompanies),
      }, {
        panelClass: ['tc-dialog', 'modal-lg'],
        width: null,
        maxWidth: null,
        autoFocus: false,
        disableClose: true,
      }).toPromise()
  }
}
