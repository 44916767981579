import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { PackageTracking } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('PackageTrackingApi', downgradeInjectable(PackageTrackingApiService))


@Injectable()
@AngularCopy()
export class PackageTrackingApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(@AngularParams() params) {
    return this.http.get<{ data: PackageTracking[] }>(`${apiUrl}/package-tracking`, { params }).toPromise()
  }

  byDealIds(deal_ids: string[]) {
    return this.http.post<{ data: PackageTracking[] }>(`${apiUrl}/package-tracking/by-multiple-deals?limit=${Number.MAX_SAFE_INTEGER}`, { deal_ids }).toPromise()
  }

  create(account: number, data: Partial<PackageTracking>) {
    return this.http.post<{ data: PackageTracking[] }>(`${apiUrl}/package-tracking/${account}`, data).toPromise()
  }

  get(account: number, id: string) {
    return this.http.get<{ data: PackageTracking[] }>(`${apiUrl}/package-tracking/${id}/${account}`).toPromise()
  }

  update(account: number, id: string, data: Partial<PackageTracking>) {
    return this.http.put<{ data: PackageTracking[] }>(`${apiUrl}/package-tracking/${id}/${account}/track`, data).toPromise()
  }


  delete(account: number, id: string) {
    return this.http.delete<{ data: PackageTracking[] }>(`${apiUrl}/package-tracking/${id}/${account}`).toPromise()
  }

  track(account: number, id: string, track) {
    return this.http.put<{ data: PackageTracking[] }>(`${apiUrl}/package-tracking/${id}/${account}/track`, { track }).toPromise()
  }
}
