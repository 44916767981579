import { Injectable } from '@angular/core'
import { TrackingProvider } from '@tradecafe/types/core'
import { keyBy, memoize, pick } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { TrackingProviderApiService } from 'src/api/shipment-routing/tracking-provider'

const ALLOWED_FIELDS = ['name', 'url', 'type', 'attributes']
const QUERY_ALL = {limit: Number.MAX_SAFE_INTEGER}


/**
 * TrackingProviders service
 *
 * @export
 * @returns
 */
@Injectable()
export class TrackingProvidersService {
  constructor(
    private AuthApi: AuthApiService,
    private TrackingProviderApi: TrackingProviderApiService,
  ) {}

  private readonly fetchAllCached = memoize(this.fetchAll)


  async getById(tracking_providers_id) {
    const { data } = await this.TrackingProviderApi.get(tracking_providers_id)
    return data
  }

  async getByIds(ids) {
    const all = await this.fetchAllCached()
    const index = keyBy(all, 'tracking_providers_id')
    return ids ? pick(index, ids) : index
  }

  async getTrackingProviders() {
    return this.fetchAllCached()
  }

  private async fetchAll() {
    const { data } = await this.TrackingProviderApi.list(QUERY_ALL)
    return data
  }

  async create(trackingProvider: Partial<TrackingProvider>) {
    const {user_id} = this.AuthApi.currentUser
    const payload = pick(trackingProvider, ALLOWED_FIELDS)
    payload.user_id = user_id
    const { data } = await this.TrackingProviderApi.create(payload)
    return data
  }

  async update(trackingProvider: Partial<TrackingProvider>) {
    const {user_id} = this.AuthApi.currentUser
    const payload = pick(trackingProvider, ALLOWED_FIELDS)
    payload.user_id = user_id
    const { data } = await this.TrackingProviderApi.update(trackingProvider.tracking_providers_id, payload)
    return data
  }

  async remove(trackingProvider: Partial<TrackingProvider>) {
    const { data } = await this.TrackingProviderApi.delete(trackingProvider.tracking_providers_id)
    return data
  }
}
