<form [formGroup]="companyForm" (submit)="save()">
  <h2 mat-dialog-title>
    <mat-icon class="fa far fa-circle-info"></mat-icon>
    {{ isBulkEdit ? '' : companyForm.controls.name.value || '' }} Company information
  </h2>
  <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="setSelectedTab($event)">
    @if (displayedTabs.includes(tabs.DETAILS)) {
      <mat-tab label="DETAILS">
        <tc-company-details-tab
          [readonly]="!(isAdminManagerSuperuser || isJuniorAdmin)"
          [company$]="company$"
          [bulkForm]="bulkForm"
          [carrierForm]="carrierForm"
          [companyForm]="companyForm"
          [isBulkEdit]="isBulkEdit"
          [selectedCompanies$]="selectedCompanies$"
        ></tc-company-details-tab>
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.PROFILE)) {
      <mat-tab label="PROFILE">
        <tc-company-profile-tab
          [companyForm]="companyForm"
          [isBulkEdit]="isBulkEdit"
          [company$]="this.company$"
          [selectedCompanies$]="selectedCompanies$"
        ></tc-company-profile-tab>
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.ADDRESSES)) {
      <mat-tab label="ADDRESSES">
        <tc-company-addresses-tab
          [companyForm]="companyForm"
          [company$]="company$"
        ></tc-company-addresses-tab>
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.LOCATIONS)) {
      <mat-tab label="LOCATIONS">
        <tc-company-locations-tab
          [companyForm]="companyForm"
        ></tc-company-locations-tab>
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.TAX_PRICING)) {
      <mat-tab label="TAX/PRICING">
        <tc-company-pricing-tab
          [pricingForm]="companyForm.controls.pricing"
          [companyForm]="companyForm"
        ></tc-company-pricing-tab>
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.CREDIT)) {
      <mat-tab label="CREDIT">
        @if (companyAccount$ | async) {
          <tc-company-credit-tab
            [companyForm]="companyForm"
            [creditForm]="creditForm"
            [creditPool$]="creditPool$"
            [companyAccount]="companyAccount$ | async"
            [editable]="!isJuniorAdmin"
          ></tc-company-credit-tab>
        }
        @if (!(companyAccount$ | async)) {
          <p>You need to create this company first, then you can update credit info for this company.</p>
        }
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.CONTACTS)) {
      <mat-tab label="CONTACTS">
        @if (companyAccount$ | async) {
          <tc-company-contacts-tab
            [company$]="company$"
          ></tc-company-contacts-tab>
        }
        @if (!(companyAccount$ | async)) {
          <p>You need to create this company first, then you can update contact info for this company.</p>
        }
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.DISTRIBUTION)) {
      <mat-tab label="DISTRIBUTION">
        <tc-company-distribution-tab
          [companyForm]="companyForm"
          [company]="company$ | async"
        ></tc-company-distribution-tab>
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.BANKING)) {
      <mat-tab label="BANKING">
        <tc-company-banking-tab
          [bankingForm]="companyForm.controls.banking"
        ></tc-company-banking-tab>
      </mat-tab>
    }
    @if (displayedTabs.includes(tabs.NOTES)) {
      <mat-tab label="NOTES">
        @if (companyAccount$ | async) {
          <tc-company-notes-tab
            [accountId]="companyAccount$ | async"
          ></tc-company-notes-tab>
        }
        @if (!(companyAccount$ | async)) {
          <p>You need to create this company first, then you can post notes for this company.</p>
        }
      </mat-tab>
    }
  </mat-tab-group>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <mat-dialog-actions>
    <div style="flex: 1">Step {{displayedTabs.indexOf(selectedTab) + 1}} of {{displayedTabs.length}}</div>
    @if (displayedTabs.indexOf(selectedTab) > 0) {
      <button mat-raised-button color="primary" type="button"
        (click)="prevStep()">
        <i class="fa fa-angle-left" aria-hidden="true"></i> Previous Step
      </button>
    }
    <button mat-button color="link" type="button">
      <i class="fa fa-print"></i> PRINT
    </button>

    <mat-divider [vertical]="true"></mat-divider>

    <button mat-button type="button" (click)="close()">
      <i class="fa fa-xmark" aria-hidden="true"></i>
      {{isReadonly ? 'Close' : 'Cancel'}}
    </button>
    @if (!isReadonly) {
      <button mat-button type="submit" [disabled]="inProgress$ | async"
        [class.tc-dirty-badge]="isDirtyAndValid$ | async"
        [class.tc-invalid-badge]="isInvalid$ | async"
        [matTooltip]="fieldsReport$ | async"
        matTooltipClass="pre-tooltip"
        matTooltipShowDelay="10000">
        <i class="fa" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
        ></i>
        Save
      </button>
    }

    @if (displayedTabs.indexOf(selectedTab) < displayedTabs.length - 1) {
      <button mat-raised-button color="primary" class="btn-icon-right" type="button"
        (click)="nextStep()">
        Next Step <i class="fa fa-angle-right" aria-hidden="true"></i>
      </button>
    }
    @if (displayedTabs.indexOf(selectedTab) >= displayedTabs.length - 1) {
      <button mat-raised-button color="primary" type="button"
        (click)="close()">
        Finish
      </button>
    }
  </mat-dialog-actions>
</form>
