import { Injectable } from '@angular/core'
import { AccountObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { identity } from 'lodash-es'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ModalService } from 'src/shared/modal/modal.service'
import { CompanyStatusFormComponent, CompanyStatusFormOptions } from './company-status-form.component'


@Injectable()
export class CompanyStatusFormService {
  constructor(private modal: ModalService) { }

  showCompanyStatus(company: DeepReadonly<AccountObject>): Observable<AccountObject> {
    return this.modal.openDialog<CompanyStatusFormComponent, CompanyStatusFormOptions, AccountObject>(CompanyStatusFormComponent, { company }, {
      panelClass: ['tc-dialog'],
      width: '450px',
      maxWidth: null,
      autoFocus: false,
      disableClose: true,
    }).pipe(filter(identity))
  }
}
