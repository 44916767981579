import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { Store, select } from '@ngrx/store'
import { accountBankingPaymentTypes } from '@tradecafe/types/core'
import { AuthApiService } from 'src/api/auth'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { disableIf } from 'src/shared/utils/disable-if'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CompanyBankingFormGroup } from '../company-form'


@Component({
  selector: 'tc-company-banking-tab',
  templateUrl: './company-banking-tab.component.html',
  styleUrl: './company-banking-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SelectSearchModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
  ]
})
export class CompanyBankingTabComponent implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly store: Store,
  ) {}

  @Input({ required: true }) bankingForm: CompanyBankingFormGroup

  // ref data
  protected readonly accountBankingPayments = accountBankingPaymentTypes
  protected readonly countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty())

  ngOnInit() {
    this.store.dispatch(loadCountries())
    disableIf(this.bankingForm, this.AuthApi.currentUser.role === 'junior-administrator')
  }
}
