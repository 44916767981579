import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { AbstractControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { Store, select } from '@ngrx/store'
import { ACCOUNT_ACTIVE, AccountObject, AccountType, CarrierTypes, CompanyStatuses, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { chain } from 'lodash-es'
import { Observable, combineLatest } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts'
import { loadBusinessTypes, selectAllBusinessTypes } from 'src/app/store/business-types'
import { loadCarriers, selectAllCarriers } from 'src/app/store/carriers'
import { loadUsers, selectAllUsers } from 'src/app/store/users'
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { environment } from 'src/environments/environment'
import { locales } from 'src/pages/admin/settings/templates/form/locales'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { disableIf } from 'src/shared/utils/disable-if'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CarrierFormGroup, CompanyBulkFormGroup, CompanyFormGroup } from '../company-form'

const { tradecafeAccount } = environment

@Component({
  selector: 'tc-company-details-tab',
  templateUrl: './company-details-tab.component.html',
  styleUrl: './company-details-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    SelectSearchModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatRadioModule,
    MatCheckboxModule,
    EpochFieldModule,
  ]
})
export class CompanyDetailsTabComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly toaster: ToasterService,
    private readonly store: Store,
  ) { super() }

  // input
  @Input({ required: true }) readonly: boolean
  @Input({ required: true }) company$: Observable<DeepReadonly<AccountObject>>
  @Input({ required: true }) companyForm: CompanyFormGroup
  @Input({ required: true }) carrierForm: CarrierFormGroup
  @Input({ required: true }) bulkForm: CompanyBulkFormGroup
  @Input({ required: true }) isBulkEdit: boolean
  @Input({ required: true }) selectedCompanies$: Observable<DeepReadonly<AccountObject[]>>

  // derivatives
  protected companyAccount$: Observable<number>
  protected managerUsersOfTradecafe$: Observable<User[]>;

  // ref data
  protected readonly locales = locales.map(([name, locale]) => ({ name, lang: locale }))
  protected readonly availableCompanyStatuses = CompanyStatuses
  protected readonly availableCarrierTypes = CarrierTypes
  protected readonly carriers$ = this.store.pipe(select(selectAllCarriers), waitNotEmpty())
  protected readonly companies$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty())
  protected readonly businessTypes$ = this.store.pipe(select(selectAllBusinessTypes), waitNotEmpty())
  protected readonly logisticUsersOfTradecafe$ = this.store.pipe(select(selectAllUsers), waitNotEmpty(), map(users =>
    users.filter(user => user.account === tradecafeAccount && user.role === 'logistics')))


  ngOnInit(): void {
    this.store.dispatch(loadBusinessTypes())
    this.store.dispatch(loadCarriers({}))
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadUsers({}))

    this.companyAccount$ = this.company$.pipe(map(company => company.account))

    replayForm(this.bulkForm.controls.canEditManagers).pipe(untilComponentDestroyed(this)).subscribe(canEditManagers => {
      disableIf(this.bulkForm.controls.accountManagersToAdd, canEditManagers)
      disableIf(this.bulkForm.controls.accountManagersToDelete, canEditManagers)
      disableIf(this.bulkForm.controls.accountManagersToReplace, canEditManagers)
    })
    replayForm(this.bulkForm.controls.canEditManager).pipe(untilComponentDestroyed(this)).subscribe(canEditManager => {
      disableIf(this.bulkForm.controls.managerInCommon, canEditManager)
    })
    replayForm(this.bulkForm.controls.canEditCoordinators).pipe(untilComponentDestroyed(this)).subscribe(canEditCoordinators => {
      disableIf(this.bulkForm.controls.coordinatorsToAdd, canEditCoordinators)
      disableIf(this.bulkForm.controls.coordinatorsToDelete, canEditCoordinators)
      disableIf(this.bulkForm.controls.coordinatorsToReplace, canEditCoordinators)
    })
    replayForm(this.bulkForm.controls.canEditCoordinator).pipe(untilComponentDestroyed(this)).subscribe(canEditCoordinator => {
      disableIf(this.bulkForm.controls.coordinatorInCommon, canEditCoordinator)
    })

    replayForm(this.companyForm).pipe(untilComponentDestroyed(this)).subscribe(cf => {
      this.companyForm.controls.coordinator.clearValidators()
      this.companyForm.controls.manager.clearValidators()
      this.companyForm.controls.managers.clearValidators()
      if (cf.status === ACCOUNT_ACTIVE) {
        this.companyForm.controls.coordinator.addValidators(Validators.required)
        if (!this.companyForm.controls.manager.value?.length) this.companyForm.controls.manager.addValidators(Validators.required)
        if (!this.companyForm.controls.manager.value) this.companyForm.controls.managers.addValidators(Validators.required)
      }
    })

    // find common values
    combineLatest([this.company$, this.selectedCompanies$]).pipe(untilComponentDestroyed(this)).subscribe(([company, selectedCompanies]) => {
      this.bulkForm.patchValue(
        this.isBulkEdit ? {
          coordinatorInCommon: getCommonMember(selectedCompanies, c => c.coordinator),
          managerInCommon: getCommonMember(selectedCompanies, c => c.manager),
          languageInCommon: getCommonMember(selectedCompanies, c => c.language),
        } : {
          coordinatorInCommon: company.coordinator,
          managerInCommon: company.manager,
          languageInCommon: company.language,
        }
      )
    })

    this.managerUsersOfTradecafe$ = this.company$.pipe(
      switchMap(company => this.store.pipe(
        select(selectAllUsers),
        map(users => this.filterUsersByCompanyType(company.type, users))
      ))
    );
  }

  protected onCompanyTypeChange() {
    this.managerUsersOfTradecafe$ = this.store.pipe(select(selectAllUsers),  map(users => this.filterUsersByCompanyType(this.companyForm.controls.type.value, users)))
    this.company$.pipe(take(1)).subscribe(account => {
      if (
        [AccountType.BUYER, AccountType.SUPPLIER].includes(this.companyForm.controls.type.value) && [AccountType.SERVICE_PROVIDER].includes(account.type) ||
        [AccountType.BUYER, AccountType.SUPPLIER].includes(account.type) && [AccountType.SERVICE_PROVIDER].includes(this.companyForm.controls.type.value)
      ) {
        this.companyForm.controls.manager.reset()
        this.companyForm.controls.managers.reset()
      }
    })
  }
  

  protected onCurrentCarrierTypeChange() {
    this.companyAccount$.pipe(take(1)).subscribe(account => {
      const { type, carrier_id } = this.carrierForm.getRawValue()
      const { name } = this.companyForm.getRawValue()
      if (carrier_id && !type?.length) {
        this.toaster.warning(`Carrier capabilities of ${name || account} will be disabled.`)
      }
    })
  }

  private filterUsersByCompanyType(type: AccountType, users: User[]): User[] {
    if (type === AccountType.SERVICE_PROVIDER) {
      return users.filter(user => user.account === tradecafeAccount && !user.archived && user.role === 'logistics');
    } else {
      return users.filter(user => user.account === tradecafeAccount && !user.archived && user.role === 'trader');
    }
  }

  protected checkForErrorsIn(formControl: AbstractControl, name: string): string {
    if (formControl.hasError('required')) return `${name} is required`
    if (formControl.hasError('pattern') || formControl.hasError('invalidEmail')) return `${name} is not valid`
    if (formControl.hasError('pattern') || formControl.hasError('invalidPhone')) return `${name} is not valid`
    return ''
  }
}

function getCommonMember<T>(sourceArray: DeepReadonly<T[]>, accessor: (t: T) => any) {
  if (!Array.isArray(sourceArray)) {
    return null;
  }

  const uniqueValues = chain(sourceArray)
    .compact()
    .map(accessor)
    .uniq()
    .value();

  return uniqueValues.length === 1 ? uniqueValues[0] : null;
}
