import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { TransitTime } from '@tradecafe/types/core'
import { loadLocations } from 'src/app/store/locations'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { ModalService } from 'src/shared/modal'
import { TransitTimesService } from '../transit-times.service'
import { TransitTimeFormComponent, TransitTimeFormOptions } from './transit-time-form.component'

@Injectable()
export class TransitTimeFormService {
  constructor(
    private modal: ModalService,
    private TransitTimes: TransitTimesService,
    private ConfirmModal: ConfirmModalService,
    private store: Store,
  ) {}

  create() {
    this.store.dispatch(loadLocations({}))
    return this.modal.openDialog<TransitTimeFormComponent, TransitTimeFormOptions, TransitTime>(TransitTimeFormComponent).toPromise()
  }

  update(transitTime: TransitTime) {
    this.store.dispatch(loadLocations({}))
    return this.modal.openDialog<TransitTimeFormComponent, TransitTimeFormOptions, TransitTime>(TransitTimeFormComponent, { transitTime }).toPromise()
  }

  remove(transitTime: TransitTime) {
    return this.ConfirmModal.show({
      title: 'Delete this transit time?',
      description: 'Are you sure you want to delete this transit time?',
    })
    .then(() => this.TransitTimes.remove(transitTime))
  }
}
