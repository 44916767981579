<h2 mat-dialog-title>{{ title }}</h2>
<tc-costs-list [types]="['tertiary']"
  [units]="matchedOffer.offer.weight.unit"
  [matchedOffer]="matchedOffer"
  [costsForm]="costsForm"
  [filter]="filter"
  [fxRates]="fxRates"
  fxRatesRange="spot"
  [shipmentRates$]="shipmentRates$"
  (updateCost)="updateCost($event)"
  (removeCost)="removeCost($event)"
  [displayColumns]="['service', 'provider', 'createdBy', 'estAmt', 'fxRate', 'estAmtCAD', 'origin', 'destination', 'portLoading', 'portDischarge', 'dealSource', 'matchedOfferSource']"
></tc-costs-list>
@if (canRemoveCosts) {
  <mat-checkbox [formControl]="shouldRemoveCosts">
    Remove existing freight rates?
  </mat-checkbox>
}
<!-- <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar> -->
<div mat-dialog-actions align="end">
  @if (canAddTertiaryCost) {
    <button mat-raised-button color="primary" type="button"
      (click)="showAddCost()">
      <i class="fa fa-plus-square"></i> Add New
    </button>
  }
  @if (canAddFreight) {
    <button mat-raised-button color="primary" type="button"
      (click)="showAddFreight()">
      <i class="fa fa-plus-square"></i> Add Freight
    </button>
  }
  <span style="flex: 1"></span>
  <button mat-button type="button" (click)="cancel()">Cancel</button>
  <button mat-raised-button type="button" color="primary" (click)="save()">
    <i class="fa fa-save"></i>
    Save
  </button>
</div>
