import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { TransitTime } from '@tradecafe/types/core'
import { BehaviorSubject } from 'rxjs'
import { selectAllLocations } from 'src/app/store/locations'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { TransitTimesService } from '../transit-times.service'

export interface TransitTimeFormOptions {
  transitTime?: TransitTime
}

@Component({
  templateUrl: './transit-time-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitTimeFormComponent {
  constructor(
    private TransitTimes: TransitTimesService,
    private toaster: ToasterService,
    private store: Store,
    private dialogRef: MatDialogRef<TransitTimeFormComponent, TransitTime>,
    @Inject(MAT_DIALOG_DATA) private dialogData: TransitTimeFormOptions = {},
  ) {}

  protected readonly SegmentTypes = [
    { id: 'rail', name: 'Rail' },
    { id: 'land', name: 'Land' },
    { id: 'air', name: 'Air' },
    { id: 'sea', name: 'Sea' },
  ]
  protected readonly isNew = !this.dialogData.transitTime
  protected readonly inProgress$ = new BehaviorSubject<'save' | undefined>(undefined)
  protected readonly locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())

  protected readonly ttForm = new FormGroup({
    name: new FormControl(!this.isNew ? this.dialogData.transitTime.name : '', Validators.required),
    delivery: new FormControl(!this.isNew ? this.dialogData.transitTime.delivery : '', Validators.required),
    pickup: new FormControl(!this.isNew ? this.dialogData.transitTime.pickup : '', Validators.required),
    type: new FormControl(!this.isNew ? this.dialogData.transitTime.type : '', [Validators.required, Validators.min(1)]),
    time: new FormControl(!this.isNew ? this.dialogData.transitTime.time : undefined, Validators.required),
  })

  protected async save() {
    if (this.inProgress$.value) return
    this.ttForm.markAllAsTouched()
    this.ttForm.updateValueAndValidity()
    if (!this.ttForm.valid) return

    this.inProgress$.next('save')

    const transitTime = this.ttForm.getRawValue()
    try {
      const stored = this.isNew
        ? await this.TransitTimes.create(transitTime)
        : await this.TransitTimes.update({ transit_time_id: this.dialogData.transitTime.transit_time_id, ...transitTime})
      this.toaster.success(`Transit Time saved successfully.`)
      this.dialogRef.close(stored)
    } catch (err) {
      console.error('Unable to save Transit Time', err)
      this.toaster.error('Unable to save Transit Time', err)
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  protected async cancel() {
    this.dialogRef.close()
  }

}
