<form [formGroup]="creditForm">
  @if (isNotBuyer$ | async) {
    <h5>Credit reference documents</h5>
  }
  @if (isNotBuyer$ | async) {
    <div style="display: grid; gap: 15px">
      <div style="display: grid; grid-template-columns: minmax(0, 2fr) minmax(0, 1fr); gap: 15px; align-items: center">
        <mat-checkbox [formControl]="creditForm.controls.bwi_credit_ref_sent">
          BWI credit reference and banking info sent
        </mat-checkbox>
        <tc-epoch-field placeholder="Date Sent"
          [ctrl]="creditForm.controls.sent_date"
        ></tc-epoch-field>
      </div>
      <div style="display: grid; grid-template-columns: minmax(0, 2fr) minmax(0, 1fr); gap: 15px">
        <div style="display: grid; grid-template-columns: minmax(0, 7fr) minmax(0, 4fr); gap: 15px">
          <mat-form-field>
            <mat-label>Credit extended</mat-label>
            <input matInput currencyMask [options]="currencyOptions$ | async"
              [formControl]="creditForm.controls.credit_extended">
          </mat-form-field>
          <tc-select-search placeholder="Currency"
            [ctrl]="creditForm.controls.currency"
            [items]="currencies$ | async" bindValue="code" bindLabel="code"
          ></tc-select-search>
        </div>
        <tc-epoch-field placeholder="Date extended"
          [ctrl]="creditForm.controls.extended_date"
        ></tc-epoch-field>
      </div>
      <div style="display: grid; grid-template-columns: minmax(0, 1fr) minmax(0, 2fr); gap: 0 15px">
        <h5 class="subtitle">Payment term</h5>
        <h5 class="subtitle">Payment reference</h5>
        <div style="display: grid; gap: 15px">
          <mat-radio-group [formControl]="creditForm.controls.payment_term_type"
            style="display: grid; grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); gap: 0 15px">
            <mat-radio-button value="open">Open</mat-radio-button>
            <mat-radio-button value="secure"> Secure</mat-radio-button>
          </mat-radio-group>
          <mat-form-field>
            <mat-label>Average Days to Due Date</mat-label>
            <input matInput type="number" step="any"
              [formControl]="companyForm.controls.avg_days_to_due_date">
          </mat-form-field>
        </div>
        <div style="display: grid; gap: 15px">
          <div style="display: grid; gap: 15px; align-items: center;"
            [style.gridTemplateColumns]="creditForm.controls.payment_term_type.value === 'secure'
              ? 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 4fr)'
              : 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 5fr)'">
            <mat-checkbox [formControl]="creditForm.controls.payment_ref_na">
              N/A
            </mat-checkbox>
            @if (creditForm.controls.payment_term_type.value === 'secure') {
              <mat-form-field>
                <mat-label>%</mat-label>
                <input matInput type="number" min="0" max="100"
                  [formControl]="creditForm.controls.payment_term_prepayment_percent">
              </mat-form-field>
            }
            <mat-form-field>
              <mat-label>No. of days</mat-label>
              <input matInput type="number" min="0"
                [formControl]="creditForm.controls.payment_ref_days">
            </mat-form-field>
            <tc-select-search placeholder="From this date"
              [ctrl]="creditForm.controls.payment_ref_from_date"
              [items]="PaymentTermsDates" bindValue="id" bindLabel="name"
            ></tc-select-search>
            <tc-select-search placeholder="Payment Reference"
              [style.gridColumn]="creditForm.controls.payment_term_type.value === 'secure' ? '2 / 5' : '2 / 4'"
              [ctrl]="creditForm.controls.payment_ref_reference_id"
              [items]="paymentReferences$ | async" bindLabel="reference" bindValue="payment_reference_id"
            ></tc-select-search>
          </div>
        </div>
      </div>
      @for (pt of creditForm.controls.custom_payment_terms.controls; track pt) {
        <div class="row">
          <div class="col-sm-12">
            <tc-select-search placeholder="Traders" [multiple]="true"
              [ctrl]="pt.controls.traders"
              [items]="traders$ | async" bindLabel="fullname" bindValue="id"
            ></tc-select-search>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="subtitle">Payment term</h5>
              </div>
              <mat-radio-group [formControl]="pt.controls.type" class="col-sm-12">
                <mat-radio-button value="open" class="col-sm-6 box-control">Open</mat-radio-button>
                <mat-radio-button value="secure" class="col-sm-6 box-control">Secure</mat-radio-button>
              </mat-radio-group>
              <div class="col-sm-12">
                <mat-form-field>
                  <mat-label>Average Days to Due Date</mat-label>
                  <input matInput type="number" step="any" [formControl]="companyForm.controls.avg_days_to_due_date">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="subtitle">Payment reference</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <mat-checkbox [formControl]="pt.controls.na">
                  N/A
                </mat-checkbox>
              </div>
              @if (pt.controls.type.value === 'secure') {
                <div class="col-sm-2">
                  <mat-form-field>
                    <mat-label>%</mat-label>
                    <input matInput type="number" min="0" max="100" [formControl]="pt.controls.prepayment_percent">
                  </mat-form-field>
                </div>
              }
              <div class="col-sm-8"
                [class.col-sm-10]="pt.controls.type.value !== 'secure'"
                [class.col-sm-8]="pt.controls.type.value === 'secure'"
                >
                <div class="row">
                  <div class="col-sm-4">
                    <mat-form-field>
                      <mat-label>No. of days</mat-label>
                      <input matInput type="number" min="0" [formControl]="pt.controls.days">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-8">
                    <tc-select-search placeholder="From this date"
                      [ctrl]="pt.controls.from_date"
                      [items]="PaymentTermsDates" bindValue="id" bindLabel="name"
                    ></tc-select-search>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-offset-2 col-sm-10 form-group">
                <tc-select-search placeholder="Payment Reference"
                  [ctrl]="pt.controls.reference_id"
                  [items]="paymentReferences$ | async" bindLabel="reference" bindValue="payment_reference_id"
                ></tc-select-search>
              </div>
            </div>
          </div>
        </div>
      }
      <!--
      <div class="row">
        <div class="col-sm-12" style="padding-bottom: 15px">
          <button class="btn btn-link modal-table-add" type="button" (click)="addPaymentTerms()">
            <i class="fa fa-fw fa-plus" aria-hidden="true"></i> Add Trader Specific Terms
          </button>
        </div>
      </div>
      -->
      <div class="row">
        <div class="clearfix"></div>
        <div class="col-sm-6">
          @if (isServiceProvider$ | async) {
            <div class="row">
              <div class="col-sm-6">
                @if (!(insuranceStatementFile$ | async)) {
                  <div>
                    <button type="button" mat-raised-button color="accent"
                      (click)="uploadClicked()"
                      [disabled]="!(creditPoolExists$ | async)"
                  matTooltip="Credit pool is not ready.
Please select currency
and click 'save' button."
                      [matTooltipDisabled]="creditPoolExists$ | async" matTooltipPosition="below" matTooltipClass="pre-tooltip">
                      <i class="fa fa-cloud-arrow-up"></i> Upload Insurance Statement
                    </button>
                    <input type="file" #insuranceStatementFile (change)="uploadClicked()" style="display: none">
                  </div>
                }
                @if (insuranceStatementFile$ | async; as isf) {
                  <div>
                    <a [href]="isf.link" target="_blank" download>{{isf.name}}</a>
                    @if (editable) {
                      <button mat-icon-button type="button"
                        (click)="removeInsuranceStatementFile()"
                        aria-label="Remove file">
                        <mat-icon class="fa fa-xmark"></mat-icon>
                      </button>
                    }
                  </div>
                }
              </div>
              <div class="col-sm-6">
                @if (insuranceStatementFile$ | async) {
                  <tc-epoch-field
                    placeholder="Insurance Exp. Date"
                    [ctrl]="creditForm.controls.insurance_exp_date"
                  ></tc-epoch-field>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }

  @if (isBuyer$ | async) {
    <div style="display: grid; gap: 15px">
      <section class="payment-terms" style="display: grid; grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); gap: 15px">
        <div style="display: grid; grid-template-columns: minmax(0, 2fr) minmax(0, 5fr) minmax(0, 5fr); gap: 15px; align-items: center">
          <h5 class="subtitle" style="grid-column: span 3">Payment terms</h5>
          <mat-radio-group [formControl]="creditForm.controls.payment_term_type" style="display: contents">
            <mat-radio-button value="open">Open</mat-radio-button>
            <tc-select-search placeholder="Credit type"
              [ctrl]="creditForm.controls.payment_term_credit_type"
              [items]="CreditTypes" bindLabel="name" bindValue="id"
            ></tc-select-search>
            <tc-select-search placeholder="Payment method"
              [ctrl]="creditForm.controls.payment_ref_payment_method_open"
              [items]="OpenPaymentMethods" bindLabel="name" bindValue="id"
            ></tc-select-search>
            <mat-radio-button value="secure">Secure</mat-radio-button>
            <tc-select-search placeholder="Secure type"
              [ctrl]="creditForm.controls.payment_term_secure_type"
              [items]="SecureTypes" bindLabel="name" bindValue="id"
            ></tc-select-search>
            <tc-select-search placeholder="Payment method"
              [ctrl]="creditForm.controls.payment_ref_payment_method_secure"
              [items]="paymentMethods$ | async" bindValue="code" bindLabel="method"
            ></tc-select-search>
          </mat-radio-group>
        </div>
        <div style="display: grid; grid-template-columns: minmax(0, 2fr) minmax(0, 10fr); gap: 15px">
          <h5 class="subtitle" style="grid-column: span 2">Payment reference</h5>
          <mat-checkbox [formControl]="creditForm.controls.payment_ref_na">
            N/A
          </mat-checkbox>
          <div style="display: grid; gap: 15px"
            [style.gridTemplateColumns]="(this.showPrepaymentPercent$ | async)
              ? 'minmax(0, 2fr) minmax(0, 2fr) minmax(0, 4fr)'
              : 'minmax(0, 3fr) minmax(0, 5fr)'">
            @if (this.showPrepaymentPercent$ | async) {
              <mat-form-field>
                <mat-label>%</mat-label>
                <input matInput type="number" min="0" max="100"
                  [formControl]="creditForm.controls.payment_term_prepayment_percent">
              </mat-form-field>
            }
            <mat-form-field>
              <mat-label>No. of days</mat-label>
              <input matInput type="number" min="0"
                [formControl]="creditForm.controls.payment_ref_days">
            </mat-form-field>
            <tc-select-search placeholder="From this date"
              [ctrl]="creditForm.controls.payment_ref_from_date"
              [items]="PaymentTermsDates" bindValue="id" bindLabel="name"
            ></tc-select-search>
          </div>
          <tc-select-search placeholder="Payment Reference" style="grid-column: span 2"
            [ctrl]="creditForm.controls.payment_ref_reference_id"
            [items]="paymentReferences$ | async" bindLabel="reference" bindValue="payment_reference_id"
          ></tc-select-search>
        </div>
      </section>
      @for (pt of creditForm.controls.custom_payment_terms.controls; track pt) {
        <div class="row">
          <div class="col-sm-12">
            <tc-select-search placeholder="Traders" [multiple]="true"
              [ctrl]="pt.controls.traders"
              [items]="traders$ | async" bindLabel="fullname" bindValue="id"
            ></tc-select-search>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="subtitle">Payment terms</h5>
              </div>
              <div class="col-sm-2 form-group">
                <mat-radio-group [formControl]="pt.controls.type">
                  <mat-radio-button value="open">Open</mat-radio-button>
                  <mat-radio-button value="secure">Secure</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-sm-5">
                <div class="row">
                  <div class="col-sm-12">
                    <tc-select-search placeholder="Credit type"
                      [ctrl]="pt.controls.credit_type"
                      [items]="CreditTypes" bindLabel="name" bindValue="id"
                    ></tc-select-search>
                  </div>
                  <div class="col-sm-12">
                    <tc-select-search placeholder="Secure type"
                      [ctrl]="pt.controls.secure_type"
                      [items]="SecureTypes" bindLabel="name" bindValue="id"
                    ></tc-select-search>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="row">
                  <div class="col-sm-12">
                    <tc-select-search placeholder="Payment method"
                      [ctrl]="pt.controls.payment_method_open"
                      [items]="OpenPaymentMethods" bindLabel="name" bindValue="id"
                    ></tc-select-search>
                  </div>
                  <div class="col-sm-12">
                    <tc-select-search placeholder="Payment method"
                      [ctrl]="pt.controls.payment_method_secure"
                      [items]="paymentMethods$ | async" bindValue="code" bindLabel="method"
                    ></tc-select-search>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="subtitle">Payment reference</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <mat-checkbox [formControl]="pt.controls.na">
                  N/A
                </mat-checkbox>
              </div>
              @if (pt.controls.type.value === 'secure' && ['prepayment', 'cash'].includes(pt.controls.secure_type.value)) {
                <div class="col-sm-2">
                  <mat-form-field>
                    <mat-label>%</mat-label>
                    <input matInput type="number" min="0" max="100"
                      [formControl]="pt.controls.prepayment_percent">
                  </mat-form-field>
                </div>
              }
              <div [class.col-sm-10]="pt.controls.type.value !== 'secure' || !['prepayment', 'cash'].includes(pt.controls.secure_type.value)"
                [class.col-sm-8]="pt.controls.type.value === 'secure' && ['prepayment', 'cash'].includes(pt.controls.secure_type.value)">
                <div class="row">
                  <div class="col-sm-4">
                    <mat-form-field>
                      <mat-label>No. of days</mat-label>
                      <input matInput type="number" min="0" [formControl]="pt.controls.days">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-8">
                    <tc-select-search placeholder="From this date"
                      [ctrl]="pt.controls.from_date"
                      [items]="PaymentTermsDates" bindValue="id" bindLabel="name"
                    ></tc-select-search>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 form-group">
                <tc-select-search placeholder="Payment Reference"
                  [ctrl]="pt.controls.reference_id"
                  [items]="paymentReferences$ | async" bindLabel="reference" bindValue="payment_reference_id"
                ></tc-select-search>
              </div>
            </div>
          </div>
        </div>
      }
      <!--
      <div class="row">
        <div class="col-sm-12" style="padding-bottom: 15px">
          <button class="btn btn-link modal-table-add" type="button" (click)="addPaymentTerms()">
            <i class="fa fa-fw fa-plus" aria-hidden="true"></i> Add Trader Specific Terms
          </button>
        </div>
      </div>
      -->
      <section class="credit-info" style="display: grid; grid-template-columns: minmax(0, 7fr) minmax(0, 5fr); gap: 0 50px">
        <h5 class="subtitle" style="grid-column: span 2">Credit info</h5>
        <div style="display: grid; grid-template-columns: minmax(0, 6fr) minmax(0, 1fr) minmax(0, 5fr); gap: 15px">
          <mat-form-field style="grid-column: span 2">
            <mat-label>Insured Limit</mat-label>
            <input matInput currencyMask [options]="currencyOptions$ | async"
              [formControl]="creditForm.controls.insured_limit">
          </mat-form-field>
          <tc-select-search placeholder="Currency"
            [ctrl]="creditForm.controls.currency"
            [items]="currencies$ | async" bindLabel="code" bindValue="code"
          ></tc-select-search>
          <mat-form-field style="grid-column: span 2">
            <mat-label>Insurance provider</mat-label>
            <input matInput [formControl]="creditForm.controls.insurance_provider">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Average Days to Due Date</mat-label>
            <input matInput type="number" step="any" [formControl]="companyForm.controls.avg_days_to_due_date">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Credit Limit</mat-label>
            <input matInput currencyMask [options]="currencyOptions$ | async"
              [formControl]="creditForm.controls.max">
          </mat-form-field>
          <mat-form-field style="grid-column: span 2">
            <mat-label>Delivered Balance</mat-label>
            <input matInput readonly [value]="(balance$ | async)?.delivered.balance | currency:creditForm.controls.currency.value">
          </mat-form-field>
          <mat-form-field>
            <mat-label>In Transit Limit</mat-label>
            <input matInput currencyMask [options]="currencyOptions$ | async"
              [formControl]="creditForm.controls.in_transit_max">
          </mat-form-field>
          <mat-form-field style="grid-column: span 2">
            <mat-label>In Transit Balance</mat-label>
            <input matInput readonly [value]="(balance$ | async)?.in_transit.balance | currency:creditForm.controls.currency.value">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Deal Creation Limit</mat-label>
            <input matInput currencyMask [options]="currencyOptions$ | async"
              [formControl]="creditForm.controls.deal_creation_max">
          </mat-form-field>
          <mat-form-field style="grid-column: span 2">
            <mat-label>Submitted Balance</mat-label>
            <input matInput readonly [value]="(balance$ | async)?.creation.balance | currency:creditForm.controls.currency.value">
          </mat-form-field>
        </div>
        <div style="display: grid; gap: 15px">
          <tc-epoch-field placeholder="Credit application date"
            [ctrl]="creditForm.controls.credit_application_date"
          ></tc-epoch-field>
          <tc-epoch-field placeholder="Credit insurance submitted date"
            [ctrl]="creditForm.controls.credit_insurance_submitted_date"
          ></tc-epoch-field>
          <tc-epoch-field placeholder="Credit insurance approval date"
            [ctrl]="creditForm.controls.credit_insurance_approval_date"
          ></tc-epoch-field>
          <tc-epoch-field placeholder="Credit insurance expirary date"
            [ctrl]="creditForm.controls.credit_insurance_expirary_date"
          ></tc-epoch-field>
          @if (fulfilled$ | async; as ff) {
            <div class="text-right">
              @if (ff.isFulfilled) {
                <span class="text-gray text-right">Credit reviewed by {{ff.user | fullname}} on {{ff.updated | epoch:'L'}}</span>
              }
              @if (!ff.isFulfilled) {
                <button type="button" mat-raised-button color="primary" (click)="reviewCredit()">Credit Application Reviewed</button>
              }
            </div>
          }
        </div>
      </section>
      @if (editable) {
        <section class="credit-application" style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); gap: 15px">
          <h5 class="subtitle" style="grid-column: span 4;">Credit application</h5>
          <mat-form-field>
            <mat-label>TAX ID</mat-label>
            <input matInput [formControl]="companyForm.controls.taxID">
          </mat-form-field>
          <mat-form-field>
            <mat-label>DUNS #</mat-label>
            <input matInput [formControl]="companyForm.controls.duns">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Other Credit Reporting Listing</mat-label>
            <input matInput [formControl]="companyForm.controls.credit_reporting_listings">
          </mat-form-field>
          <mat-form-field>
            <mat-label>File Reference #</mat-label>
            <input matInput [formControl]="companyForm.controls.file_reference">
          </mat-form-field>
          <div class="table-responsive" style="grid-column: span 4;">
            <table class="table">
              <tr>
                <th>Document name</th>
                <th>Type</th>
                <th></th>
              </tr>
              @for (application of creditApplications$ | async; track application) {
                <tr>
                  <td>{{application.file.name}}</td>
                  <td>{{application.type}}</td>
                  <td>
                    <button mat-icon-button type="button" [matMenuTriggerFor]="creditAppMenu" [matMenuTriggerData]="{application: application, index: $index}">
                      <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
                    </button>
                  </td>
                </tr>
              }
            </table>
            <button type="button" mat-button class="pull-right" (click)="addCreditApplication()">
              <i class="fa fa-fw fa-plus"></i> Upload
            </button>
          </div>
        </section>
      }
    </div>
  }
</form>


<mat-menu #creditAppMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-application="application" let-index="index">
    <button mat-menu-item type="button" (click)="downloadCreditApplication(application)">
      <i class="fa fa-fw fa-cloud-arrow-down" aria-hidden="true"></i> Download
    </button>
    <button mat-menu-item type="button" (click)="deleteCreditApplication(index)">
      <i class="fa fa-fw fa-trash-can" aria-hidden="true"></i> Delete
    </button>
  </ng-template>
</mat-menu>
