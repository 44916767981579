<table class="table modal-table">
  <thead>
    <tr>
      <th class="bd-bottom col-head" style="width: 120px">Type</th>
      <th class="bd-bottom col-head" style="width: 150px">Product category</th>
      <th class="bd-bottom col-head" style="width: 120px">Product type</th>
      <th class="bd-bottom col-head" style="width: 120px">Product spec</th>
      <th class="bd-bottom col-head">Product(s)</th>
      <th class="bd-bottom" style="width: 50px">&nbsp;</th>
    </tr>
  </thead>
  <tbody class="inner-table">
    @for (businessTypeGroup of productRows$ | async; track businessTypeGroup) {
      <tr>
        <td class="bd-none text-capitalize" style="width: 120px">{{businessTypeGroup.businessType || 'Unknown'}}</td>
        <td colspan="5" class="bd-none" style="padding: 0;">
          <table class="table inner-table" style="margin: 0">
            @for (categoryGroup of businessTypeGroup.groups; track categoryGroup) {
              <tr>
                <td class="bd-bottom" style="width: 150px">{{categoryGroup.category?.name || 'Unknown'}}</td>
                <td class="bd-bottom" style="padding: 0;">
                  <table style="width: 100%">
                    @for (typeGroup of categoryGroup.groups; track typeGroup) {
                      <tr>
                        <td [class.bd-bottom]="$index+1 !== categoryGroup.groups.length" style="width: 120px">
                          {{typeGroup.type?.name || 'Unknown'}}
                        </td>
                        <td [class.bd-bottom]="$index+1 !== categoryGroup.groups.length" style="padding-top: 0">
                          <table style="width: 100%">
                            @for (itemTypeGroup of typeGroup.groups; track itemTypeGroup) {
                              <tr>
                                <td [class.bd-bottom]="$index+1 !== typeGroup.groups.length" style="width: 120px">
                                  {{itemTypeGroup.itemType?.name || '—'}}
                                </td>
                                <td [class.bd-bottom]="$index+1 !== typeGroup.groups.length">
                                  {{itemTypeGroup.productNames}}
                                </td>
                                @if (!isJuniorAdmin) {
                                  <td [class.bd-bottom]="$index+1 !== typeGroup.groups.length">
                                    <button mat-icon-button type="button" [matMenuTriggerFor]="ellipsisMenu" [matMenuTriggerData]="{itemTypeGroup: itemTypeGroup}">
                                      <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
                                    </button>
                                  </td>
                                }
                              </tr>
                            }
                          </table>
                        </td>
                      </tr>
                    }
                  </table>
                </td>
              </tr>
            }
          </table>
        </td>
      </tr>
    }
  </tbody>
</table>

<div class="tab-toolbar" style="grid-template-columns: 1fr auto;">
  @if (canSeeOfferCreationEnablement$ | async) {
    <mat-checkbox
      [formControl]="companyForm.controls.permissionsOfferCreate">
      {{enablementLabel$ | async}}
    </mat-checkbox>
  }
  <button mat-button type="button" (click)="showAddProfile()" style="grid-column: 2">
    <i class="fa fa-plus"></i> add new
  </button>
</div>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-itemTypeGroup="itemTypeGroup">
    @if (!isBulkEdit) {
      <button mat-menu-item type="button" (click)="showEditProfile(itemTypeGroup.groups)">
        <i class="fa fa-fw fa-edit" aria-hidden="true"></i> Edit
      </button>
    }
    @if (isAdminManagerSuperuser) {
      <button mat-menu-item type="button" (click)="showDeleteProfile(itemTypeGroup.groups)">
        <i class="fa fa-fw fa-trash-can" aria-hidden="true"></i> Delete
      </button>
    }
  </ng-template>
</mat-menu>
