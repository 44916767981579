@if (!isBulkEdit) {
  @if (companyAccount$ | async; as account) {
    <span class="text-gray">Account #: {{account}}</span>
  }
  <div style="display: grid; grid-template-columns: minmax(0, 5fr) minmax(0, 4fr) minmax(0, 3fr); gap: 15px">
    <mat-form-field>
      <mat-label>Company name</mat-label>
      <input matInput [formControl]="companyForm.controls.name">
    </mat-form-field>
    <tc-select-search placeholder="Business type"
      [ctrl]="companyForm.controls.business_type_id"
      [items]="businessTypes$ | async" bindLabel="name" bindValue="business_type_id"
    ></tc-select-search>
    <tc-select-search placeholder="Status"
      [ctrl]="companyForm.controls.status"
      [items]="availableCompanyStatuses" bindLabel="name" bindValue="value"
    ></tc-select-search>
    <tc-epoch-field placeholder="In business since"
      [ctrl]="companyForm.controls.since"
    ></tc-epoch-field>
    <tc-select-search placeholder="Subsidiary of" style="grid-column: span 2"
      [ctrl]="companyForm.controls.parent"
      [items]="companies$ | async" bindValue="account" bindLabel="name"
    ></tc-select-search>
    <mat-form-field>
      <mat-label>Website</mat-label>
      <input matInput type="url" [formControl]="companyForm.controls.website">
    </mat-form-field>
    <mat-form-field style="grid-column: span 2">
      <mat-label>Tracking website</mat-label>
      <input matInput [formControl]="companyForm.controls.tracking_website">
      <mat-hint>Use {{'{track_number}'}} tag in Tracking website URL</mat-hint>
    </mat-form-field>
  </div>
  <div style="display: grid; grid-template-columns: minmax(0, 7fr) minmax(0, 3fr) minmax(0, 2fr); gap: 15px; margin: 10px 0 0">
    <mat-radio-group [formControl]="companyForm.controls.type" (change)="onCompanyTypeChange()">
      <mat-radio-button value="buyer" class="col-sm-3">Buyer</mat-radio-button>
      <mat-radio-button value="supplier" class="col-sm-3"> Supplier</mat-radio-button>
      <mat-radio-button value="service_provider" class="col-sm-6">Service provider</mat-radio-button>
    </mat-radio-group>
    @if (companyForm.controls.type.value === 'service_provider') {
      <tc-select-search [placeholder]="carrierForm.controls.type.value.length ? 'Carrier Type' : 'Not Carrier'" [multiple]="true"
        [ctrl]="carrierForm.controls.type"
        [items]="availableCarrierTypes" bindLabel="name" bindValue="id"
        (change)="onCurrentCarrierTypeChange()"
      ></tc-select-search>
    }
  </div>
  <div style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); gap: 15px">
    <mat-form-field style="grid-column: span 2">
      <mat-label>Phone</mat-label>
      <input matInput [formControl]="companyForm.controls.primaryphone">
      <mat-error *ngIf="checkForErrorsIn(companyForm.controls.primaryphone, 'Phone') as errs">{{errs}}</mat-error>
    </mat-form-field>
    <mat-form-field style="grid-column: span 2">
      <mat-label>Email</mat-label>
      <input matInput type="email" tc-email-pattern [formControl]="companyForm.controls.primaryemail">
      <mat-error *ngIf="checkForErrorsIn(companyForm.controls.primaryemail, 'Email') as errs">{{errs}}</mat-error>
    </mat-form-field>
  </div>
  <div style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); gap: 15px; margin: 10px 0 0">
    <tc-select-search  placeholder="Primary account manager"
      [ctrl]="companyForm.controls.manager"
      [items]="managerUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <tc-select-search placeholder="Secondary account managers" [multiple]="true"
      selectedTextFormat="count>4"
      [ctrl]="companyForm.controls.managers"
      [items]="managerUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <tc-select-search placeholder="Primary Logistics Coordinator"
      [ctrl]="companyForm.controls.coordinator"
      [items]="logisticUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <tc-select-search placeholder="Associated Logistics Coordinators" [multiple]="true"
      selectedTextFormat="count>4"
      [ctrl]="companyForm.controls.coordinators"
      [items]="logisticUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <mat-form-field style="grid-column: span 2">
      <mat-label>SCAC code</mat-label>
      <input matInput [formControl]="companyForm.controls.scac_code">
    </mat-form-field>
    <tc-select-search placeholder="Language" style="grid-column: span 2"
      [ctrl]="companyForm.controls.language"
      [items]="locales" bindLabel="name" bindValue="lang"
    ></tc-select-search>
    @if (companyForm.controls.type.value === 'buyer') {
      <tc-select-search placeholder="Preferred Carriers" [multiple]="true" style="grid-column: span 2"
        [ctrl]="companyForm.controls.preferred_carriers"
        [items]="carriers$ | async" bindLabel="name" bindValue="carrier_id"
      ></tc-select-search>
    }
  </div>
}

@if (isBulkEdit) {
  <div class="bulk-edits">
    <mat-checkbox [formControl]="bulkForm.controls.canEditManagers" />
    <tc-select-search placeholder="Add Associate Account manager" [multiple]="true"
      [ctrl]="bulkForm.controls.accountManagersToAdd"
      [items]="managerUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <tc-select-search placeholder="Delete Associate Account manager" [multiple]="true"
      [ctrl]="bulkForm.controls.accountManagersToDelete"
      [items]="managerUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <tc-select-search placeholder="Replace All Associate Account managers with" [multiple]="true"
      [ctrl]="bulkForm.controls.accountManagersToReplace"
      [items]="managerUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <mat-checkbox [formControl]="bulkForm.controls.canEditManager" />
    <tc-select-search placeholder="Primary Manager"
      [ctrl]="bulkForm.controls.managerInCommon"
      [items]="managerUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <mat-checkbox [formControl]="bulkForm.controls.canEditCoordinators" />
    <tc-select-search placeholder="Add Associated LC" [multiple]="true"
      [ctrl]="bulkForm.controls.coordinatorsToAdd"
      [items]="logisticUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <tc-select-search placeholder="Delete Associated LC" [multiple]="true"
      [ctrl]="bulkForm.controls.coordinatorsToDelete"
      [items]="logisticUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <tc-select-search placeholder="Replace All LCs with" [multiple]="true"
      [ctrl]="bulkForm.controls.coordinatorsToReplace"
      [items]="logisticUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <mat-checkbox [formControl]="bulkForm.controls.canEditCoordinator" />
    <tc-select-search placeholder="Primary Logistics Coordinator"
      [ctrl]="bulkForm.controls.coordinatorInCommon"
      [items]="logisticUsersOfTradecafe$ | async" bindLabel="fullname" bindValue="user_id"
    ></tc-select-search>
    <mat-checkbox [formControl]="bulkForm.controls.canEditLanguage" />
    <tc-select-search placeholder="Language"
      [ctrl]="bulkForm.controls.languageInCommon"
      [items]="locales" bindLabel="name" bindValue="lang"
    ></tc-select-search>
  </div>
}
