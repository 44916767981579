<table class="table modal-table">
  <thead>
    <tr>
      <th class="col-head bd-bottom">Name</th>
      <th class="col-head bd-bottom">Email Addresses</th>
      <th class="col-head bd-bottom">Updated By</th>
      <th class="col-head bd-bottom">Updated At</th>
    </tr>
  </thead>
  <tbody>
    @for (row of distributionsLists$ | async; track row) {
      <tr>
        <td class="bd-bottom" (click)="isNotTrader && editDistributionList(row, $index)">{{ row.name }}</td>
        <td class="bd-bottom" (click)="isNotTrader && editDistributionList(row, $index)">{{ row.emails }}</td>
        <td class="bd-bottom" (click)="isNotTrader && editDistributionList(row, $index)">{{ row.updated_by }}</td>
        <td class="bd-bottom" (click)="isNotTrader && editDistributionList(row, $index)">{{ row.updated_at }}</td>
        <td class="bd-bottom">
          <button mat-icon-button type="button" [matMenuTriggerFor]="ellipsisMenu" [matMenuTriggerData]="{row: row, index: $index}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </td>
      </tr>
    }
  </tbody>
</table>

<div class="tab-toolbar">
  <button mat-button type="button" (click)="addDistributionList()">
    <i class="fa fa-plus"></i> add new
  </button>
</div>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-row="row" let-index="index">
    <button mat-menu-item type="button" (click)="editDistributionList(row, index)">
      <i class="fa fa-fw fa-edit" aria-hidden="true"></i> Edit
    </button>
    @if (isAdminManagerSuperuser) {
      <button mat-menu-item type="button" (click)="removeDistributionList(index)">
        <i class="fa fa-fw fa-trash-can" aria-hidden="true"></i> Delete
      </button>
    }
  </ng-template>
</mat-menu>
