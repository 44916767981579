import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AccountObject, AccountProductSpec } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { groupBy, isEqual } from 'lodash-es'
import { BehaviorSubject, ObservedValueOf, combineLatest } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadItemTypes, selectAllItemTypes } from 'src/app/store/item-types'
import { loadProductCategories, selectAllProductCategories } from 'src/app/store/product-categories'
import { loadProductTypes, selectAllProductTypes } from 'src/app/store/product-types'
import { loadProducts, selectAllProducts, selectProductEntities } from 'src/app/store/products'
import { AccountsService } from 'src/services/data/accounts.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'

export interface CompanyProfileFormOptions {
  title: string
  isBulkEdit: boolean
  company: DeepReadonly<AccountObject>
  selectedCompanies: DeepReadonly<AccountObject[]>
  defaults: {
    businessTypeId: string
    categoryId: string
    typeId: string
    itemTypeId: string
    products_spec: DeepReadonly<AccountProductSpec[]>
  }
}

@Component({
  selector: 'tc-company-profile-form',
  styleUrl: './company-profile-form.component.scss',
  templateUrl: './company-profile-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyProfileFormComponent implements OnInit {
  constructor(
    private readonly toaster: ToasterService,
    private readonly AuthApi: AuthApiService,
    private readonly Accounts: AccountsService,
    private readonly store: Store,
    private readonly dialogRef: MatDialogRef<CompanyProfileFormComponent, DeepReadonly<AccountObject | AccountObject[]>>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: CompanyProfileFormOptions,
  ) {}

  // options
  protected readonly title = this.dialogData.title
  private readonly isNew = this.dialogData.company.account
  protected readonly isBulkEdit = this.dialogData.isBulkEdit
  protected readonly selectedCompanies = this.dialogData.selectedCompanies
  protected readonly company = this.dialogData.isBulkEdit ? this.dialogData.selectedCompanies[0] : this.dialogData.company
  protected readonly enablement = ['manager', 'administrator', 'junior-administrator', 'superuser'].includes(this.AuthApi.currentUser.role)
    && (this.company.type === 'supplier' || this.company.type === 'buyer')
  protected readonly enablementLabel = {
    'buyer': 'Available for SO matching',
    'supplier': 'Enable for SO creation',
  }[this.company.type] || ''

  // form
  protected readonly form = new FormGroup({
    businessTypeId: new FormControl(this.dialogData.defaults.businessTypeId, Validators.required),
    itemTypeId: new FormControl(this.dialogData.defaults.itemTypeId),
    categoryId: new FormControl(this.dialogData.defaults.categoryId, Validators.required),
    typeId: new FormControl(this.dialogData.defaults.typeId, Validators.required),
    productIds: new FormControl(
      this.dialogData.defaults.products_spec.map(ps => ps.product_id),
      Validators.required),
  })
  private readonly productOverrides = new Map<string, ReturnType<typeof this.newOverrideForm>>()
  protected productNameOverrides$ = combineLatest([
    replayForm(this.form.controls.itemTypeId),
    replayForm(this.form.controls.productIds),
    this.store.pipe(select(selectProductEntities), waitNotEmpty()),
  ]).pipe(map(([itemTypeId, productIds, productsById]) =>
    productIds.map(productId => ({
      itemTypeId,
      product: productsById[productId],
      form: this.getOverrideForm(productId, itemTypeId, this.company),
    }))))

  // ref data
  private readonly categories$ = this.store.pipe(
    select(selectAllProductCategories),
    waitNotEmpty(),
    map(categories => groupBy(categories, item => (item.type || 'unknown').toLowerCase())))
  private readonly types$ = this.store.pipe(select(selectAllProductTypes), waitNotEmpty(), map(types => groupBy(types, 'category_id')))
  private readonly products$ = this.store.pipe(select(selectAllProducts), waitNotEmpty(), map(products => groupBy(products, 'type_id')))

  protected readonly itemTypes$ = this.store.pipe(select(selectAllItemTypes), waitNotEmpty())
  protected readonly businessTypes$ = this.categories$.pipe(map(categories => Object.keys(categories)))
  protected readonly limitedCategories$ = combineLatest([replayForm(this.form.controls.businessTypeId), this.categories$])
    .pipe(map(([businessTypeId, categories]) => categories[businessTypeId] || []))
  protected readonly limitedTypes$ = combineLatest([replayForm(this.form.controls.categoryId), this.types$])
    .pipe(map(([categoryId, types]) => types[categoryId] || []))
  protected readonly limitedProducts$ = combineLatest([replayForm(this.form.controls.typeId), this.products$])
    .pipe(map(([typeId, products]) => products[typeId] || []))

  // state
  protected readonly inProgress$ = new BehaviorSubject<'loading'|'save'|undefined>('loading')

  ngOnInit() {
    this.store.dispatch(loadProductCategories())
    this.store.dispatch(loadProductTypes())
    this.store.dispatch(loadProducts())
    this.store.dispatch(loadItemTypes())
    combineLatest([this.categories$, this.types$, this.products$, this.itemTypes$])
      .pipe(take(1)).subscribe(() => this.inProgress$.next(undefined))
  }

  protected onBusinessTypeChanged() {
    this.form.patchValue({ categoryId: '', typeId: '', productIds: [] })
  }

  protected onCategoryChanged() {
    this.form.patchValue({ typeId: '', productIds: [] })
  }

  protected onTypeChanged() {
    this.form.patchValue({ productIds: [] })
  }

  protected save() {
    if (this.inProgress$.value) return
    this.form.markAllAsTouched()
    this.form.updateValueAndValidity()
    this.productOverrides.forEach(form => form.markAllAsTouched())
    if (!this.form.valid) return

    this.inProgress$.next('save')
    this.productNameOverrides$.pipe(take(1)).subscribe(async productNameOverrides => {
      try {
        if (this.isBulkEdit) {
          const companies = await this.saveForMultipleCompanies(productNameOverrides)
          this.toaster.success('Companies Profiles updated successfully')
          this.dialogRef.close(companies)
        } else {
          const company = await this.saveForSingleCompany(this.company, productNameOverrides)
          if (company) {
            if (!this.isNew) this.toaster.success('Company Profile updated successfully')
            this.dialogRef.close(company)
          }
        }
      } finally {
        this.inProgress$.next(undefined)
      }
    })
  }

  private async saveForSingleCompany(
    company: DeepReadonly<AccountObject>,
    productNameOverrides: ObservedValueOf<typeof this.productNameOverrides$>,
  ) {
    try {
      const otherProducts = (company.products_spec || []).filter(x =>
        !productNameOverrides.some(y =>
          x.product_id === y.product.product_id && (x.item_type_id === y.itemTypeId || !x.item_type_id && !y.itemTypeId)) &&
        !this.dialogData.defaults.products_spec.some(y => isEqual(x, y)))
      const formProducts = productNameOverrides.map(({ itemTypeId, product, form }) => ({
        product_id: product.product_id,
        item_type_id: itemTypeId || undefined,
        name: form.controls.name.value || undefined,
        hs_code: form.controls.hs_code.value || undefined,
        offer_creation: form.controls.offer_creation.value || undefined
      }))
      const products_spec = [...otherProducts, ...formProducts]
      const products = products_spec.map(spec => spec.product_id)
      if (company.account) {
        company = await this.Accounts.updateAccountProducts(company, products_spec)
      } else {
        company = { ...company, products, products_spec }
      }
      return company
    } catch (err) {
      console.error('Unable to save product name overrides.', err)
      this.toaster.error('Unable to save product name overrides.', err)
      return undefined
    }
  }

  private saveForMultipleCompanies(productNameOverrides: ObservedValueOf<typeof this.productNameOverrides$>) {
    return Promise.all(this.selectedCompanies.map(company =>
      this.saveForSingleCompany(company, productNameOverrides)))
  }

  protected getProductEnablement(row) {
    return { color: row.product.so_creation ? 'lightgreen' : 'red' }
  }

  private getOverrideForm(productId: string, itemTypeId: string, company: DeepReadonly<AccountObject>) {
    const key = `${productId}:${itemTypeId}`
    if (this.productOverrides.has(key)) return this.productOverrides.get(key)
    const override = company.products_spec?.find(pc =>
      pc.product_id === productId && (pc.item_type_id === itemTypeId || !pc.item_type_id && !itemTypeId))
    let form = this.newOverrideForm(override)
    this.productOverrides.set(key, form)
    return form
  }

  private newOverrideForm(override?: DeepReadonly<AccountProductSpec>) {
    return new FormGroup({
      name: new FormControl(override?.name),
      hs_code: new FormControl(override?.hs_code),
      offer_creation: new FormControl(override?.offer_creation),
    })
  }
}
