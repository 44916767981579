<section class="tab-toolbar">
  <mat-form-field [class.tc-text-empty]="!filtersForm.controls.search.value">
    <mat-label>Search</mat-label>
    <button mat-icon-button matPrefix>
      <mat-icon class="fa fa-search"></mat-icon>
    </button>
    <input matInput [formControl]="filtersForm.controls.search" autocomplete="text">
    <button mat-icon-button class="tc-text-clear"
      (click)="filtersForm.controls.search.reset()">
      <mat-icon class="fa fa-times"></mat-icon>
    </button>
  </mat-form-field>
  <tc-select-search placeholder="User" [multiple]="true"
    [ctrl]="filtersForm.controls.user_id"
    [items]="users$ | async" bindLabel="fullname" bindValue="user_id"
  ></tc-select-search>
  <tc-select-search placeholder="Category" [multiple]="true"
    [ctrl]="filtersForm.controls.category"
    [items]="categories" bindLabel="name" bindValue="id"
  ></tc-select-search>
  @if (!isJuniorAdmin) {
    <button type="button" mat-button (click)="showCreateCompanyNote()">
      <i class="fa fa-plus"></i> Add Note
    </button>
  }
</section>

<tc-notes-list
  [dataSource]="dataSource"
  [displayColumns]="['created', 'visibility', 'user', 'body', 'ellipsisMenu']"
  (update)="showUpdateCompanyNote($event)"
  (ignore)="ignoreNote($event)"
  (unignore)="unignoreNote($event)"
></tc-notes-list>
