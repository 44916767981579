import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core'
import { ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatRadioChange, MatRadioModule } from '@angular/material/radio'
import { Store, select } from '@ngrx/store'
import { LocationObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadCountries, selectCountriesByCode } from 'src/app/store/countries'
import { loadLocations, selectAllLocations, selectLocationEntities } from 'src/app/store/locations'
import { loadUnlocodes, selectAllUnlocodes } from 'src/app/store/unlocodes'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchComponent } from 'src/components/select-search/select-search.component'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { environment } from 'src/environments/environment'
import { isAtLeastAdmin } from 'src/services/data/users.service'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CompanyFormGroup } from '../company-form'
import { CompanyLocationFormModule } from '../sub-forms/company-location-form/company-location-form.module'
import { CompanyLocationFormService } from '../sub-forms/company-location-form/company-location-form.service'


@Component({
  selector: 'tc-company-locations-tab',
  templateUrl: './company-locations-tab.component.html',
  styleUrl: './company-locations-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    CompanyLocationFormModule,
    ReactiveAsteriskModule,
    SelectSearchModule,
  ]
})
export class CompanyLocationsTabComponent implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly store: Store,
    private readonly CompanyLocationForm: CompanyLocationFormService,
    private readonly ConfirmModal: ConfirmModalService,
  ) {
    this.enableRouteService = environment.enableRouteService ?? false;
    this.enableFreightRatePaths = environment.enableFreightRatePaths ?? false;
  }

  enableRouteService = false;
  enableFreightRatePaths = false;

  protected readonly isAdminManagerSuperuser = isAtLeastAdmin(this.AuthApi.currentUser)

  protected readonly countries$ = this.store.pipe(select(selectCountriesByCode), waitNotEmpty())
  protected readonly locations$ = this.store.pipe(select(selectAllLocations), waitNotEmpty())
  protected readonly unlocodes$ = this.store.pipe(select(selectAllUnlocodes), waitNotEmpty())

  @Input({ required: true }) companyForm: CompanyFormGroup
  @ViewChild('prefPort') preferredPortElement: SelectSearchComponent<string>;

  protected companyLocations$: Observable<DeepReadonly<LocationObject>[]>

  ngOnInit() {
    this.store.dispatch(loadLocations({}))
    this.store.dispatch(loadCountries())
    this.store.dispatch(loadUnlocodes());

    this.companyLocations$ = combineLatest([
      replayForm(this.companyForm.controls.locations),
      this.store.pipe(select(selectLocationEntities), waitNotEmpty()),
    ]).pipe(map(([companyLocations, locations]) =>
      // NOTE: data integrity check
      companyLocations?.map(loc => locations[loc] || {} as LocationObject) || []))
  }

  protected onDefaultLocationIsPortChange(change: MatRadioChange) {
    const ctrl = this.companyForm.controls.default_port;
    if (change.value) {
      ctrl.addValidators(Validators.required);
    } else {
      ctrl.clearValidators();
    }
    ctrl.updateValueAndValidity();
    this.preferredPortElement.checkIfRequired();
  }

  protected setDefaultLocation(locationId: string) {
    const ctrl = this.companyForm.controls.default_location
    ctrl.setValue(locationId)
    ctrl.markAsTouched()
    ctrl.markAsDirty()
  }

  protected async showAddLocation() {
    const loc = await this.CompanyLocationForm.showCompanyLocation()
    if (!loc) return
    const ctrl = this.companyForm.controls.locations
    ctrl.setValue([...ctrl.value, loc])
    ctrl.markAsTouched()
    ctrl.markAsDirty()
  }

  protected async showRemoveLocation(index: number) {
    await this.ConfirmModal.show({
      title: 'Remove this location?',
      description: 'Are you sure you want to remove this location?',
    })
    const ctrl = this.companyForm.controls.locations
    ctrl.setValue(ctrl.value.filter((_l, i) => i !== index))
    ctrl.markAsTouched()
    ctrl.markAsDirty()
  }
}
