<h2 mat-dialog-title>
  <mat-icon class="fa fa-address-book"></mat-icon>
  Reassign Accounts for {{sourceUser.fullname}}
</h2>
<div mat-dialog-content>
  <div gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px">
    <tc-select-search placeholder="Target Coordinator" [ctrl]="targetUser"
        [items]="targetUsers$ | async" bindLabel="name" bindValue="id"
        ></tc-select-search>
    <!-- <tc-select-search placeholder="Assignment Type" [ctrl]="type"
        [items]="types" bindLabel="name" bindValue="id"
        ></tc-select-search> -->
  </div>
</div>
<tc-reassignments-list #list
    [dataSource]="dataSource"
    (selectionChanged)="selectionChanged($event)"
    ></tc-reassignments-list>
<mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined"></mat-progress-bar>
<div mat-dialog-actions align="end">
  <button mat-raised-button type="button" color="orange" [disabled]="!(canUnassign$ | async)" (click)="unassign()">
    Unassign {{(targetUser$ | async)?.fullname}}
  </button>
  <button mat-button type="button" (click)="close()">Cancel</button>
  <button mat-raised-button type="button" color="primary" [disabled]="inProgress$ | async" (click)="save()">
    <mat-icon class="fa fa-fw" aria-hidden="true"
        [class.fa-save]="(inProgress$ | async) !== 'save'"
        [class.fa-spin]="(inProgress$ | async) === 'save'"
        [class.fa-spinner]="(inProgress$ | async) === 'save'"
        ></mat-icon>
    Save
  </button>
</div>
