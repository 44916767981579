import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { downgradeInjectable } from '@angular/upgrade/static'
import { Trigger } from '@tradecafe/types/core'
import { AngularCopy } from 'src/decorators/angular-copy.decorator'
import { AngularParams, HaveAngularParams } from 'src/decorators/angular-params.decorator'
import { environment } from 'src/environments/environment'


const { apiUrl } = environment


export default module =>
  module.service('TriggerApi', downgradeInjectable(TriggerApiService))


@Injectable()
@AngularCopy()
export class TriggerApiService {
  constructor(private http: HttpClient) {}

  @HaveAngularParams()
  list(account, @AngularParams() params) {
    return this.http.get<{ data: Trigger[] }>(`${apiUrl}/${account}/triggers`, { params }).toPromise()
  }

  create(account, data) {
    return this.http.post<{ data: Trigger }>(`${apiUrl}/${account}/triggers`, data).toPromise()
  }

  get(account, id) {
    return this.http.get<{ data: Trigger }>(`${apiUrl}/${account}/triggers/${id}`).toPromise()
  }

  update(account, id, data) {
    return this.http.put<{ data: Trigger }>(`${apiUrl}/${account}/triggers/${id}`, data).toPromise()
  }

  delete(account, id) {
    return this.http.delete<{ data: Trigger }>(`${apiUrl}/${account}/triggers/${id}`).toPromise()
  }

  pause(account, id) {
    return this.http.put<{ data: Trigger }>(`${apiUrl}/${account}/triggers/${id}/pause`, {}).toPromise()
  }

  resume(account, id) {
    return this.http.put<{ data: Trigger }>(`${apiUrl}/${account}/triggers/${id}/resume`, {}).toPromise()
  }

  @HaveAngularParams()
  getRecipientUserTypes(@AngularParams() params) {
    return this.http.get<{ data: any[] }>(`${apiUrl}/recipient/type`, { params }).toPromise()
  }

  getEventTypes() {
    // NOTE: there is complete CRUD API, we just don't use it
    return this.http.get<{ data: any[] }>(`${apiUrl}/events`).toPromise()
  }
}
