import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { PaymentMethod } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as PaymentMethodActions from './payment-methods.actions'

export const paymentMethodsFeatureKey = 'paymentMethods'
export interface PaymentMethodsState extends EntityState<DeepReadonly<PaymentMethod>> {
}

export const selectId = (paymentMethod: PaymentMethod) => paymentMethod.payment_method_id
export const adapter = createEntityAdapter<DeepReadonly<PaymentMethod>>({ selectId })

const initialState: PaymentMethodsState = adapter.getInitialState({
})

export const paymentMethodsReducer = createReducer(
  initialState,
  on(PaymentMethodActions.loadPaymentMethodsSuccess,
    (state, action) =>
      adapter.upsertMany(action.paymentMethods, state)),
)

