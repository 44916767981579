import { Injectable } from "@angular/core";
import { AccountObject, CreditPool } from "@tradecafe/types/core";
import { DeepReadonly } from "@tradecafe/types/utils";
import { CreditPoolService } from "src/services/data/credit-pool.service";
import { ModalService } from "src/shared/modal";
import { CompanyFormComponent, CompanyFormOptions } from "./company-form.component";

@Injectable()
export class CompanyFormService {
  constructor(
    private readonly modal: ModalService,
    private readonly CreditPools: CreditPoolService,
  ) {}

  async showCompanyForm(company: DeepReadonly<AccountObject>, isBulkEdit: boolean, selectedCompanies: DeepReadonly<AccountObject[]>) {
    // fix backend data type
    company = { ...company, archived: company.archived ? 1 : 0 }

    let creditPool: CreditPool
    if (company.account) {
      creditPool = await this.CreditPools.getFor(company.account) || {} as CreditPool
      creditPool.in_transit_max = creditPool.in_transit_max || 0
      creditPool.deal_creation_max = creditPool.deal_creation_max || 0
      creditPool.max = creditPool.max || 0
      creditPool.attributes = creditPool.attributes || {}
    }

    return this.modal.openDialog<
      CompanyFormComponent,
      CompanyFormOptions,
      void
    >(
      CompanyFormComponent,
      { company, creditPool, isBulkEdit, selectedCompanies },
      {
        panelClass: ['tc-dialog', 'modal-full'],
        width: 'inherit',
        maxWidth: '100%',
        position: {
          top: '30px',
        }
      }
    ).toPromise()
  }
}
