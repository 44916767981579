<form (ngSubmit)="save()">
  <h2 mat-dialog-title>
    <i class="fa fa-floppy-disk"></i>&nbsp;{{isNew ? 'Create New Transit Time' : 'Update Transit Time'}}
  </h2>
  <div mat-dialog-content gdColumns="repeat(2, minmax(0, 1fr))" gdGap="15px">
    <mat-form-field gdColumn="span 2">
      <mat-label>Name</mat-label>
      <input matInput autocomplete="off" [formControl]="ttForm.controls.name">
    </mat-form-field>
    <tc-select-search gdColumn="span 2" placeholder="Pickup location"
        [group]="ttForm" ctrlName="pickup"
        [items]="locations$ | async" bindValue="location_id" bindLabel="name"
        clearable="true"
        ></tc-select-search>
    <tc-select-search gdColumn="span 2" placeholder="Delivery location"
        [group]="ttForm" ctrlName="delivery"
        [items]="locations$ | async" bindValue="location_id" bindLabel="name"
        clearable="true"
        ></tc-select-search>
    <mat-form-field>
      <mat-label>Transit Time (days)</mat-label>
      <input matInput autocomplete="off" [formControl]="ttForm.controls.time" type="number" min="1" step="1">
    </mat-form-field>
    <tc-select-search placeholder="Transit Type"
        [group]="ttForm" ctrlName="type"
        [items]="SegmentTypes" bindValue="id" bindLabel="name"
        clearable="true"
        ></tc-select-search>
  </div>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="inProgress$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      Save</button>
  </div>
</form>
