<table class="table modal-table table-hover table-striped">
  <thead>
    <tr>
      <th class="col-head bd-bottom">Name</th>
      <th class="col-head bd-bottom">Address</th>
      <th class="col-head bd-bottom">Role</th>
      <th class="col-head bd-bottom">Date created</th>
      <th class="col-head bd-bottom"></th>
    </tr>
  </thead>
  <tbody>
    @for (user of companyUsers$ | async; track user) {
      <tr [class.tc-archived-user]="user.archived">
        <td class="bd-bottom" (click)="showEditContact(user)">{{user | fullname}} @if (user.attributes.designated === '1') {
          <i class="fa fa-star" style="color: orange"
            matTooltip="Designated user"
          ></i>
          } @if (!user.autocomplete_2fa) {
          <i class="fa fa-shield"
          matTooltip="2FA Enabled"></i>
        }
      </td>
      <td class="bd-bottom" (click)="showEditContact(user)">{{user.address | address:['street1', 'city', 'state_code', 'country']}}</td>
      <td class="bd-bottom" (click)="showEditContact(user)">{{user.role}}</td>
      <td class="bd-bottom" (click)="showEditContact(user)">{{user.created | epoch:'L'}}</td>
      <td class="bd-bottom mat-column-ellipsisMenu">
        @if (canEditContact(user)) {
          <button mat-icon-button type="button"
            [matMenuTriggerFor]="ellipsisMenu"
            [matMenuTriggerData]="{user: user}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        }
      </td>
    </tr>
  }
</tbody>
</table>

<div class="tab-toolbar">
  <button type="button" mat-button (click)="showAddContact()">
    <i class="fa fa-plus"></i> add new
  </button>
</div>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-user="user">
    <button mat-menu-item type="button"
      (click)="masqueradeContact(user)" (mouseover)="prepareAutologin(user)">
      <i class="fa fa-edit" aria-hidden="true"></i> Masquerade
    </button>
    <button mat-menu-item type="button"
      (click)="showEditContact(user)">
      <i class="fa fa-edit" aria-hidden="true"></i> Edit
    </button>
    @if (isAdminManagerSuperuser && user.archived) {
      <button mat-menu-item type="button"
        (click)="archiveContact(user, false)">
        <i class="fa fa-archive" aria-hidden="true"></i> Un-archive
      </button>
    }
    @if (isAdminManagerSuperuser && !user.archived) {
      <button mat-menu-item type="button"
        (click)="archiveContact(user, true)">
        <i class="fa fa-archive" aria-hidden="true"></i> Archive
      </button>
    }
    @if (isAdminManagerSuperuser && !user.autocomplete_2fa) {
      <button mat-menu-item type="button"
        (click)="toggle2FA(user, false)">
        <i class="fa fa-shield" aria-hidden="true"></i> Disable 2FA
      </button>
    }
    @if (isAdminManagerSuperuser && user.autocomplete_2fa) {
      <button mat-menu-item type="button"
        (click)="toggle2FA(user, true)">
        <i class="fa fa-shield" aria-hidden="true"></i> Enable 2FA
      </button>
    }
    @if (isAdminManagerSuperuser && (isTradeCafe$ | async) && user.role === 'logistics') {
      <button mat-menu-item type="button"
        (click)="reassignAccounts(user)">
        <i class="fa fa-address-book" aria-hidden="true"></i> Reassign Accounts
      </button>
    }
  </ng-template>
</mat-menu>
