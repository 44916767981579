<div class="tab-toolbar">
  <tc-select-search placeholder="Consignees" [multiple]="true"
    [ctrl]="companyForm.controls.consignees"
    selectedTextFormat="count>6"
    [items]="consignees$ | async" bindLabel="name" bindValue="consignee_id"
  ></tc-select-search>
</div>
<table class="table modal-table">
  <thead>
    <tr>
      <th class="col-head bd-bottom">Name</th>
      <th class="col-head bd-bottom">Est #</th>
      <th class="col-head bd-bottom">Address</th>
      <th class="col-head bd-bottom">Primary</th>
      <th class="col-head bd-bottom">Consignee</th>
      <th class="col-head bd-bottom">Def. {{(isSuppler$ | async) ? 'Pickup' : 'Delivery'}}</th>
      @if (isTradecafeStaffAccount$ | async) {
        <th class="col-head bd-bottom">INALFRESCO</th>
      }
      @if (isTradecafeStaffAccount$ | async) {
        <th class="col-head bd-bottom">Use for AES</th>
      }
      <th class="col-head bd-bottom"></th>
    </tr>
  </thead>
  <tbody>
    @for (address of companyForm.controls.addresses.value; track address) {
      <tr [class.tc-archived]="address?.archived">
        <td class="bd-bottom"><span (click)="editAddress(address)">{{address.name}}</span></td>
        <td class="bd-bottom"><span (click)="editAddress(address)">{{address.establishment}}</span></td>
        <td class="bd-bottom"><span (click)="editAddress(address)">{{address | address:['street1', 'city', 'state_code', 'country']}}</span></td>
        <td class="bd-bottom"><span (click)="editAddress(address)">{{address.primary ? 'YES' : ''}}</span></td>
        <td class="bd-bottom"><span (click)="editAddress(address)">{{address.consignee ? 'YES' : ''}}</span></td>
        <td class="bd-bottom"><span (click)="editAddress(address)">{{address.def_delivery ? 'YES' : ''}}</span></td>
        @if (isTradecafeStaffAccount$ | async) {
          <td class="bd-bottom"> <span (click)="editAddress(address)">{{address.inalfresco ? 'YES' : ''}}</span></td>
        }
        @if (isTradecafeStaffAccount$ | async) {
          <td class="bd-bottom"> <span (click)="editAddress(address)">{{address.aes_address ? 'YES' : ''}}</span></td>
        }
        <td class="bd-bottom">
          <button mat-icon-button type="button" [matMenuTriggerFor]="ellipsisMenu" [matMenuTriggerData]="{address: address}">
            <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
          </button>
        </td>
      </tr>
    }
  </tbody>
</table>

<div class="tab-toolbar">
  <button mat-button type="button" (click)="addAddress()">
    <i class="fa fa-plus"></i> add new
  </button>
</div>

<mat-menu #ellipsisMenu="matMenu" yPosition="below" class="nav-right-menu">
  <ng-template matMenuContent let-address="address">
    <button mat-menu-item type="button" (click)="editAddress(address)">
      <i class="fa fa-fw fa-edit" aria-hidden="true"></i> Edit
    </button>
    @if (!address.archived) {
      <button mat-menu-item type="button" (click)="archiveAddress(address)">
        <i class="fa fa-fw fa-archive" aria-hidden="true"></i> Archive
      </button>
    }
    @if (address.archived) {
      <button mat-menu-item type="button" (click)="unarchiveAddress(address)">
        <i class="fa fa-fw fa-archive" aria-hidden="true"></i> Un-archive
      </button>
    }
  </ng-template>
</mat-menu>
