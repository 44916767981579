import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { SelectSearchModule } from "src/components/select-search/select-search.module";
import { ModalModule } from "src/shared/modal";
import { CompanyLocationFormComponent } from "./company-location-form.component";
import { CompanyLocationFormService } from "./company-location-form.service";

@NgModule({
  declarations: [CompanyLocationFormComponent],
  providers: [CompanyLocationFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ModalModule,
    SelectSearchModule,
  ],
})
export class CompanyLocationFormModule {
}
