<form [formGroup]="form" (submit)="save()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    {{ title }}
  </h2>
  <div mat-dialog-content>
    <section class="product-selection">
      <tc-select-search placeholder="Type"
        [ctrl]="form.controls.businessTypeId"
        [items]="businessTypes$ | async"
        (change)="onBusinessTypeChanged()"
      ></tc-select-search>
      <tc-select-search placeholder="Item Specification"
        [ctrl]="form.controls.itemTypeId"
        [items]="itemTypes$ | async" bindLabel="name" bindValue="item_type_id"
      ></tc-select-search>
      <tc-select-search placeholder="Product Category"
        [ctrl]="form.controls.categoryId"
        [items]="limitedCategories$ | async" bindLabel="name" bindValue="category_id"
        (change)="onCategoryChanged()"
      ></tc-select-search>
      <tc-select-search placeholder="Product Type"
        [ctrl]="form.controls.typeId"
        [items]="limitedTypes$ | async" bindLabel="name" bindValue="type_id"
        (change)="onTypeChanged()"
      ></tc-select-search>
      <tc-select-search placeholder="Products" [multiple]="true" class="fullwidth"
        [ctrl]="form.controls.productIds"
        [items]="limitedProducts$ | async" bindLabel="name" bindValue="product_id"
      ></tc-select-search>
    </section>
    <section class="product-overrides" style="--columns: {{ enablement ? 4 : 3 }}">
      @for (row of productNameOverrides$ | async; track row) {
        <ng-container class="row">
          <mat-form-field class="expand-on-hover">
            <mat-label>Product</mat-label>
            <input matInput readonly [value]="row.product.name">
          </mat-form-field>
          <mat-form-field class="expand-on-hover">
            <mat-label>Product Name override</mat-label>
            <input matInput [formControl]="row.form.controls.name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>HS Code override</mat-label>
            <input matInput [formControl]="row.form.controls.hs_code">
          </mat-form-field>
          <mat-checkbox [formControl]="row.form.controls.offer_creation">
            <i class="fa fa-solid fa-circle"
              [ngStyle]="{ color: row.product.so_creation ? 'lightgreen' : 'red' }"
              [matTooltip]="'Supplier Offer Creation ' + (row.product.so_creation ? 'enabled' : 'disabled')"
              matTooltipPosition="above"
            ></i>
            {{ enablementLabel }}
          </mat-checkbox>
        </ng-container>
      }
    </section>
  </div>

  <mat-progress-bar [mode]="(inProgress$ | async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-fw" aria-hidden="true"
        [class.fa-save]="(inProgress$ | async) !== 'save'"
        [class.fa-spin]="(inProgress$ | async) === 'save'"
        [class.fa-spinner]="(inProgress$ | async) === 'save'"
      ></i>
      Save
    </button>
  </div>
</form>
