import { Injectable } from "@angular/core";
import { Receipt } from "@tradecafe/types/core";
import { DeepReadonly } from "@tradecafe/types/utils";
import { ModalService } from "src/shared/modal";
import { ReceiptFormComponent, ReceiptFormOptions } from "./receipt-form.component";

@Injectable()
export class ReceiptFormService {
  constructor(private readonly modal: ModalService) {}

  showAddReceipt() {
    return this.show({ title: "Add New Receipt" })
  }

  showEditReceipt(receipt: DeepReadonly<Receipt>) {
    return this.show({ title: "Update Receipt", receipt })
  }

  private show(options: ReceiptFormOptions) {
    return this.modal.openDialog(ReceiptFormComponent, options).toPromise()
  }
}
