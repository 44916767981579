import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DeepReadonly } from '@tradecafe/types/utils';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ScrollableDataSource } from 'src/services/table-utils/data-sources/scrollable-data-source';
import { TableSelection } from 'src/services/table-utils/selection/table-selection';

export interface ReassignAccountRow {
  account: number
  name: string
  primary: boolean
  associate: boolean
  hint: string
  disabled: boolean
  dirty: boolean
}

@Component({
  selector: 'tc-reassignments-list',
  templateUrl: './reassignments-list.component.html',
  styleUrls: ['./reassignments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReassignmentsListComponent extends OnDestroyMixin implements OnInit {

  readonly selection = new TableSelection<ReassignAccountRow, number>('account')
  @Input() displayColumns: Array<'select' | 'name' | 'primary' | 'associate'> = ['select', 'name', 'primary', 'associate']
  @Input() dataSource: ScrollableDataSource<ReassignAccountRow, any>
  @Output() readonly selectionChanged = new EventEmitter<number[]>()

  @ViewChild(MatSort) set sort(sort: MatSort) { this.dataSource.setSort(sort) }
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) { this.dataSource.setPaginator(paginator) }
  @ViewChild(CdkVirtualScrollViewport) set viewPort(viewPort: CdkVirtualScrollViewport) { this.dataSource.setViewPort(viewPort) }

  ngOnInit(): void {
    this.selection.changed.pipe(untilComponentDestroyed(this)).subscribe(this.selectionChanged)
  }

  getRowId = (_i: number, row: DeepReadonly<ReassignAccountRow>) =>
    row.account
}
