import { Injectable } from '@angular/core'
import { get, set } from 'lodash-es'
import { AuthApiService } from 'src/api/auth'
import { MatchedOfferApiService } from 'src/api/matched-offer'
import { UserApiService } from 'src/api/user'
import { dayjs } from './dayjs'

@Injectable()
export class MatchedOfferNotifierService {
  constructor(
    private AuthApi: AuthApiService,
    private UserApi: UserApiService,
    private MatchedOfferApi: MatchedOfferApiService,
  ) { }

  private readonly handlers = []
  private lastTimeChecked = +get(this.AuthApi.currentUser, 'attributes.matched_offers_checked', dayjs().unix())
  private readonly delay = 1000 * 60
  private intervalId


  onChange(handler) {
    this.handlers.push(handler)

    return () => {
      this.handlers.slice(this.handlers.indexOf(handler), 1)
    }
  }

  start() {
    if (this.intervalId) {
      console.error('matchedOfferNotifier already started')
      return
    }

    this.check()
    this.intervalId = setInterval(() => {
      this.check()
    }, this.delay)
  }

  stop() {
    clearInterval(this.intervalId)
    this.intervalId = null
  }

  clear() {
    this.lastTimeChecked = dayjs().unix()
    const user = this.AuthApi.currentUser
    set(user, 'attributes.matched_offers_checked', dayjs().unix())
    this.UserApi.update(user.account, user.user_id, {attributes: user.attributes})
    localStorage.setItem('user', JSON.stringify(user))
    this.fire(0)
  }


  private check() {
    if (!this.AuthApi.currentUser) return
    this.MatchedOfferApi.getTotal({created: this.lastTimeChecked}).then((amount) => {
      setTimeout(() => this.fire(amount)) // TODO: ng1 - research & remove timeout?
    })
  }

  private fire(amount) {
    this.handlers.forEach((handler) => {
      handler(amount)
    })
  }
}
