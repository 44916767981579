import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableKey } from '@tradecafe/types/core';

export interface MatchedOfferViewLogOverlayOptions {
  matched_offer_id: string;
}

@Component({
  selector: 'tc-matched-offer-view-log-overlay',
  templateUrl: './matched-offer-view-log-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchedOfferViewLogOverlayComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: MatchedOfferViewLogOverlayOptions,
    private dialogRef: MatDialogRef<MatchedOfferViewLogOverlayComponent, void>
  ) {}

  tableIdentity = TableKey.MatchedOfferViewLogAudit;
  auditLogFilter = { matched_offer_id: this.dialogData.matched_offer_id, action: 'MATCHED_OFFER_VIEW' };

  cancel() {
    this.dialogRef.close();
  }
}
