import { Injectable } from '@angular/core'
import { ModalProxyService } from 'src/shared/modal'

@Injectable()
export class EmailSelectService {
  constructor(private modalHelper: ModalProxyService) {}

  show({emails, options}) {
    // TODO: angular.copy(address) ?
    return this.modalHelper.showModal({
      component: 'tcEmailSelectForm',
      windowClass: 'modalclone',
      backdrop: 'static',
      resolve: {
        options: () => options,
        emails: () => emails,
      },
    })
  }
}
