import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Store, select } from '@ngrx/store'
import { AccountObject, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { archiveUser, disable2FAUser, enable2FAUser, loadUsers, selectAllUsers, unarchiveUser } from 'src/app/store/users'
import { environment } from 'src/environments/environment'
import { isAtLeastAdmin } from 'src/services/data/users.service'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CompanyContactFormModule } from '../sub-forms/company-contact-form/company-contact-form.module'
import { CompanyContactFormService } from '../sub-forms/company-contact-form/company-contact-form.service'
import { ReassignAccountsFormModule } from '../sub-forms/reassign-accounts-form/reassign-accounts-form.module'
import { ReassignAccountsFormService } from '../sub-forms/reassign-accounts-form/reassign-accounts-form.service'

const { tradecafeAccount } = environment


@Component({
  selector: 'tc-company-contacts-tab',
  templateUrl: './company-contacts-tab.component.html',
  styleUrl: './company-contacts-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    PipesModule,
    ReassignAccountsFormModule,
    CompanyContactFormModule,
  ]
})
export class CompanyContactsTabComponent implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly ReassignAccountsForm: ReassignAccountsFormService,
    private readonly CompanyContactForm: CompanyContactFormService,
    private readonly store: Store,
  ) {}

  protected readonly isAdminManagerSuperuser = isAtLeastAdmin(this.AuthApi.currentUser)
  protected readonly currentUser = this.AuthApi.currentUser
  protected isTradeCafe$: Observable<boolean>

  @Input({ required: true }) company$: Observable<DeepReadonly<AccountObject>>

  protected companyUsers$: Observable<DeepReadonly<User[]>>

  protected readonly reassignAccounts = this.ReassignAccountsForm.reassignAccounts.bind(this.ReassignAccountsForm)
  protected autologinTokens: Record<string, string> = {}

  ngOnInit() {
    this.store.dispatch(loadUsers({}))
    this.isTradeCafe$ = this.company$.pipe(map(company => company.account === tradecafeAccount))
    this.companyUsers$ = this.company$.pipe(switchMap(company => this.store.pipe(
      select(selectAllUsers),
      waitNotEmpty(),
      map(users => users.filter(user => user.account === company.account)))))
  }

  private showContactForm(user?: DeepReadonly<User>) {
    this.company$.pipe(take(1)).subscribe(company => {
      this.CompanyContactForm.showUpdateContact({ account: company.account, user })
    })
  }

  protected prepareAutologin(user: DeepReadonly<User>) {
    if (this.autologinTokens[user.user_id] !== undefined) return
    this.autologinTokens[user.user_id] = ''
    this.AuthApi.autologin(user.user_id).then(({ data }) => {
      this.autologinTokens[user.user_id] = data.autologin_token
    })
  }

  protected masqueradeContact(user: DeepReadonly<User>) {
    const autologin_token = this.autologinTokens[user.user_id]
    if (autologin_token) {
      window.open(environment.webappUrl + `?token=${autologin_token}`, '_blank')
      delete this.autologinTokens[user.user_id]
    }
  }

  protected showAddContact() {
    this.showContactForm()
  }

  protected canEditContact(user: DeepReadonly<User>) {
    const { account, role } = this.AuthApi.currentUser
    if (user.account === account && user.role === 'superuser' && role !== 'superuser') return false
    return true
  }

  protected showEditContact(user: DeepReadonly<User>) {
    if (!this.canEditContact(user)) return
    this.showContactForm(user)
  }

  // protected async removeContact(user: DeepReadonly<User>) {
  //   await this.modalHelper.showConfirm({
  //     title: 'Remove this contact?',
  //     description: 'Are you sure you want to remove this contact?',
  //   })
  //   this.store.dispatch(deleteUser({ account: user.account, id: user.user_id }))
  // }

  protected archiveContact(user: DeepReadonly<User>, archived: boolean) {
    this.store.dispatch(archived ? archiveUser(user) : unarchiveUser(user))
  }

  protected toggle2FA(user: DeepReadonly<User>, autocomplete_2fa: boolean) {
    this.store.dispatch(autocomplete_2fa ? enable2FAUser(user) : disable2FAUser(user))
  }
}
