import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { BUYER, Receipt } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { OperationsApiService } from 'src/api/operations'
import { ReceiptApiService } from 'src/api/receipt'
import { loadAccounts, selectAllAccounts } from 'src/app/store/accounts'
import { loadBanks, selectAllBanks } from 'src/app/store/banks'
import { loadCurrencies, selectAllCurrencies } from 'src/app/store/currencies'
import { loadDepartments, selectAllDepartments } from 'src/app/store/departments'
import { environment } from 'src/environments/environment'
import { dayjs } from 'src/services/dayjs'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'


export interface ReceiptFormOptions {
  title: string
  receipt?: DeepReadonly<Receipt>
}

@Component({
  selector: 'tc-receipt-form',
  templateUrl: './receipt-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiptFormComponent implements OnInit {
  constructor(
    private readonly toaster: ToasterService,
    private readonly ReceiptApi: ReceiptApiService,
    private readonly OperationsApi: OperationsApiService,
    private readonly dialogRef: MatDialogRef<ReceiptFormComponent, Receipt>,
    private readonly store: Store,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: ReceiptFormOptions,
  ) {}

  // input
  protected readonly title = this.dialogData.title

  // ref data
  // TODO: WA-13908: [items]-filter=":: $ctrl.isCompanyVisible"
  protected readonly accounts$ = this.store.pipe(select(selectAllAccounts), waitNotEmpty(), map(accounts => {
    const bwi = [environment.tradecafeAccount, ...environment.bwiInventoryAccounts]
    return accounts
      .filter(acc => environment.enableBrokerageDeals
        ? !bwi.includes(acc.account)
        : acc.type === BUYER)
      .map(acc => ({ ...acc, account: acc.account.toString() }))
  }))
  protected readonly banks$ = this.store.pipe(select(selectAllBanks), waitNotEmpty())
  protected readonly currencies$ = this.store.pipe(select(selectAllCurrencies), waitNotEmpty())
  protected readonly departments$ = this.store.pipe(select(selectAllDepartments), waitNotEmpty())

  // form
  protected readonly receiptForm = new FormGroup({
    received: new FormControl(this.dialogData.receipt?.received || dayjs.utc().unix(), Validators.required),
    amount: new FormControl(this.dialogData.receipt?.amount, Validators.required),
    currency: new FormControl(this.dialogData.receipt?.currency, Validators.required),
    account: new FormControl(this.dialogData.receipt?.account, Validators.required),
    bank_id: new FormControl(this.dialogData.receipt?.attributes?.bank_id),
    bank_sc: new FormControl(this.dialogData.receipt?.attributes?.bank_sc),
    ref_no: new FormControl(this.dialogData.receipt?.attributes?.ref_no),
    bank_narrative: new FormControl(this.dialogData.receipt?.attributes?.bank_narrative),
    description: new FormControl(this.dialogData.receipt?.description),
  })

  // state
  protected readonly inProgress$ = new BehaviorSubject<'loading'|'save'|undefined>('loading')


  ngOnInit() {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadBanks({}))
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadDepartments())

    combineLatest([this.accounts$, this.banks$, this.currencies$, this.departments$]).pipe(take(1)).subscribe(() => {
      this.inProgress$.next(undefined)
    })

    if (this.dialogData.receipt?.receipt_id) {
      this.receiptForm.controls.amount.disable()
      this.receiptForm.controls.currency.disable()
      this.receiptForm.controls.received.disable()
    }
  }

  protected async save() {
    if (this.inProgress$.value) return
    this.receiptForm.markAllAsTouched()
    if (!this.receiptForm.valid) return

    const id = this.dialogData.receipt?.receipt_id
    const { bank_id, bank_sc, ref_no, bank_narrative, ...receiptFormRaw } = this.receiptForm.getRawValue()
    const receiptForm = {
      ...receiptFormRaw,
      attributes: {
        ...this.dialogData.receipt?.attributes,
        bank_id, bank_sc, ref_no, bank_narrative,
      }
    }

    if (id) {
      if (this.dialogData.receipt.account !== receiptForm.account && this.dialogData.receipt.invoices?.length) {
        receiptForm.account = this.dialogData.receipt.account
        this.toaster.error('There is a receipt associated with an invoice. Please unapply receipt before trying to change the customer.')
        return
      }
    }

    this.inProgress$.next('save')
    try {
      const receipt = id
        ? await this.update(id, receiptForm)
        : await this.create(receiptForm)

      this.dialogRef.close(receipt)
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  private create(payload: DeepReadonly<Omit<Receipt, 'receipt_id'|'created'|'status'|'amount_assigned'|'invoices'>>) {
    return this.OperationsApi.createReceipt(payload).toPromise().then(({data}) => {
      this.toaster.success('Receipt created successfully.')
      return data
    }, (err) => {
      console.error('Unable to create receipt', err)
      this.toaster.error('Unable to create receipt', err)
      throw err
    })
  }

  private update(id: string, payload: DeepReadonly<Partial<Omit<Receipt, 'receipt_id'|'created'|'updated'|'user_id'>>>) {
    return this.ReceiptApi.update(environment.tradecafeAccount, id, payload).then((res) => {
      this.toaster.success('Receipt updated successfully.')
      return res.data
    }, (err) => {
      console.error('Unable to update receipt', err)
      this.toaster.error('Unable to update receipt', err)
      throw err
    })
  }
}
