@if (enableRouteService && enableFreightRatePaths) {
  <div class="location-filters">
    <tc-select-search #prefPort
      placeholder="Preferred Port"
      [group]="companyForm"
      ctrlName="default_port"
      [items]="unlocodes$ | async"
      bindValue="unlocode"
      bindLabel="displayName"
      bindHint="unlocode"
    ></tc-select-search>
    <mat-radio-group [formControl]="companyForm.controls.default_location_is_port" (change)="this.onDefaultLocationIsPortChange($event)">
      <mat-label>Default Location Setting</mat-label>
      <mat-radio-button [value]="false">Location</mat-radio-button>
      <mat-radio-button [value]="true">Port</mat-radio-button>
    </mat-radio-group>
  </div>
}

<mat-radio-group class="locationsGrid" [formControl]="companyForm.controls.default_location">
  <table class="table modal-table">
    <thead>
      <tr>
        <th class="col-head bd-bottom">Default</th>
        <th class="col-head bd-bottom">Location description</th>
        <th class="col-head bd-bottom">City</th>
        <th class="col-head bd-bottom">State/Province</th>
        <th class="col-head bd-bottom">Country</th>
        <th class="col-head bd-bottom"></th>
      </tr>
    </thead>
    <tbody>
      @for (loc of companyLocations$ | async; track loc) {
        <tr
          (click)="setDefaultLocation(loc.location_id)">
          <td class="bd-bottom">
            <mat-radio-button #radio [value]="loc.location_id"></mat-radio-button>
          </td>
          <td class="bd-bottom">{{loc.name}}</td>
          <td class="bd-bottom">{{loc.city}}</td>
          <td class="bd-bottom">{{loc.state}}</td>
          <td class="bd-bottom">{{(countries$ | async)?.[loc.country]?.name || loc.country}}</td>
          <td class="bd-bottom" style="padding-top: 2px">
            @if (isAdminManagerSuperuser) {
              <button mat-icon-button type="button"
                (click)="$event.stopImmediatePropagation(); showRemoveLocation($index)"
                >
                <mat-icon class="fa fa-trash-can"></mat-icon>
              </button>
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
  <div class="tab-toolbar">
    <button mat-button type="button" (click)="showAddLocation()">
      <i class="fa fa-plus"></i> add new
    </button>
  </div>
</mat-radio-group>
