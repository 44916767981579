import { Injectable } from '@angular/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  MatchedOfferViewLogOverlayComponent,
  MatchedOfferViewLogOverlayOptions,
} from './matched-offer-view-log-overlay.component';

@Injectable()
export class MatchedOfferViewLogOverlayService {
  constructor(private modal: ModalService) {}

  showLogs(matchedOfferId: string) {
    return this.modal.openDialog<
      MatchedOfferViewLogOverlayComponent,
      MatchedOfferViewLogOverlayOptions,
      void
    >(
      MatchedOfferViewLogOverlayComponent,
      {
        matched_offer_id: matchedOfferId,
      },
      {
        panelClass: ['tc-dialog', 'modal-xlg'],
        width: null,
        maxWidth: null,
      }
    );
  }
}
