import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatTabChangeEvent } from '@angular/material/tabs'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { ACCOUNT_ACTIVE, AccountObject, Carrier, CreditPool, SERVICE_PROVIDER, SegmentType } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { compact, difference, identity, isEmpty, uniq } from 'lodash-es'
import { BehaviorSubject, combineLatest, merge, of } from 'rxjs'
import { distinctUntilChanged, filter, map, mapTo, startWith, take, tap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { CreditPoolApiService } from 'src/api/credit-pool'
import { ValidateApiService } from 'src/api/validate'
import { createAccount, createAccountFailure, createAccountSuccess, selectAllAccounts, updateAccount, updateAccountFailure, updateAccountSuccess, updateAccountsBulk, updateAccountsBulkFailure, updateAccountsBulkSuccess } from 'src/app/store/accounts'
import { selectAllBusinessTypes } from 'src/app/store/business-types'
import { selectAllCarriers } from 'src/app/store/carriers'
import { selectAllConsignees } from 'src/app/store/consignees/consignees.selectors'
import { selectAllCountries } from 'src/app/store/countries'
import { selectAllCurrencies, selectCurrencyEntities } from 'src/app/store/currencies'
import { selectAllItemTypes } from 'src/app/store/item-types'
import { selectAllLocations } from 'src/app/store/locations'
import { selectAllMeasures } from 'src/app/store/measures'
import { selectAllPaymentMethods } from 'src/app/store/payment-methonds'
import { selectAllPaymentReferences } from 'src/app/store/payment-references'
import { selectAllPricingTerms } from 'src/app/store/pricing-terms'
import { selectAllProductCategories } from 'src/app/store/product-categories'
import { selectAllProductTypes } from 'src/app/store/product-types'
import { selectAllProducts } from 'src/app/store/products'
import { selectAllUsers } from 'src/app/store/users'
import { ConfirmModalService } from 'src/components/confirm/confirm-modal.service'
import { isAtLeastAdmin } from 'src/services/data/users.service'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { fieldsReport } from 'src/shared/utils/fields-report'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CarriersService } from '../../../tracking-providers/carriers.service'
import { newCarrierForm, newCompanyBulkForm, newCompanyForm, newCreditForm, readCompanyForm, readCreditForm } from './company-form'

const tabs = {
  DETAILS: 0,
  PROFILE: 1,
  ADDRESSES: 2,
  LOCATIONS: 3,
  TAX_PRICING: 4,
  CREDIT: 5,
  CONTACTS: 6,
  DISTRIBUTION: 7,
  BANKING: 8,
  NOTES: 9,
} as const

export interface CompanyFormOptions {
  company: DeepReadonly<AccountObject>,
  creditPool: DeepReadonly<CreditPool>,
  isBulkEdit?: boolean,
  selectedCompanies?: DeepReadonly<AccountObject[]>,
}

@Component({
  selector: 'tc-company-form',
  templateUrl: './company-form.component.html',
  styleUrl: './company-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyFormComponent extends OnDestroyMixin implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly Carriers: CarriersService,
    private readonly ConfirmModal: ConfirmModalService,
    private readonly CreditPoolApi: CreditPoolApiService,
    private readonly toaster: ToasterService,
    private readonly ValidateApi: ValidateApiService,
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly dialogRef: MatDialogRef<CompanyFormComponent, void>,
    @Inject(MAT_DIALOG_DATA) protected readonly dialogData: CompanyFormOptions,
  ) { super() }

  // input
  protected readonly isReadonly = false
  protected readonly isBulkEdit = this.dialogData.isBulkEdit
  protected readonly selectedCompanies$ = new BehaviorSubject(this.dialogData.selectedCompanies)
  protected readonly company$ = new BehaviorSubject(this.isBulkEdit ? this.dialogData.selectedCompanies[0] : this.dialogData.company)
  protected readonly companyAccount$ = this.company$.pipe(map(company => company.account))
  protected readonly creditPool$ = new BehaviorSubject(this.dialogData.creditPool || {} as DeepReadonly<CreditPool>)

  // permissions
  protected readonly isAdminManagerSuperuser = isAtLeastAdmin(this.AuthApi.currentUser)
  protected readonly isJuniorAdmin = this.AuthApi.currentUser.role === 'junior-administrator'
  protected readonly tabs = tabs
  protected readonly displayedTabs = Object.values(tabs).filter(tab => ({
    // tabs access
    [tabs.DETAILS]: true,
    [tabs.PROFILE]: true,
    [tabs.ADDRESSES]: true,
    [tabs.LOCATIONS]: true,
    [tabs.TAX_PRICING]: this.isAdminManagerSuperuser || this.isJuniorAdmin,
    [tabs.CREDIT]: this.isAdminManagerSuperuser || this.dialogData.company?.type === SERVICE_PROVIDER,
    [tabs.CONTACTS]: true,
    [tabs.DISTRIBUTION]: true,
    [tabs.BANKING]: this.isAdminManagerSuperuser || this.isJuniorAdmin,
    [tabs.NOTES]: this.isAdminManagerSuperuser || this.isJuniorAdmin,
  }[tab] && (!this.isBulkEdit || {
    // bulk edit access
    [tabs.DETAILS]: true,
    [tabs.PROFILE]: true,
    [tabs.ADDRESSES]: false,
    [tabs.LOCATIONS]: false,
    [tabs.TAX_PRICING]: false,
    [tabs.CREDIT]: false,
    [tabs.CONTACTS]: false,
    [tabs.BANKING]: false,
    [tabs.NOTES]: false,
  }[tab])))
  protected selectedTab = this.displayedTabs[0]

  // form
  protected readonly companyForm = newCompanyForm(this.ValidateApi, this.company$.value)
  protected readonly creditForm = newCreditForm(this.creditPool$.value)
  protected readonly bulkForm = newCompanyBulkForm()
  protected readonly carrierForm = newCarrierForm()

  // state
  protected readonly inProgress$ = new BehaviorSubject<'save'|'loading'>('loading')
  protected readonly fieldsReport$ = fieldsReport(this.companyForm, this.creditForm).pipe(tap(x => console.warn('fields report', x)))
  protected readonly isDirtyAndValid$ = combineLatest([
    this.companyForm.statusChanges.pipe(startWith('PENDING')),
    this.creditForm.statusChanges.pipe(startWith('PENDING')),
  ]).pipe(
    map(() =>
      this.companyForm.valid && this.companyForm.dirty ||
      !this.companyForm.invalid && this.creditForm.valid && this.creditForm.dirty),
    distinctUntilChanged())
  protected readonly isInvalid$ = combineLatest([
    this.companyForm.statusChanges.pipe(startWith('PENDING')),
    this.creditForm.statusChanges.pipe(startWith('PENDING')),
  ]).pipe(
    map(() => this.companyForm.invalid || this.creditForm.invalid && this.creditForm.dirty),
    distinctUntilChanged())


  ngOnInit() {
    merge(
      combineLatest([
        this.displayedTabs.includes(tabs.DETAILS)
          ? this.store.pipe(select(selectAllAccounts), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.DETAILS)
          ? this.store.pipe(select(selectAllBusinessTypes), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.DETAILS)
          ? this.store.pipe(select(selectAllCarriers), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.ADDRESSES)
          ? this.store.pipe(select(selectAllConsignees), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.BANKING) || this.displayedTabs.includes(tabs.LOCATIONS)
          ? this.store.pipe(select(selectAllCountries), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.CREDIT) && this.company$.value?.account || this.displayedTabs.includes(tabs.TAX_PRICING)
          ? this.store.pipe(select(selectAllCurrencies), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.DETAILS)
          ? this.store.pipe(select(selectAllItemTypes), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.LOCATIONS) || this.displayedTabs.includes(tabs.TAX_PRICING)
          ? this.store.pipe(select(selectAllLocations), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.TAX_PRICING)
          ? this.store.pipe(select(selectAllMeasures), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.CREDIT) && this.company$.value?.account
          ? this.store.pipe(select(selectAllPaymentMethods), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.CREDIT) && this.company$.value?.account
          ? this.store.pipe(select(selectAllPaymentReferences), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.TAX_PRICING)
          ? this.store.pipe(select(selectAllPricingTerms), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.PROFILE)
          ? this.store.pipe(select(selectAllProductCategories), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.PROFILE)
          ? this.store.pipe(select(selectAllProducts), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.PROFILE)
          ? this.store.pipe(select(selectAllProductTypes), waitNotEmpty()) : of(false),
        this.displayedTabs.includes(tabs.CONTACTS) || this.displayedTabs.includes(tabs.DETAILS) || this.displayedTabs.includes(tabs.CREDIT)
          ? this.store.pipe(select(selectAllUsers), waitNotEmpty()) : of(false),
      ]).pipe(take(1), mapTo(undefined)),
      this.actions$.pipe(ofType(createAccount, updateAccount, updateAccountsBulk), mapTo('save')),
      this.actions$.pipe(ofType(createAccountSuccess, createAccountFailure, updateAccountSuccess, updateAccountFailure, updateAccountsBulkSuccess, updateAccountsBulkFailure), mapTo(undefined)),
    ).pipe(distinctUntilChanged(), untilComponentDestroyed(this)).subscribe(status => {
      this.inProgress$.next(status)
    })

    this.actions$.pipe(ofType(createAccountSuccess, updateAccountSuccess), untilComponentDestroyed(this)).subscribe((action) => {
      this.company$.next(action.account)
      this.companyForm.markAsUntouched()
      this.companyForm.markAsPristine()
    })

    this.actions$.pipe(ofType(updateAccountsBulkSuccess), untilComponentDestroyed(this)).subscribe((action) => {
      const updatedCompanies = this.selectedCompanies$.value.map(c => {
        const updated = action.accounts.find(a => a.account === c.account)
        return updated || c
      })
      this.selectedCompanies$.next(updatedCompanies)
      this.bulkForm.markAsUntouched()
      this.bulkForm.markAsPristine()
    })

    this.companyAccount$.pipe(distinctUntilChanged(), filter(identity), untilComponentDestroyed(this)).subscribe(async account => {
      const ctrl = this.companyForm.controls.carrier_id
      const _company = await this.Carriers.getCompanyCarrierId(account)
      const carrierId = ctrl.value || _company?.carrier_id
      if (!carrierId) return
      try {
        // Invalidate cache for carriers so that the latest created carrier is added to cache
        await this.Carriers.invalidateCache()
        const carrier = await this.Carriers.getCachedById(carrierId)
        this.carrierForm.setValue({
          carrier_id: carrier.carrier_id,
          type: carrier.type,
        })
      } catch (err) {
        if (err.status === 404) {
          ctrl.setValue('')
          ctrl.markAsTouched()
          ctrl.markAsDirty()
        }
        this.toaster.error('Unable to load carrier data')
      }
    })
  }

  protected nextStep() {
    const index = this.displayedTabs.indexOf(this.selectedTab)
    this.selectedTab = this.displayedTabs[index + 1]
  }

  protected prevStep() {
    const index = this.displayedTabs.indexOf(this.selectedTab)
    this.selectedTab = this.displayedTabs[index - 1]
  }

  protected setSelectedTab(e: MatTabChangeEvent) {
    this.selectedTab = e.index as any
  }

  protected save() {
    if (this.inProgress$.value) return

    if (this.isBulkEdit) this.saveMultipleCompanies()
    else this.saveSingleCompany()
  }

  protected async close() {
    if (this.companyForm.dirty || this.creditForm.dirty) {
      await this.ConfirmModal.showUnsavedDataWarning()
    }
    this.dialogRef.close()
  }

  private saveMultipleCompanies() {
    const bulk = this.bulkForm.getRawValue()
    const patches: { account: number, patch: DeepReadonly<Partial<AccountObject>>}[] = compact(this.selectedCompanies$.value.map(company => {
      let patch: DeepReadonly<Partial<AccountObject>> = undefined
      if (bulk.canEditManagers) {
        if (bulk.accountManagersToAdd.length) patch = { ...patch, managers: uniq((company.managers || []).concat(bulk.accountManagersToAdd)) }
        if (bulk.accountManagersToDelete.length) patch = { ...patch, managers: difference(patch?.managers || company.managers || [], bulk.accountManagersToDelete) }
        if (bulk.accountManagersToReplace.length) patch = { ...patch, managers: bulk.accountManagersToReplace }
      }
      if (bulk.canEditCoordinators) {
        if (bulk.coordinatorsToAdd.length) patch = { ...patch, coordinators: uniq((company.coordinators || []).concat(bulk.coordinatorsToAdd)) }
        if (bulk.coordinatorsToDelete.length) patch = { ...patch, coordinators: difference(patch?.coordinators || company.coordinators || [], bulk.coordinatorsToDelete) }
        if (bulk.coordinatorsToReplace.length) patch = { ...patch, coordinators: bulk.coordinatorsToReplace }
      }
      if (bulk.canEditManager && bulk.managerInCommon) patch = { ...patch, manager: bulk.managerInCommon }
      if (bulk.canEditCoordinator && bulk.coordinatorInCommon) patch = { ...patch, coordinator: bulk.coordinatorInCommon }
      if (bulk.canEditLanguage && bulk.languageInCommon) patch = { ...patch, language: bulk.languageInCommon }
      if (patch) return { account: company.account, patch }
      return undefined
    }))
    this.store.dispatch(updateAccountsBulk({ patches }))
  }

  private async saveSingleCompany() {
    const companyForm = this.companyForm.getRawValue()
    this.companyForm.markAllAsTouched()

    if (companyForm.status === ACCOUNT_ACTIVE && (isEmpty(companyForm.coordinator) || (isEmpty(companyForm.managers) && isEmpty(companyForm.manager)))) {
      this.toaster.warning('Account cannot be switched to Active status without have an account manager and logistics coordinator assigned.')
      return
    } else if (isEmpty(companyForm.products_spec)) {
      this.toaster.warning('Product is required, please add product in profile tab.')
      return
    } else if (!this.companyForm.valid) {
      return
    }

    if (this.creditForm.dirty) {
      this.creditForm.markAllAsTouched()
      if (!this.creditForm.valid) return
    }

    const patch = readCompanyForm(this.companyForm)
    if (this.company$.value.account) {
      this.store.dispatch(updateAccount({ account: this.company$.value, patch }))
    } else {
      this.store.dispatch(createAccount({ account: patch }))
    }

    // Re-acquire the account info (now with updates!)
    const account = await this.company$.pipe(filter(c => !!c.account), take(1)).toPromise()

    // If either the carrier specific form has changes _or_ the overall name of the carrier has changed
    if (this.carrierForm.dirty || this.companyForm.controls.name.dirty) {
      // To ensure the Save button keeps showing a spinner when carrier is being created
      this.inProgress$.next('save')
      await this.saveCarrierForCurrentCompany(account)
      this.carrierForm.markAsUntouched()
      this.carrierForm.markAsPristine()
    }

    if (this.creditForm.dirty) {
      const currencies = await this.store.pipe(select(selectCurrencyEntities), waitNotEmpty(), take(1)).toPromise()
      const patch = readCreditForm(this.creditForm, currencies)
      const {user_id} = this.AuthApi.currentUser

      let creditPool = this.creditPool$.value.pool_id
        ? await this.updateCreditPool(account.account, { ...patch, user_id } as any) // TODO: remove "any" after updating @tradecafe/types@0.1.158
        : await this.createCreditPool(account.account, { ...patch, user_id } as any) // TODO: remove "any" after updating @tradecafe/types@0.1.158
      this.creditPool$.next(creditPool)
      this.creditForm.markAsUntouched()
      this.creditForm.markAsPristine()
    }
  }

  private createCreditPool(account: number, payload: DeepReadonly<Partial<CreditPool>>) {
    const {user_id} = this.AuthApi.currentUser
    return this.CreditPoolApi.create(account, {
      ...payload,
      account,
      attributes: {
        ...payload.attributes,
        creator_id: user_id,
      },
    }).then(({ data }) => {
      this.toaster.success('Credit saved successfully.')
      return data
    }, (err) => {
      console.error('Unable to create credit.', err)
      this.toaster.error('Unable to create credit.', err)
      throw err
    })
  }

  private updateCreditPool(account: number, payload: DeepReadonly<Partial<CreditPool>>) {
    return this.CreditPoolApi.update(account, payload).then(({ data }) => {
      this.toaster.success('Credit saved successfully.')
      return data
    }, (err) => {
      console.error('Unable to update credit.', err)
      this.toaster.error('Unable to update credit.', err)
      throw err
    })
  }

  private async saveCarrierForCurrentCompany(account: DeepReadonly<AccountObject>) {
    const {type: companyType, name} = this.companyForm.getRawValue()
    // for now we don't allow buyers and suppliers to have carriers
    if (companyType !== SERVICE_PROVIDER) return

    // carrier is present. we need to populate company name and archive/unarchive
    // depending on selected carrier types
    const {type: carrierTypes = [], carrier_id} = this.carrierForm.getRawValue()

    // UI doesn't know anything about company carrier
    if (!carrierTypes?.length && !carrier_id) return

    const carrier: Partial<Carrier> = {
      carrier_id,
      account: account.account.toString(),
      name,
      type: carrierTypes as SegmentType[],
    }

    if (carrierTypes.length) {
      // at least one carrier type is selected
      carrier.archived = false // unarchive
    } else if (carrier_id) {
      // carrier is present in DB, but user selected no carrier types on UI
      console.warn(`archive carrier "${carrier.name}" (${carrier_id})`)
      carrier.archived = true // archive
    } else {
      // no carrier in DB, no carrier types selected - there is nothing to do
      return undefined
    }

    const stored = await this.saveCarrier(carrier)
    if (this.companyForm.controls.carrier_id.value !== stored.carrier_id) {
      this.carrierForm.controls.carrier_id.setValue(stored.carrier_id)
      this.companyForm.controls.carrier_id.setValue(stored.carrier_id)
      this.store.dispatch(updateAccount({ account, patch: { attributes: { carrier_id: stored.carrier_id }}}))
    }
  }

  /**
   * Create or Update carrier, display notifications
   *
   * @private
   * @param {any} carrier
   * @returns
   */
  private async saveCarrier(carrier: DeepReadonly<Partial<Carrier>>) {
    try {
      const {carrier_id} = carrier
      const stored = await (carrier_id ? this.Carriers.update(carrier) : this.Carriers.create(carrier))
      Object.assign(carrier, stored)
      return stored
    } catch (err) {
      const message = err?.data?.error?.error?.message
      if (message?.includes('E11000')) {
        console.error(`There is already a carrier that has the name "${carrier.name}", please use another company name`)
        this.toaster.error(`There is already a carrier that has the name "${carrier.name}", please use another company name`)
      } else {
        console.error('Unable to save carrier', err)
        this.toaster.error('Unable to save carrier', err)
      }
      throw err
    }
  }
}
