import { Injectable } from '@angular/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  CompanyLogOverlayComponent,
  CompanyLogOverlayOptions,
} from './company-log-overlay.component';

@Injectable()
export class CompanyLogOverlayService {
  constructor(private modal: ModalService) {}

  showLogs(account: string) {
    return this.modal.openDialog<
      CompanyLogOverlayComponent,
      CompanyLogOverlayOptions,
      void
    >(
      CompanyLogOverlayComponent,
      {
        title: `Log`,
        account,
      },
      {
        panelClass: ['tc-dialog', 'modal-xlg'],
        width: null,
        maxWidth: null,
      }
    );
  }
}
