import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { Store, select } from '@ngrx/store'
import { AccountObject, BUYER, ItemType, ProductCategory, ProductType, SUPPLIER } from '@tradecafe/types/core'
import { DeepReadonly, compareBy } from '@tradecafe/types/utils'
import { map as _map, groupBy, mapValues } from 'lodash-es'
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { loadItemTypes, selectItemTypeEntities } from 'src/app/store/item-types'
import { loadProductCategories, selectProductCategoryEntities } from 'src/app/store/product-categories'
import { loadProductTypes, selectProductTypeEntities } from 'src/app/store/product-types'
import { loadProducts, selectProductEntities } from 'src/app/store/products'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { isAtLeastAdmin } from 'src/services/data/users.service'
import { ModalModule } from 'src/shared/modal'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CompanyFormGroup } from '../company-form'
import { CompanyProfileFormModule } from '../sub-forms/company-profile-form/company-profile-form.module'
import { CompanyProfileFormService, ProfileRow } from '../sub-forms/company-profile-form/company-profile-form.service'

@Component({
  selector: 'tc-company-profile-tab',
  templateUrl: './company-profile-tab.component.html',
  styleUrl: './company-profile-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    MatMenuModule,
    ModalModule,
    CompanyProfileFormModule,
  ]
})
export class CompanyProfileTabComponent implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly CompanyProfileForm: CompanyProfileFormService,
    private readonly store: Store,
  ) {}

  @Input({ required: true }) companyForm: CompanyFormGroup
  @Input({ required: true }) isBulkEdit: boolean
  @Input({ required: true }) company$: BehaviorSubject<DeepReadonly<AccountObject>>
  @Input({ required: true }) selectedCompanies$: BehaviorSubject<DeepReadonly<AccountObject[]>>

  protected readonly isAdminManagerSuperuser = isAtLeastAdmin(this.AuthApi.currentUser)
  protected readonly isJuniorAdmin = this.AuthApi.currentUser.role === 'junior-administrator'

  protected productRows$: Observable<DeepReadonly<{
    businessType: string;
    groups: {
      category: ProductCategory;
      groups: {
        type: ProductType;
        groups: {
          itemType: ItemType;
          productNames: string;
          groups: ProfileRow[];
        }[];
      }[];
    }[];
  }[]>>
  protected canSeeOfferCreationEnablement$: Observable<boolean>
  protected enablementLabel$: Observable<string>


  ngOnInit() {
    this.store.dispatch(loadProducts())
    this.store.dispatch(loadProductCategories())
    this.store.dispatch(loadProductTypes())
    this.store.dispatch(loadItemTypes())

    this.productRows$ = this.buildProductRows()

    this.canSeeOfferCreationEnablement$ = (this.isBulkEdit || !this.isAdminManagerSuperuser && !this.isJuniorAdmin)
      ? of(false)
      : replayForm(this.companyForm.controls.type).pipe(map(type => type === SUPPLIER || type === BUYER))

    this.enablementLabel$ = replayForm(this.companyForm.controls.type).pipe(map(type => ({
      [BUYER]: 'Available Supplier Offer matching',
      [SUPPLIER]: 'Enable Supplier Offer Creation',
    }[type] || '')))
  }

  protected async showAddProfile() {
    const res = await this.CompanyProfileForm.showAddProfile({
      company: this.company$.value,
      selectedCompanies: this.selectedCompanies$.value,
      isBulkEdit: this.isBulkEdit,
      rows: [],
    })
    this.handleCompanyProfileFormResult(res)
  }

  protected async showEditProfile(rows: DeepReadonly<ProfileRow[]>) {
    const res = await this.CompanyProfileForm.showEditProfile({
      company: this.company$.value,
      selectedCompanies: this.selectedCompanies$.value,
      isBulkEdit: this.isBulkEdit,
      rows,
    })
    this.handleCompanyProfileFormResult(res)
  }

  protected async showDeleteProfile(rows: DeepReadonly<ProfileRow[]>) {
    const res = await this.CompanyProfileForm.showDeleteProfile({
      company: this.company$.value,
      selectedCompanies: this.selectedCompanies$.value,
      isBulkEdit: this.isBulkEdit,
      rows,
    })
    this.handleCompanyProfileFormResult(res)
  }

  private handleCompanyProfileFormResult(res: DeepReadonly<AccountObject | AccountObject[]>) {
    if (!res) return
    if (this.isBulkEdit) {
      this.selectedCompanies$.next(res as AccountObject[])
    } else {
      if (this.company$.value.account) this.company$.next(res as AccountObject)
      const ctrl = this.companyForm.controls.products_spec
      ctrl.setValue((res as AccountObject).products_spec)
      ctrl.markAsTouched()
      ctrl.markAsDirty()
    }
  }

  private buildProductRows() {
    return combineLatest([
      this.store.pipe(select(selectProductEntities), waitNotEmpty()),
      this.store.pipe(select(selectProductTypeEntities), waitNotEmpty()),
      this.store.pipe(select(selectProductCategoryEntities), waitNotEmpty()),
      this.store.pipe(select(selectItemTypeEntities), waitNotEmpty()),
      replayForm(this.companyForm.controls.products_spec),
      this.selectedCompanies$
    ]).pipe(map(([products, types, categories, itemTypes, products_spec, selectedCompanies]) => {
      let commonProductSpecs: AccountObject['products_spec']

      // find common-info for bulk-edit
      if (this.isBulkEdit && selectedCompanies?.length === 1) {
        products_spec = selectedCompanies[0].products_spec
      } else if (this.isBulkEdit && selectedCompanies?.length > 1) {
        commonProductSpecs = this.findCommonProductSpecs()
      }

      // group products
      const interestingProducts = (commonProductSpecs || products_spec)?.map(profileLine => ({
        profileLine,
        product: products[profileLine.product_id],
        category: categories[products[profileLine.product_id]?.category_id],
        type: types[products[profileLine.product_id]?.type_id],
        itemType: itemTypes[profileLine.item_type_id],
        businessType: categories[products[profileLine.product_id]?.category_id]?.type,
      }))?.sort(compareBy(x => x.product?.name))
      const typeGroups = mapValues(groupBy(interestingProducts, 'product.type_id'), (groups, type_id) => ({
        type: types[type_id],
        groups: _map(groupBy(groups, 'profileLine.item_type_id'), (grps, item_type_id) => ({
          itemType: itemTypes[item_type_id],
          productNames: grps.map(p => p.product?.name || 'unknown').join(', '),
          groups: grps,
        })).sort(compareBy(x => x.itemType?.name)),
      }))
      const categoryGroups = mapValues(groupBy(typeGroups, 'type.category_id'), (groups, category_id) => ({
        category: categories[category_id],
        groups: groups.sort(compareBy(x => x.type?.name)),
      }))
      const businessTypeGroups = mapValues(groupBy(categoryGroups, 'category.type'), (groups, type) => ({
        businessType: type,
        groups: groups.sort(compareBy(x => x.category?.name)),
      }))

      return _map(businessTypeGroups).sort(compareBy(x => x.businessType))
    }))
  }

  private findCommonProductSpecs() {
    const findCommonality = (spec1, spec2) => {
      let commonSpecs = []
      if (Array.isArray(spec1) && Array.isArray(spec2)) {
        spec1.forEach((e) => {
          if (spec2.some((k) => k.product_id === e.product_id)) {
            commonSpecs.push(e)
          }
        })
      }
      return commonSpecs;
    }
    let commonInfo = []
    for (let i = 1; i < this.selectedCompanies$.value.length; i++) {
      commonInfo = findCommonality(this.selectedCompanies$.value[0].products_spec, this.selectedCompanies$.value[i].products_spec)
    }
    return commonInfo
  }
}
