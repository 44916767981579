import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { ACCOUNT_ACTIVE, AccountObject, CompanyStatuses } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { BehaviorSubject } from 'rxjs'
import { take } from 'rxjs/operators'
import { updateAccountFailure, updateAccountStatus, updateAccountSuccess } from 'src/app/store/accounts'
import { ToasterService } from 'src/shared/toaster/toaster.service'

export interface CompanyStatusFormOptions {
  company: DeepReadonly<AccountObject>,
}

@Component({
  selector: 'tc-company-status-form',
  styleUrl: './company-status-form.component.scss',
  templateUrl: './company-status-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyStatusFormComponent {
  constructor(
    private readonly toaster: ToasterService,
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly dialogRef: MatDialogRef<CompanyStatusFormComponent, AccountObject>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: CompanyStatusFormOptions
  ) {}

  // const
  protected readonly CompanyStatuses = CompanyStatuses.map(s => ({
    ...s,
    hint: s.value === ACCOUNT_ACTIVE && this.canNotActivate? `⚠️ Select manager and coordinator` : '',
  }))

  // form
  protected readonly form = new FormGroup({
    status: new FormControl(this.dialogData.company.status, Validators.required),
  })

  // state
  protected inProgress$ = new BehaviorSubject<'save'|undefined>(undefined)


  protected save() {
    this.form.markAllAsTouched()
    this.form.updateValueAndValidity()
    if (!this.form.valid) return

    const { status } = this.form.getRawValue()
    if (status === ACCOUNT_ACTIVE && this.canNotActivate) {
      this.toaster.warning('Account cannot be switched to Active status without have an account manager and logistics coordinator assigned.')
      return
    }

    this.inProgress$.next('save')
    this.store.dispatch(updateAccountStatus({ account: this.dialogData.company, status }))

    this.actions$.pipe(ofType(updateAccountSuccess, updateAccountFailure), take(1)).subscribe((action) => {
      if (action.type === updateAccountSuccess.type) this.dialogRef.close(action.account)
      this.inProgress$.next(undefined)
    })
  }

  private readonly canNotActivate =
    !this.dialogData.company.coordinator ||
    !this.dialogData.company.managers?.length &&
    !this.dialogData.company.manager
}
