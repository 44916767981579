import { Injectable } from '@angular/core';
import { User } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { ModalService } from 'src/shared/modal';
import { ReassignAccountsFormComponent, ReassignAccountsOptions } from './reassign-accounts-form.component';

@Injectable()
export class ReassignAccountsFormService {
  constructor(private modal: ModalService) {}

  reassignAccounts(sourceUser: DeepReadonly<User>) {
    return this.modal.openDialog<ReassignAccountsFormComponent, ReassignAccountsOptions, void>(ReassignAccountsFormComponent, { sourceUser })
  }
}
