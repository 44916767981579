<form [formGroup]="form" (submit)="save()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    Update company status
  </h2>
  <div mat-dialog-content>
    <tc-select-search placeholder="Status"
        [ctrl]="form.controls.status"
        [items]="CompanyStatuses" bindLabel="name" bindValue="value" bindHint="hint"
        ></tc-select-search>
  </div>
  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      Save
    </button>
  </div>
</form>
