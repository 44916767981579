import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { FileApiService, FilesFilterV2 } from 'src/api/file'
import { ElasticSearchService, ESPaginationParams, ESSortParams } from '../elastic-search'

@Injectable()
export class FileElasticSearchService extends ElasticSearchService {

  constructor(private FileApi: FileApiService) {
    super()
  }

  filters(query?: any) {
    return this.FileApi.esSearchFilters({ query }).pipe(
      map(filtersData => this.getViewFilters(filtersData)),
    )
  }

  search(payload: FilesFilterV2, pagination: ESPaginationParams, sorts: ESSortParams) {
    return this.FileApi.esSearch(payload, pagination, sorts).pipe(
      map(({ total, hits }) => ({ total, data: hits })),
    )
  }
}
