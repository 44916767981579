import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SelectSearchModule } from 'src/components/select-search/select-search.module';
import { ModalModule } from 'src/shared/modal';
import { ReassignAccountsFormComponent } from './reassign-accounts-form.component';
import { ReassignAccountsFormService } from './reassign-accounts-form.service';
import { ReassignmentsListModule } from './reassignments-list/reassignments-list.module';

@NgModule({
  declarations: [ReassignAccountsFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    MatIconModule,
    FlexLayoutModule,
    ModalModule,
    SelectSearchModule,
    ReassignmentsListModule,
  ],
  providers: [ReassignAccountsFormService]
})
export class ReassignAccountsFormModule { }
