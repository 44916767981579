import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { Store, select } from '@ngrx/store'
import { LocationObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { loadCurrencies, selectAllCurrencies } from 'src/app/store/currencies'
import { loadLocations, selectLocationEntities } from 'src/app/store/locations'
import { loadMeasures, selectAllMeasures } from 'src/app/store/measures'
import { loadPricingTerms, selectAllPricingTerms } from 'src/app/store/pricing-terms'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { CompanyFormGroup, CompanyPricingFormGroup } from '../company-form'

@Component({
  selector: 'tc-company-pricing-tab',
  templateUrl: './company-pricing-tab.component.html',
  styleUrl: './company-pricing-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    SelectSearchModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ]
})
export class CompanyPricingTabComponent implements OnInit {
  constructor(
    private readonly store: Store,
  ) {}

  @Input({ required: true }) pricingForm: CompanyPricingFormGroup
  @Input({ required: true }) companyForm: CompanyFormGroup

  // ref data
  protected readonly currencies$ = this.store.pipe(select(selectAllCurrencies), waitNotEmpty())
  protected readonly measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty())
  protected readonly pricingTerms$ = this.store.pipe(select(selectAllPricingTerms), waitNotEmpty())
  protected companyLocations$: Observable<DeepReadonly<LocationObject>[]>

  ngOnInit() {
    this.store.dispatch(loadCurrencies({}))
    this.store.dispatch(loadMeasures())
    this.store.dispatch(loadPricingTerms({}))
    this.store.dispatch(loadLocations({}))

    // TODO: WA-11608 - show invisible/already selected locations
    this.companyLocations$ = combineLatest([
      replayForm(this.companyForm.controls.locations),
      this.store.pipe(select(selectLocationEntities), waitNotEmpty()),
    ]).pipe(map(([companyLocations, locations]) => companyLocations?.map(loc => locations[loc]) || []))
  }
}
