import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ModalModule } from 'src/shared/modal'
import { CompanyProfileFormComponent } from './company-profile-form.component'
import { CompanyProfileFormService } from './company-profile-form.service'

@NgModule({
  declarations: [CompanyProfileFormComponent],
  providers: [CompanyProfileFormService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatTooltipModule,
    ModalModule,
    SelectSearchModule,
  ],
})
export class CompanyProfileFormModule { }
