import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { Store, select } from '@ngrx/store'
import { AccountObject, DistributionListObject } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { selectUserEntities } from 'src/app/store/users'
import { isAtLeastAdmin } from 'src/services/data/users.service'
import { dayjs } from 'src/services/dayjs'
import { replayForm } from 'src/shared/utils/replay-form'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { DistributionListOverlayService } from '../../actions/distribution-list-overlay/distribution-list-overlay.service'
import { CompanyFormGroup } from '../company-form'


interface DistributionListRow {
  distributionList: DistributionListObject,
  name: string
  emails: string
  updated_by: string
  updated_at: string
}

@Component({
  selector: 'tc-company-distribution-tab',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './company-distribution-tab.component.html',
  styleUrl: './company-distribution-tab.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ]
})
export class CompanyDistributionTabComponent implements OnInit {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly DistributionListOverlay: DistributionListOverlayService,
    private readonly store: Store,
  ) {}

  protected readonly isAdminManagerSuperuser = isAtLeastAdmin(this.AuthApi.currentUser)
  protected readonly isNotTrader = this.AuthApi.currentUser.role !== 'trader'


  @Input({ required: true }) companyForm: CompanyFormGroup
  @Input({ required: true }) company: DeepReadonly<AccountObject>

  protected distributionsLists$: Observable<DeepReadonly<DistributionListRow[]>>

  ngOnInit() {
    this.distributionsLists$ = combineLatest([
      replayForm(this.companyForm.controls.distribution_lists),
      this.store.pipe(select(selectUserEntities), waitNotEmpty()),
    ]).pipe(map(([dls, users]) => dls.map(dl => ({
      distributionList: dl,
      name: dl.name,
      emails: this.serializeEmails(dl.recipients),
      updated_by: users[dl.updated_by]?.fullname,
      updated_at: dayjs.unix(dl.updated_at).fromNow()
    }))))
  }

  protected serializeEmails(recipients: DeepReadonly<{email: string}[]>) {
    let emailRecipients = recipients.filter((r) => {
      let keys = Object.keys(r)
      return keys[0] === 'email'
    })
    let emailString = ''
    if (Array.isArray(emailRecipients) && emailRecipients.length > 0) {
      return emailRecipients.reduce(
        (emailString, currentRecipient, currentIndex) => currentRecipient.email
          ? (emailString + (currentIndex === 0 ? '' : ', ') + currentRecipient.email)
          : emailString
        ,
        emailString
      )
    } else {
      return ''
    }
  }

  protected addDistributionList() {
    this.DistributionListOverlay.showCreateDistributionList(this.company).subscribe(newDistributionList => {
      if (!newDistributionList) return
      newDistributionList.updated_at = dayjs.utc().unix()
      newDistributionList.updated_by = this.AuthApi.currentUser.user_id
      const ctrl = this.companyForm.controls.distribution_lists
      ctrl.setValue([...ctrl.value, newDistributionList])
      ctrl.markAsTouched()
      ctrl.markAsDirty()
    })
  }

  protected editDistributionList(row: DeepReadonly<DistributionListRow>, index: number) {
    this.DistributionListOverlay.showUpdateDistributionList(this.company, row.distributionList).subscribe(updatedDistributionList => {
      if (!updatedDistributionList) return
      updatedDistributionList.updated_at = dayjs.utc().unix()
      updatedDistributionList.updated_by = this.AuthApi.currentUser.user_id
      const ctrl = this.companyForm.controls.distribution_lists
      ctrl.setValue(ctrl.value.map((dl, i) => i === index ? updatedDistributionList : dl))
      ctrl.markAsTouched()
      ctrl.markAsDirty()
    })
  }

  protected removeDistributionList(index: number) {
    const ctrl = this.companyForm.controls.distribution_lists
    ctrl.setValue(ctrl.value.filter((_dl, i) => i !== index))
    ctrl.markAsTouched()
    ctrl.markAsDirty()
  }
}
